import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Api, Endpoint } from "../../../services/api.service";
import { Activity, DashboardTripActivity } from "./activity.component";
import { differenceInDays, formatRelative, subDays } from 'date-fns';
import { Trip, TripStatus } from "../../../entities/trip.entity";
import { useHistory } from "react-router-dom"
import { Settings } from "../../../entities/settings.entity";
import { SettingsService } from "../../../services/settings.service";
import { Currency } from "../../../entities/currency.entity";
import { Booking } from "../../../entities/booking.entity";
import { AuthService } from "../../../services/auth.service";
import { Role, RoleAssignment } from "../../../entities/role.entity";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export interface TripResponse {
  id: string,
  activity: Activity,
}
enum ActivityStatus {
  DriverEnroute, Metering, Cancelled, Completed,
}
const StatusMap = new Map([
  [ActivityStatus.DriverEnroute, [TripStatus.DriverEnroute]],
  [ActivityStatus.Metering, [TripStatus.Metering]],
  [ActivityStatus.Cancelled, [TripStatus.Cancelled]],
  [ActivityStatus.Completed, [TripStatus.Completed]],
]);
let TripList: Map<string | undefined, Activity>
let TripExtra: Map<string | undefined, Activity>
let bookingCheck: boolean = false;

export function DashboardTrip() {
  let history = useHistory();
  const [tripList, setTripList] = useState<Map<string | undefined, Activity>>(new Map());
  const [enroute, setEnroute] = useState<Activity[]>([]);
  const [metering, setMetering] = useState<Activity[]>([]);
  const ref = useRef<Map<string | undefined, Activity>>(new Map());
  const [eventInit, setEventInit] = useState<number>(0);
  const [eventInitCheck, setEventInitCheck] = useState<boolean>(false);
  const [canceled, setCanceled] = useState<Activity[]>([]);
  const [completed, setCompleted] = useState<Activity[]>([]);
  const [ccTripAccess, setCCTripAccess] = useState<boolean>()
  const [ongoingTripAccess, setOngoingTripAccess] = useState<boolean>()

  function showToast(message: string) {
    toast.info(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  useEffect(() => {
    let ccStatus, ongoingStatus;
    const Payload: any | undefined = AuthService.getUser();
    const { user } = Payload;
    const assignments: RoleAssignment[] = user ? [...((user?.role as Role).assignments as RoleAssignment[])] : [];
    const moduleCodes = Array.from(assignments, assignment => ({
      module: assignment.module,
      action: assignment.action
    }));
    const newCodes = moduleCodes.map((item: any) => {
      if (item.module === "dashboard" && item.action === "view-completed-cancelled-trip") {
        ccStatus = true
        setCCTripAccess(true)

      }

      if (item.module === "dashboard" && item.action === "view-ongoing-trip") {
        ongoingStatus = true
        setOngoingTripAccess(true)

      }
    })

    if (!ccStatus && !ongoingStatus) {
      showToast(`YOU DO NOT HAVE PERMISSION TO SEE ANY TRIPS`);
    }



  }, []);

  const loadActivities = (status: ActivityStatus): Activity[] => {
    TripList = tripList;
    console.log('Inside Load activites')
    let trips: Activity[];
    if (TripExtra?.values() !== undefined) {

      console.log('Booking Extra Data', TripExtra)
      trips = Array.from(TripExtra.values())
    } else {
      trips = Array.from(TripList.values())
      console.log('Booking inside init Data')
    }
    return Array.from(trips.filter(trips => StatusMap.get(status)?.includes(trips.status)));
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    console.log('Insideeeeeeeeee Trip Alert1111111')
    if (eventInit === 1) {
      console.log('Insideeeeeeeeee Trip Alert22222222')
      Api.on('trip_update', (data) => handleTripUpdate(tripList, data));
      Api.on('trip_alert', (data) => handleTripAlert(tripList, data))
      setEventInit(2);
    }
    if (!eventInitCheck) {
      setEventInit(1);
      setEventInitCheck(true);
    }
    setEnroute(loadActivities(ActivityStatus.DriverEnroute));
    setMetering(loadActivities(ActivityStatus.Metering));
    setCanceled(loadActivities(ActivityStatus.Cancelled));
    setCompleted(loadActivities(ActivityStatus.Completed));
  }, [tripList]);

  const init = async () => {
    try {
      const response = await Api.get<TripResponse[], void>(Endpoint.DASHBOARD_TRIP);
      //   new Date(2020, 5, 2),
      //   new Date(2020, 5, 1)
      // ),formatRelative(subDays(new Date(), 0), new Date()));
      console.log('trip list', response);
      const data = new Map(response.map(trip => [trip.id, trip.activity]))
      setTripList(data);
      console.log("data", data)
      ref.current = data;
    } catch (error: any) {
      console.log('Failed to load booking list', error.message);
    }
  }
  const handleTripUpdate = async (list: Map<string | undefined, Activity>, data: any) => {
    console.log("data in trip update", data)
    const newActivity = createActivity(data);
    if (!bookingCheck) {
      console.log('Inside Map Initialize Should be one only')
      TripExtra = new Map(list);
      bookingCheck = true;
    }

    let tempMap: Map<any, any> = new Map()
    tempMap.set(data.id, newActivity)
    let triplistData = Array.from(TripExtra).map((item) => {
      if(item[0]!==data.id)
      tempMap.set(item[0], item[1])
    })
    TripExtra = tempMap
    setTripList(tempMap);
    setEnroute(loadActivities(ActivityStatus.DriverEnroute));
    setMetering(loadActivities(ActivityStatus.Metering));
    setCanceled(loadActivities(ActivityStatus.Cancelled));
    setCompleted(loadActivities(ActivityStatus.Completed));

  }
  const handleTripAlert = (list: Map<string | undefined, Activity>, data: Trip) => {
    console.log('TripList3: ', list)
    console.log('TripList3 REFFF: ', ref)
    console.log('TripList3 TripList: ', TripList)
    const newActivity = createActivity(data);
    if (!bookingCheck) {
      console.log('Inside Map Initialize Should be one only')
      TripExtra = new Map(list);
      bookingCheck = true;
    }
    let tempMap: Map<any, any> = new Map()
    tempMap.set(data.id, newActivity)
    let triplistData = Array.from(TripExtra).map((item) => {
      if(item[0]!==data.id)
      tempMap.set(item[0], item[1])
    })
    TripExtra = tempMap
    console.log('Inside Trip Extra Data', TripExtra);
    setTripList(TripExtra);
    setEnroute(loadActivities(ActivityStatus.DriverEnroute));
    setMetering(loadActivities(ActivityStatus.Metering));
    setCanceled(loadActivities(ActivityStatus.Cancelled));
    setCompleted(loadActivities(ActivityStatus.Completed));
  }

  const toSubUnit = (value: number): number => {
    // const settings: Settings | undefined = SettingsService.getSetting(); 
    // const unit = settings?.generalCurrency ? (settings?.generalCurrency as Currency).subunits : 100;
    // const val = value / unit;
    return value;
  }

  const dateFormate = (date: any) => {
    const settings: Settings | undefined = SettingsService.getSetting();

    let d: any = new Date(date)
    return d.toLocaleString(undefined, { timeZone: (settings as Settings)?.zone?.generalTimezone });

  }


  const createActivity = (data: Trip) => {
    const final: any = data;
    const lastestTripData: Activity = {
      driverId: final.session.driver.code,
      plate: final.session.vehicle.plate,
      tripCode: data.code,
      totalFare: data.totalFare ? toSubUnit(data.totalFare).toString() : '0',
      status: data.status,
      type: data.type,
      timestamp: data.updateTime.toString(),
      booking: final?.booking?.code,
      zone: data.zone,
    }
    return lastestTripData;
  }
  const { t } = useTranslation("acl");
  const onclick = (tripCode: string) => {
    console.log('event event:', tripCode)
    history.push(`/security/track/${tripCode}`);
  }
  return (
    <div className="content-i">
      <div className="content-box">
        <div className="row">
          {ongoingTripAccess ?
            <>
              <div className="col-sm-12 col-md-3">
                <DashboardTripActivity title={t("dashboard.trip.trip-elements.enroute")} onclick={onclick} activities={enroute} color="secondary" />
              </div>
              <div className="col-sm-12 col-md-3">
                <DashboardTripActivity title={t("dashboard.trip.trip-elements.metering")} onclick={onclick} activities={metering} color="primary" />
              </div>
            </> : null}
          {ccTripAccess ?
            <>
              <div className="col-sm-12 col-md-3">
                <DashboardTripActivity title={t("dashboard.trip.trip-elements.canceled")} onclick={onclick} activities={canceled} color="danger" />
              </div>
              <div className="col-sm-12 col-md-3">
                <DashboardTripActivity title={t("dashboard.trip.trip-elements.completed")} onclick={onclick} activities={completed} color="success" />
              </div>
            </> : null}
        </div>
      </div>
      <ToastContainer theme="dark" />
    </div>
  );
}