import { Currency } from "../entities/currency.entity";
import { Settings, Timezone } from "../entities/settings.entity";
import { SettingsService } from "../services/settings.service";

const settings: Settings | undefined = SettingsService.getSetting();
const symbol = settings?.generalCurrency ? (settings.generalCurrency as Currency).symbol : ""
const unit = settings?.generalCurrency ? (settings?.generalCurrency as Currency).subunits : 100;
enum Unit {
  Metric = "metric",
  Imperial = "imperial"
}

export function getGeneralZoneDistanceValue(): number {
  const zone = settings?.zone;
  if (zone)
    return zone.generalUnit === Unit.Metric ? 1000 : 1609.34
  else {
    return 1000
  }
}

export function getGeneralDistanceFormat(distance: number): string {
  const zone = settings?.zone;
  if (zone) {
    if (zone.generalUnit === Unit.Metric) return Math.round(distance / 1000) + " KMs";
    else return Math.round(distance / 1609.34) + " Miles";
  }
  else return Math.round(distance / 1000) + " KMs";
}

export function getGeneralZoneDistanceTitle(): string {
  const zone = settings?.zone;
  if (zone)
    return zone.generalUnit === Unit.Metric ? 'KM' : 'Miles'
  else {
    return 'KM'
  }
}

export function getGeneralToFixedValue(): number {
  // const currency = settings?.generalCurrency ? (settings?.generalCurrency as Currency).symbol : null;
  // const result = value/unit
  const fixed = Math.round((Math.log(unit) / Math.log(10)));
  return fixed;
}

export function getCurrencyFormatting(value?: number, symbol?: string, subUnits?: number) {
  if (!symbol || !subUnits) {
    if (!settings) { symbol = symbol || "$"; subUnits = subUnits ?? 100; }
    else {
      if (typeof settings.generalCurrency === "string" || typeof settings.generalCurrency === "undefined") {
        symbol = symbol || "$";
        subUnits = subUnits ?? 100;
      }
      else {
        symbol = symbol || settings.generalCurrency.symbol;
        subUnits = subUnits ?? settings.generalCurrency.subunits;
      }
    }
  }

  const fixed = Math.round(Math.log(subUnits) / Math.log(10));
  if (!value) return `${symbol} ${(0).toFixed(fixed)}`;
  else {
    const val = (value / subUnits).toFixed(fixed);
    return `${symbol} ${val || (0).toFixed(fixed)}`;
  }
}

export function getDateFormatting(date: Date, timezone?: string) {
  if (!timezone) {
    timezone = (settings?.generalTimezone as Timezone)?.name || 'Australia/Sydney';
  }
  return date.toLocaleString();
}

export function getGeneralTimeFormat(time: number) {
  return new Date(time * 1000).toISOString().substr(11, 8);
}

export function getGeneralToSubUnit(value: number): string {
  if (value === undefined) {
    return "N/A";
  }
  const val = value / unit;
  return ` ${symbol} ${Math.round((val + Number.EPSILON) * unit) / unit}`;
};


export function getCurrency(value?: number) {
  const symbol = (settings?.generalCurrency as Currency).symbol;
  return `${symbol} ${value}`
}


