import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useWindowScroll } from "react-use";
import { Loading } from "../../../components/alerts/loading.component";
import { GroupFilter, ListFilter } from "../../../components/list/filter.component";
import { ListItem, ListItems, ListItemStatus } from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { View } from "../../../components/view/wrapper.component";
import { Fare } from "../../../entities/fare.entity";
import { Role, RoleAssignment } from "../../../entities/role.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormStyle } from "../../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../../services/api.service';
import { AuthService } from "../../../services/auth.service";
import { checkAssignment } from "../../../util/roleAssignment";
import { FarePost } from "../post/post.component";
import { SelectedItemContent } from "./content.component";

const groupFilters: GroupFilter[] = [
  { title: 'All', icon: 'phone-21' },
  { title: "deleted", icon: "trash" },

];

export function FareList(props: any) {

  const { y: pageYOffset } = useWindowScroll();

  const [search, setSearch] = useState<string>();
  const [fares, setFares] = useState<Fare[] | undefined>(undefined);
  const [selectedFare, setSelectedFare] = useState<Fare>();
  const [limit, setLimit] = useState<number>(20);
  const [allCount, setAllCount] = useState<number>(0);
  const [deletedCount, setDeletedCount] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [count, setCount] = useState<number>(1);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);
  const [size, setSize] = useState<number>()
  const [addButtonDisplay,setAddButtonDisplay] = useState<boolean>(false)


  const { t } = useTranslation('main');
  
  useEffect(()=>{
    // check if given module and action is assigned to the role or not return boolean value
    setAddButtonDisplay(checkAssignment("fare","list-all") || checkAssignment("fare","list-own") )
  },[])

  useEffect(() => {
    setSelectedFare(props.location.state)
  }, [props.location.state])
  const refresh = useCallback(async () => {
    let group: string | undefined = activeGroupFilter?.title
    group = group === 'all' ? undefined : group;

    let isDeleted = false;
    if (group === "deleted") {
      console.log('Inside delete:', group, isDeleted)
      group = undefined
      isDeleted = true
    }
    const { items, totalItems } = await Api.get<IPaginationResponse<Fare>, IPaginationOptions>(Endpoint.FARE_LIST, { page: 0, limit, group, search, isDeleted });

    if (items && items.length !== totalItems) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (items) {
      if (props.location.state !== null && !selectedFare) {
        setSelectedFare(props.location.state);
      }
      setFares(items);
    }
    const response = await Api.get<any, any>(Endpoint.FARE_DELETED_COUNT); 
    setDeletedCount(response); 

    setAllCount(totalItems)

    if (items.length > 0 && !selectedFare) {
      setSelectedFare(items[0]);
    }
  }, [search, limit, activeGroupFilter, selectedFare]);

  useEffect(() => { refresh(); }, [refresh]);

  const fetchMoreData = () => {
    setLimit(limit + 10);
  };

  const deleteFare = async () => {
    setConfirmingDelete(false);
    await Api.delete(Endpoint.FARE, { id: selectedFare?.id });
    setSelectedFare(undefined);
    refresh();
  }

  const toListItems = (fare?: Fare): ListItem<Fare> | undefined => {
    if (!fare) {
      return;
    }
    return {
      id: fare.id as string,
      title: fare.title,
      status: ListItemStatus.Green,
      deleted: fare.deletedTime,
      ref: fare
    }
  }

  const addFare = () => {
    setFormMode(FormMode.Adding);
  }

  const editFare = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (fares) {
      const currentNetworkIndex = fares.findIndex(fare => !!selectedFare && fare.id === selectedFare.id);
      if (currentNetworkIndex + 1 < fares.length && currentNetworkIndex !== -1) {
        setSelectedFare(fares[currentNetworkIndex + 1]);
      }
    }
  }

  const onPrev = () => {
    if (fares) {
      const currentNetworkIndex = fares.findIndex(fare => !!selectedFare && fare.id === selectedFare.id);
      if (currentNetworkIndex - 1 > -1 && currentNetworkIndex !== -1) {
        setSelectedFare(fares[currentNetworkIndex - 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedFare(undefined);
    setActiveGroupFilter(target);
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  let history = useHistory();
  function checkAudit(id: string | undefined): void | undefined {
    history.push(`/audit/${id}`);
  }

  const updateSize = () => {
    setSize(window.screen.width)
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [])

  return (
    <div>
      {size && size < 1000 ?
        <List>
          <div className="row">
            <div className="col-xl-12">
              <ListFilter
                groupFilters={[
                  { title: 'All', icon: 'phone-21', count: allCount },
                  { title: "deleted", icon: "trash", count: deletedCount },

                ]}
                activeGroupFilter={activeGroupFilter}
                onGroupSelect={selectGroup}
              />
            </div>
            <div className="col-xl-12">
              <ListItems
                items={fares?.map(toListItems) as ListItem<Fare>[]}
                activeItem={toListItems(selectedFare)}
                onSearchChange={onSearchChange}
                showSearch={true}
                showRefresh={true}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                onRefresh={() => { setFares(undefined); refresh(); }}
                onClick={(item: ListItem<Fare>) => { setSelectedFare(item.ref); }}
              />
            </div>
            <div className="col-xl-12" style={{ marginTop: "40px" }}>
              {formMode === undefined ? (
                fares !== undefined ? (
                  selectedFare ? (
                    <View
                      componentName={"fare"}
                      showAdd={true}
                      showEdit={true}
                      showDelete={selectedFare.deletedTime ? false : true}
                      showNext={true}
                      showPrev={true}
                      showAudit={true}
                      onAdd={addFare}
                      onEdit={editFare}
                      onDelete={deleteFare}
                      onNext={onNext}
                      onPrev={onPrev}
                      onAudit={() => checkAudit(selectedFare.id)}
                    >
                      <SelectedItemContent
                        selectedFare={selectedFare}
                        onRefresh={refresh}
                        setSelectedFare={setSelectedFare}
                      />
                    </View>
                  ) : (
                    addButtonDisplay && <div className="mx-auto py-5 text-center">
                      <button onClick={addFare} className="btn btn-primary text-bold">{t("mainMenu.fares.add-fare")}</button>
                    </div>
                  )
                ) : (
                  <div className="mx-auto py-5 text-center">
                    <p>{t("mainMenu.fares.loading")}</p>
                    <Loading loading={true} />
                  </div>
                )
              ) : (
                selectedFare ? (
                  <FarePost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    entityId={selectedFare.id}
                    onAfterSave={() => refresh()}
                  />
                ) : (
                  <FarePost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    onAfterSave={() => refresh()}
                  />
                )
              )}
            </div>
          </div>
        </List> :
        <List>
          <ListFilter
            groupFilters={[
              { title: "All", icon: "phone-21", count: allCount },
              { title: "deleted", icon: "trash", count: deletedCount },

            ]}
            activeGroupFilter={activeGroupFilter}
            onGroupSelect={selectGroup}
          />

          <ListItems
            items={fares?.map(toListItems) as ListItem<Fare>[]}
            activeItem={toListItems(selectedFare)}
            onSearchChange={onSearchChange}
            showSearch={true}
            showRefresh={true}
            hasMore={hasMore}
            fetchMoreData={fetchMoreData}
            onRefresh={() => { setFares(undefined); refresh(); }}
            onClick={(item: ListItem<Fare>) => { setSelectedFare(item.ref); }}
          />

          {formMode === undefined ? (
            fares !== undefined ? (
              selectedFare ? (
                <View
                  componentName={"fare"}
                  showAdd={true}
                  showEdit={true}
                  showDelete={selectedFare.deletedTime ? false : true}
                  showNext={true}
                  showPrev={true}
                  showAudit={true}
                  onAdd={addFare}
                  onEdit={editFare}
                  onDelete={deleteFare}
                  onNext={onNext}
                  onPrev={onPrev}
                  onAudit={() => checkAudit(selectedFare.id)}
                >
                  <SelectedItemContent
                    selectedFare={selectedFare}
                    onRefresh={refresh}
                    setSelectedFare={setSelectedFare}
                  />
                </View>
              ) : (
                addButtonDisplay &&<div className="mx-auto py-5 text-center">
                  <button onClick={addFare} className="btn btn-primary text-bold">{t("mainMenu.fares.add-fare")}</button>
                </div>
              )
            ) : (
              <div className="mx-auto py-5 text-center">
                <p>{t("mainMenu.fares.loading")}</p>
                <Loading loading={true} />
              </div>
            )
          ) : (
            selectedFare ? (
              <FarePost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                entityId={selectedFare.id}
                onAfterSave={() => refresh()}
              />
            ) : (
              <FarePost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh()}
              />
            )
          )}
        </List>
      }
    </div>
  );

}
