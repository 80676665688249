import React, { useEffect } from "react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { selectedPic, selectedUrl, show } from "../../../components/form/input/upload.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component";
import { Settings } from "../../../entities/settings.entity";
import { Vehicle, VehicleFileType } from "../../../entities/vehicle.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormHeaderType, FormStyle } from "../../../enums/form.enum";
import { Api, Endpoint } from '../../../services/api.service';
import { VehicleRoute } from "../routes";
import { VehiclePostFeature } from "./section/feature.component";
import { VehiclePostFleet } from "./section/fleet.component";
import { VehiclePostModel } from "./section/model.component";
import { VehiclePostNetwork } from "./section/network.component";
import { VehiclePostOperator } from "./section/operator.component";
import { VehiclePostRegistration } from "./section/registration.component";
import { VehiclePostService } from "./section/service.component";

interface Props {
  style?: FormStyle;
  cancel?: Function;
  formMode?: FormMode;
  entityId?: string;
  onAfterSave?: (entity: Vehicle) => void;
}

interface id {
  id: string | undefined;
  picId: string | undefined;
  fileType: VehicleFileType
}
export const urlShow = React.createContext<boolean>(false);
/**
 * Add or update a vehicle
 */

export function VehiclePost({
  style, cancel, formMode, entityId, onAfterSave,
}: Props) {

  const { t } = useTranslation('main');
  const [pic, setPic] = useState<boolean>(false)
  const [vehicle, setVehicle] = useState<Vehicle>();
  const [settings, setSettings] = useState<Settings>();
  const [entityCount,setEntityCount] = useState<any>()

  const onBeforeSave = (mode: FormMode, form: Vehicle) => {
    console.log('This is form:',form)
    if(form.registrationExpiryDate==''){
delete form.registrationExpiryDate
    }
    if (show === true) {
      if(form.newPlateFile?.dataUrl === selectedPic){
        form.newPlateFile = undefined
      }
      if(form.newInsuranceFiles && form.newInsuranceFiles.length > 0){
        form.newInsuranceFiles = Object.values(form.newInsuranceFiles).filter(file=>file.dataUrl !== selectedPic);
      }
      if(form.newRegistrationFiles && form.newRegistrationFiles.length > 0){
        form.newRegistrationFiles = Object.values(form.newRegistrationFiles).filter(file=>file.dataUrl !== selectedPic);
      }
      if(form.newProfileFiles && form.newProfileFiles.length > 0){
        form.newProfileFiles = Object.values(form.newProfileFiles).filter(file=>file.dataUrl !== selectedPic);
      }
    }
    console.log('This is formssss:',vehicle)
  }

  const processData = (vehicle: Vehicle): Vehicle => {
    console.log("Vehicles: ",vehicle)
    setVehicle(vehicle) 
    return vehicle
  }

  const fetchSettings = async () => {
    const data = await Api.get<Settings, undefined>(Endpoint.SETTINGS);
    const count = await Api.get<any, any>(Endpoint.DRIVER_ENTITIES_COUNT);
    console.log("count",count)
    setEntityCount(count)
    setSettings(data);
  }
  useEffect(() => {
    fetchSettings();
  }, []);
  const FormEditingOnclick = async (e: any) => {
    setPic(true)
    if (vehicle?.plateFile?.id) {
      await Api.post<any, id>(Endpoint.VEHICLE_FILE_DELETE, { id: vehicle.id, picId: vehicle.plateFile?.id, fileType: VehicleFileType.Pic });
    }
  }

  /**
 * Custom Header
 */
  const header = () => (
    style && style === FormStyle.Containerized ? (
      <>
        <SectionsWrapper className="col-lg-6">
          <FormHeader type={FormHeaderType.Title} title={t("entities.vehicle")} />
        </SectionsWrapper>

        <SectionsWrapper className="col-lg-6">
          <FormHeader type={FormHeaderType.Controls} />
        </SectionsWrapper>
      </>
    ) : (
      <FormHeader type={FormHeaderType.Title} title={t("entities.vehicle")} />
    )
  );

  /**
   * Render
   */
  return (
    settings?<Form<Vehicle>
      endpoint={Endpoint.VEHICLE}
      returnLink={VehicleRoute.List}
      cancel={cancel}
      formMode={formMode}
      entityId={entityId}
      processData = {processData}
      onBeforeSave = {onBeforeSave}
      onAfterSave={onAfterSave}
      className={style === FormStyle.Containerized && "ae-content-w"}
      fileEndpoint={Endpoint.VEHICLE_FILES}
      formFiles={[
        { required: settings?.vehicleRequirementPlatePicture?true:false, count: 1, originalName: 'plateFile', name: "newPlateFile", desc: t("vehicle.registrationSection.platePictureLabel") },
        { required: settings?.vehicleRequirementPictures?true:false, count: 4, originalName: 'profileFiles', name: "newProfileFiles", desc: t("vehicle.modelSection.vehiclePictureLabel") },
        { required: settings?.vehicleRequirementInsurance?true:false, count: 4, originalName: 'insuranceFiles', name: "newInsuranceFiles", desc: t("vehicle.modelSection.insurancePictureLabel") },
        { required: settings?.vehicleRequirementRegistration?true:false, count: 4, originalName: 'registrationFiles', name: "newRegistrationFiles", desc: t("vehicle.registrationSection.registrationDocument") }
      ]}
    >
      {style === FormStyle.Containerized && header()}
      
      <SectionsWrapper className={style === FormStyle.Containerized ? "col-lg-12" : ""}>
        {style !== FormStyle.Containerized && <FormHeader type={FormHeaderType.Title} title={t("entities.vehicle")} />}
        <FormSections>
        { (settings.vehicleRequirementFeature)&&(entityCount.featureCount<1 && formMode==0)?<div className="alert alert-danger" role="alert">
          Please Add Feature Before Adding A New Vehicle
        </div>:""}
        {(settings.vehicleRequirementFleet)&& (entityCount.fleetCount<1 && formMode==0)?<div className="alert alert-danger" role="alert">
          Please Add Fleet Before Adding A New Vehicle
        </div>:""}
        {(settings.vehicleRequirementNetwork)&& (entityCount.networkCount<1 && formMode==0)?<div className="alert alert-danger" role="alert">
        Please Add Network Before Adding A New Vehicle
        </div>:""}
        {(settings.vehicleRequirementOperator)&& (entityCount.operatorCount<1 && formMode==0)?<div className="alert alert-danger" role="alert">
        {t('form.header.operatorRequiredMessage')}
        </div>:""}
       
          <urlShow.Provider value={pic}>
            <VehiclePostRegistration FormEditingOnclick={FormEditingOnclick} settings={settings} />
          </urlShow.Provider>
          <VehiclePostModel settings={settings} />
        </FormSections>
      </SectionsWrapper>

      <SectionsWrapper className={style === FormStyle.Containerized ? "col-lg-12" : ""}>
        {style !== FormStyle.Containerized && <FormHeader type={FormHeaderType.Controls} />}
        <FormSections>
       
          <VehiclePostFleet settings={settings} />
          <VehiclePostNetwork settings={settings} />
          <VehiclePostOperator settings={settings} />
          <VehiclePostService settings={settings} />
          <VehiclePostFeature settings={settings} />
        </FormSections>
      </SectionsWrapper>
    </Form>:null
  );
}
