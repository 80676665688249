import React, { useCallback, useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../components/form/input/input.component";
import { FormRadio } from "../../../components/form/input/radio.component";
import { FormSection } from "../../../components/form/section.component";
import { Api, Endpoint } from "../../../services/api.service";

/**
 * Update general settings
 */

export const MeterSection = () => {

  const { t } = useTranslation('forms');
  const [vat, setVat] = useState()
  const childProps = useFormContext();
  const form = useForm()
  const [taxType, setTaxType] = useState<any>([
    { label: "Inclusive", value: 'inclusive',selected: true },
    { label: "Exclusive", value: 'exclusive',  },
  ]);
  const [taxTypeValue, setTaxTypeValue] = useState<any>();

  const refresh = async() => {
    
    const form =  childProps.getValues()
    setVat(form.meterVAT)

    if (!form.id) {
      return;
    }

    const response = await Api.get<any, any>(Endpoint.ZONE_DATA, { id: form.id });
    if(response){
      const data1 = taxType.map((cur: any) => {
        if (cur.value === response.taxType) {
          return {
            selected: true,
            label: cur.label,
            value: cur.value,
          }

        }
        else {
          return {
            // selected: cur.selected,
            label: cur.label,
            value: cur.value,
          }
        }
      });

      setTaxTypeValue(data1)
    }
  };

  useEffect(() => {
    refresh();
  }, [vat]);

  return (
    <FormSection
      title={t("zone.meter.taxSection.title")}
      subtitle=" "
    >
      <div className="row">
        <FormInput
          name="meterTax"
          label={t("zone.meter.taxSection.taxAmountLabel")}
          placeholder="Percentage"
          type="number"
          step={0.01}
          className="col-sm-4"
          tabIndex={11} 
          validation={{ required: true }}
          {...childProps}
        />
        <FormInput
          name="meterTaxLabel"
          label={t("zone.meter.taxSection.taxLabel")}
          placeholder="Ex. GST"
          type="text"
          className="col-sm-4"
          tabIndex={12}
          validation={{ required: true }}
          {...childProps}
        />
        <FormInput
          name="meterVAT"
          label={t("zone.meter.taxSection.vatLabel")}
          placeholder=""
          type="number"  
          value = {vat}
          onChange = {(e:any)=>setVat(e.target.value)}
          step={0.01}
          className="col-sm-4"
          tabIndex={13}
          validation={{ required: true }}
          {...childProps}
        />

      <FormRadio
        label={t("Tax Type")}
        name="taxType"
        className="col-sm-12"
        validation={{ required: true }}
        onChange={
          (e: any) => {
            const data = taxType.map((cur: any) => {
              if (cur.value === e.target.value) {
                return {
                  selected: true,
                  label: cur.label,
                  value: cur.value,
                }
              }
              else {
                return {
                  label: cur.label,
                  value: cur.value,
                }
              }
            })
            setTaxTypeValue(data)
          }}
        options={taxTypeValue ? taxTypeValue : taxType}
        tabIndex={15}
        {...childProps}
      />
      </div>
    </FormSection>
  );
}