import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useWindowScroll } from "react-use";
import { Loading } from "../../../components/alerts/loading.component";
import { GroupFilter, ListFilter } from "../../../components/list/filter.component";
import { ListItem, ListItems } from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { View } from "../../../components/view/wrapper.component";
import { CustomTolls } from "../../../entities/customTolls.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormStyle } from "../../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../../services/api.service';
import { getStorageUrl } from "../../../util/file.util";
import { CustomTollsPost } from "../post.component";
import { SelectedItemContent } from "./content.component";

const groupFilters: GroupFilter[] = [
  { title: 'All', icon: 'phone-21' },
  { title: "deleted", icon: "trash" },

];

export enum ProfilePicSize {
  List = "admin_list",
  Single = "admin_single",
}

export function CustomTollsList(props: any) {

  const { y: pageYOffset } = useWindowScroll();

  const [search, setSearch] = useState<string>('');
  const [ErrorMessage, setErrorMessage] = useState<string>();
  const [limit, setLimit] = useState<number>(20);
  const [allCount, setAllCount] = useState<number>(0);
  const [count, setCount] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [customTollss, setCustomTollss] = useState<CustomTolls[] | undefined>(undefined);
  const [selectedCustomTolls, setSelectedCustomTolls] = useState<CustomTolls>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);
  const [size, setSize] = useState<number>()
  const [deletedCount, setDeletedCount] = useState<number>(0);

  const { t } = useTranslation(['main', 'list']);

  useEffect(() => {
    setSelectedCustomTolls(props.location.state)
  }, [props.location.state])

  const refresh = useCallback(async (e?:boolean) => {
    setErrorMessage(undefined);
    let group: string | undefined = activeGroupFilter?.title
    group = group === 'all' ? undefined : group;
    console.log('Inside refresh')
    let isDeleted = false;
    if (group === "deleted") {
      group = undefined;
      isDeleted = true;
    }
    console.log("group",group)
    const { items, totalItems } = await Api.get<IPaginationResponse<CustomTolls>, IPaginationOptions>(Endpoint.CUSTOM_TOLLS_LIST, { page: 0, limit, group, search, isDeleted });

    if (items && items.length !== totalItems) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (items) {
      if (props.location.state !== null && !selectedCustomTolls) {
        setSelectedCustomTolls(props.location.state);
      }
      setCustomTollss(items);
      if (selectedCustomTolls && e) {
        const data = items.filter((item: CustomTolls) => { return item.id === selectedCustomTolls.id })
        setSelectedCustomTolls(data[0]);
      }
    }

    const response = await Api.get<any, any>(Endpoint.CUSTOM_TOLLS__DELETE_COUNT);
    setDeletedCount(response);

    setAllCount(totalItems)

    if (items.length > 0 && !selectedCustomTolls) {
      setSelectedCustomTolls(items[0]);
    }
  }, [search, limit, activeGroupFilter, selectedCustomTolls]);

  useEffect(() => { refresh(false); }, [refresh]);

  const fetchMoreData = () => {
    setLimit(limit + 10);
  };

  const deleteCustomTolls = async () => {
    setConfirmingDelete(false); setErrorMessage(undefined);
    await Api.delete(Endpoint.CUSTOM_TOLLS, { id: selectedCustomTolls?.id });
    setSelectedCustomTolls(undefined);
    refresh(true);

  }

  const toListItems = (customTolls?: CustomTolls): ListItem<CustomTolls> | undefined => {
    if (!customTolls) {
      return;
    }
    return {
      id: customTolls.id as string,
      title: `${customTolls.title}`,
      ref: customTolls,
      deleted: customTolls.deletedTime,

    }

  }

  const addCustomTolls = () => {
    setFormMode(FormMode.Adding);
  }

  const editCustomTolls = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (customTollss) {
      const currentCustomTollssIndex = customTollss.findIndex(customTolls => !!selectedCustomTolls && customTolls.id === selectedCustomTolls.id);
      if (currentCustomTollssIndex + 1 < customTollss.length && currentCustomTollssIndex !== -1) {
        setSelectedCustomTolls(customTollss[currentCustomTollssIndex + 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    console.log("Target",target)
    setSelectedCustomTolls(undefined);
    setActiveGroupFilter(target);
  }

  const onPrev = () => {
    if (customTollss) {
      const currentCustomTollsIndex = customTollss.findIndex(customTolls => !!selectedCustomTolls && customTolls.id === selectedCustomTolls.id);
      if (currentCustomTollsIndex - 1 > -1 && currentCustomTollsIndex !== -1) {
        setSelectedCustomTolls(customTollss[currentCustomTollsIndex - 1]);
      }
    }
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  let history = useHistory();
  function checkAudit(id: string | undefined): void | undefined {
    history.push(`/audit/${id}`);
  }

  const updateSize = () => {
    setSize(window.screen.width)
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [])

  return (
    <div>
      {size && size < 1000 ?
        <List>
          <div className="row">
            <div className="col-xl-12">
              <ListFilter
                groupFilters={[
                  { title: 'All', icon: 'phone-21', count: allCount },
                  { title: "deleted", icon: "trash", count: deletedCount },
                ]}
                activeGroupFilter={activeGroupFilter}
                onGroupSelect={selectGroup}
              />
            </div>
            <div className="col-xl-12">
              <ListItems
                items={customTollss?.map(toListItems) as ListItem<CustomTolls>[]}
                activeItem={toListItems(selectedCustomTolls)}
                onSearchChange={onSearchChange}
                showSearch={true}
                showRefresh={true}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                onRefresh={() => { setCustomTollss(undefined); refresh(); }}
                onClick={(item: ListItem<CustomTolls>) => { setSelectedCustomTolls(item.ref); }}
              />
            </div>
            <div className="col-xl-12" style={{ marginTop: "40px" }}>
              {formMode === undefined ? (
                customTollss !== undefined ? (
                  selectedCustomTolls ? (
                    <View
                      componentName={"customTolls"}
                      showAdd={true}
                      showEdit={true}
                      showDelete={true}
                      showNext={true}
                      showPrev={true}
                      showAudit={true}
                      onAdd={addCustomTolls}
                      onEdit={editCustomTolls}
                      onDelete={deleteCustomTolls}
                      onPrev={onPrev}
                      onNext={onNext}
                      onAudit={() => checkAudit(selectedCustomTolls.id)}

                    >
                      <SelectedItemContent
                        selectedCustomTolls={selectedCustomTolls}
                        setSelectedCustomTolls={setSelectedCustomTolls}
                        onRefresh={()=>refresh(true)}
                        errorMessage={ErrorMessage}
                      />
                    </View>
                  ) : (
                    <div className="mx-auto py-5 text-center">
                      <button onClick={addCustomTolls} className="btn btn-primary text-bold">{t("list:customTolls.addCustomTolls")}</button>
                    </div>
                  )
                ) : (
                  <div className="mx-auto py-5 text-center">
                    <p>{t("list:customTolls.wait")}</p>
                    <Loading loading={true} />
                  </div>
                )
              ) : (
                selectedCustomTolls ? (
                  <CustomTollsPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    entityId={selectedCustomTolls.id}
                    onAfterSave={() => refresh(true)}
                  />
                ) : (
                  <CustomTollsPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    onAfterSave={() => refresh(true)}
                  />
                )
              )}
            </div>
          </div>
        </List> :
        <List>
          <ListFilter
            groupFilters={[
              { title: "All", icon: "phone-21", count: allCount },
              { title: "deleted", icon: "trash", count: deletedCount },
            ]}
            activeGroupFilter={activeGroupFilter}
            onGroupSelect={selectGroup}
          />

          <ListItems
            items={customTollss?.map(toListItems) as ListItem<CustomTolls>[]}
            activeItem={toListItems(selectedCustomTolls)}
            onSearchChange={onSearchChange}
            showSearch={true}
            showRefresh={true}
            hasMore={hasMore}
            fetchMoreData={fetchMoreData}
            onRefresh={() => { setCustomTollss(undefined); refresh(true); }}
            onClick={(item: ListItem<CustomTolls>) => { setSelectedCustomTolls(item.ref); }}
          />
          {formMode === undefined ? (
            customTollss !== undefined ? (
              selectedCustomTolls ? (<>
                <View
                  componentName={"customTolls"}
                  showAdd={true}
                  showEdit={true}
                  showDelete={true}
                  showNext={true}
                  showPrev={true}
                  showAudit={true}
                  onAdd={addCustomTolls}
                  onEdit={editCustomTolls}
                  onDelete={deleteCustomTolls}
                  onPrev={onPrev}
                  onNext={onNext}
                  onAudit={() => checkAudit(selectedCustomTolls.id)}

                >
                  <SelectedItemContent
                    selectedCustomTolls={selectedCustomTolls}
                    onRefresh={()=>refresh(true)}
                    setSelectedCustomTolls={setSelectedCustomTolls}
                    errorMessage={ErrorMessage}
                  />
                </View></>
              ) : (
                <div className="mx-auto py-5 text-center">
                  <button onClick={addCustomTolls} className="btn btn-primary text-bold">{t("list:customTolls.addCustomTolls")}</button>
                </div>
              )
            ) : (
              <div className="mx-auto py-5 text-center">
                <p>{t("list:customTolls.wait")}</p>
                <Loading loading={true} />
              </div>
            )
          ) : (
            selectedCustomTolls ? (
              <CustomTollsPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                entityId={selectedCustomTolls.id}
                onAfterSave={() => refresh(true)}
              />
            ) : (
              <CustomTollsPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh(true)}
              />
            )
          )}
        </List>
      }

    </div>
  );

}
