import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../../components/form/input/input.component";
import { FormTextArea } from "../../../../components/form/input/textarea.component";
import { FormSection } from "../../../../components/form/section.component";

export function ArticlePostBasic(props: any) {
  const { t } = useTranslation('forms');
  const formProps = useFormContext();
  const [valueData, setValueData] = useState<boolean>(false);
  const [articleDate, setArticleDate] = useState<string>();
  
  const [date, setDate] = useState<boolean>(false);
  
  const [description, setDescription] = useState<any>();
  const { control } = formProps;
  const [editorState, setEditorState] = React.useState(
    () => EditorState.createEmpty(),
  );

  const onEditorStateChange = (newEditorState: any) => {
    console.log('New Editor State is this: ', newEditorState);
    console.log('Current Context: ', newEditorState.getCurrentContent())
    setEditorState(newEditorState);
    const rawContentState = convertToRaw(newEditorState.getCurrentContent());
    console.log('Raw Content State:', rawContentState);
    const markup = draftToHtml(rawContentState);
    setDescription(markup);
  };

  const valueConverstion=(value:any)=>{
    if (value !== null) {
      if (value?.blocks) {
        const contentState = convertFromRaw(value);
        const editorState = EditorState.createWithContent(contentState);
        const stateWithContentAndSelection = EditorState.moveFocusToEnd(editorState)
        value = stateWithContentAndSelection;
        setEditorState(value);
        setValueData(true);
      }
    }
    setEditorState(value);
  }
  const DateConverstion=(value:any)=>{
     
  const defaultValues = formProps.control.defaultValuesRef.current;

  console.log('Date Conversion default Value:',defaultValues.date);
    console.log('Date Conversion Value:',defaultValues.date);
    const date: Date = new Date(defaultValues.date);
    const month = ((date.getMonth() + 1) < 10) ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
    const day = ((date.getDate()) < 10) ? `0${date.getDate()}` : `${date.getDate()}`
    const formattedDate = `${date.getFullYear()}-${month}-${day}`;
    setArticleDate(formattedDate);
    setDate(true);
  }

  return (
    <FormSection
      title={t("article.title")}
      subtitle={t("article.basicSection.subTitle")}
    >
      <div className="row">
        <FormInput
          label={t("article.basicSection.title")}
          name="title"
          type="text"
          className="col-md-6"
          validation={{ required: true }}
          tabIndex={1}
          autoFocus
          {...formProps}
        />
         <Controller
            name="date"
            defaultValue={null}
            control={control}
            render={({ value, onChange }) => {
              if(!date){
               DateConverstion(value)}
               else if(value){setArticleDate(value)}
              return   <FormInput
              label={t("article.basicSection.date")}
              name="date"
              type="date"
              value={articleDate}
              onChange={onChange}
              className="col-md-6"
              validation={{ required: true }}
              tabIndex={3}
              {...formProps}
            />
            }}
            />
      
      </div>
      <div className="row">
      <FormTextArea
        name="excerpt"
        className="col-md-12"
        label={t("article.basicSection.excerpt")}
        rows={3}
        tabIndex={2}
        {...formProps}
      />
      </div>
      <div className="row">
        <FormInput
          label={''}
          name="description"
          value={description}
          type="hidden"
          {...formProps}
        />

        <div className='editor'>
          <Controller
            name="editorData"
            defaultValue={null}
            control={control}
            render={({ value, onChange }) => {
              if (valueData === false) {
               valueConverstion(value)
              }
              return <Editor
                editorState={editorState}
                defaultEditorState={editorState}
                onEditorStateChange={onEditorStateChange}
                onChange={onChange}
                editorStyle={{height:'120px'}}
                placeholder={t('common.enterText')}
                stripPastedStyles={true}
                toolbar={{
                  options: ['inline', 'blockType', 'fontSize', , 'list', 'textAlign', , 'link', , 'emoji', 'image', 'remove', 'history'],
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                }}
              />
            }}
          />
        </div>
      </div>
    </FormSection>
  );

}
