import { RouteProps } from "react-router-dom";
import { DashboardBooking } from "./booking/booking.component";
import { DashboardMap } from "./map/map.component";
import { DashboardOverview } from "./overview/overview.component";
import { DashboardRevenue } from "./revenue/revenue.component";
import { DashboardSession } from "./session/session.component";
import { DashboardTrip } from "./trip/trip.component";
import { DashboardDispatch } from "./dispatch/dispatch.component";

export enum DashboardRoute {
  Overview = '/dashboard/overview',
  Revenue = '/dashboard/revenue',
  Map = '/dashboard/map',
  Dispatch = '/dashboard/dispatch',
  Bookings = '/dashboard/bookings',
  Trips = '/dashboard/trips',
  Sessions = '/dashboard/sessions',
}

export const DashboardRouteMap = [
  {
    path: DashboardRoute.Overview,
    component: DashboardOverview,
  },
  {
    path: DashboardRoute.Revenue,
    component: DashboardRevenue,
  },
  {
    path: DashboardRoute.Map,
    component: DashboardMap,
  },
  {
    path: DashboardRoute.Dispatch,
    component: DashboardDispatch,
  },
  {
    path: DashboardRoute.Bookings,
    component: DashboardBooking,
  },
  {
    path: DashboardRoute.Trips,
    component: DashboardTrip,
  },
  {
    path: DashboardRoute.Sessions,
    component: DashboardSession,
  },
] as RouteProps[];
