import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatsComponent } from '../../../components/stats/stats.component';
import { ContentWrapper, ViewContent } from '../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';
import { Driver } from '../../../entities/driver.entity';
import { Group } from '../../../entities/group.entity';
import { GroupDrivers } from '../../../entities/group.entity copy';
import { getDatesForFilters, StatsFilterPeriod, StatsRequest, StatsResponse } from '../../../interfaces/stats.interface';
import { Api, Endpoint } from '../../../services/api.service';
import { getCurrencyFormatting, getGeneralDistanceFormat, getGeneralTimeFormat } from '../../../util/distance';

interface Props {
  selectedGroup: Group
  onRefresh?: () => void,
  setSelectedGroup?: (group: undefined | Group) => void
}
export function SelectedItemContent({ selectedGroup, onRefresh, setSelectedGroup }: Props) {
  const [stats, setStats] = useState<StatsResponse>();
  const { t } = useTranslation(['forms', 'dashboard']);
  selectedGroup.leadDriver = selectedGroup.leadDriver as Driver;
  const [time, setTime] = useState<StatsFilterPeriod>(StatsFilterPeriod.Today);

  useEffect(() => {
    getStats(time);
  }, []);

  async function getStats(time: StatsFilterPeriod, customStart?: Date, customEnd?: Date) {
    setTime(time); if (time === StatsFilterPeriod.Custom && !customStart) return;
    setStats(undefined);
    let startDate: Date, endDate: Date;
    if (customStart && customEnd) {
      startDate = customStart; endDate = customEnd;
    }
    else ({ startDate, endDate } = getDatesForFilters(time));
    const response = await Api.get<StatsResponse, StatsRequest>(Endpoint.STATS, {
      group: selectedGroup.id, timeline: time, startDate, endDate
    });
    setStats(response);
  }

  const onclickRecover = async (selectedGroup: Group) => {
    console.log('Recover This Group:', selectedGroup);
    const response = await Api.post<Group, { id: string }>(Endpoint.GROUP_RECOVER, { id: selectedGroup.id as string });
    console.log('Recover This Group Response:', response)
    if (onRefresh && setSelectedGroup) {
      setSelectedGroup(undefined);
      onRefresh();
    }
  }

  function onCustomSelect(startDate: Date, endDate: Date) {
    getStats(StatsFilterPeriod.Custom, startDate, endDate);
  }

  return (
    <ContentWrapper className="row">
      <ViewContent
        className="col-md-6"
        title={selectedGroup.title}
      >
        <ViewContentItems>
          {selectedGroup.deletedTime ? <div className="alert alert-success borderless">
            <h5 className="alert-heading">Group Recovery</h5>
            <p>Do you want to recover this group data?</p>
            <div className="alert-btn">
              <a className="btn btn-white-gold" href={'#href'} onClick={() => onclickRecover(selectedGroup)}>
                <i className="os-icon os-icon-ui-92"></i>
                <span>{'Recover Group'}</span>
              </a>
            </div>
          </div> : null
          }
          <ViewContentItem title={t('group.basicSection.title')}>
            {selectedGroup.title}
          </ViewContentItem>

          <ViewContentItem title={t('group.basicSection.subTitle')}>
            {selectedGroup.description}
          </ViewContentItem>

          {(selectedGroup && selectedGroup.leadDriver) && (
            <ViewContentItem title={t('group.basicSection.leaderName')}>
              {(selectedGroup.leadDriver.firstName + " " + selectedGroup.leadDriver.lastName)}
            </ViewContentItem>
          )}

          {(selectedGroup && selectedGroup.groupDrivers && selectedGroup.groupDrivers.length > 0) && (
            <ViewContentItem title={t('group.basicSection.members')}>
              {(selectedGroup.groupDrivers as GroupDrivers[]).map((item,i) => {
                if ((item.driver as Driver)?.id !== (selectedGroup?.leadDriver as Driver)?.id) {
                  return <React.Fragment key={i}>
                    {(item.driver as Driver)?.firstName + " " + (item.driver as Driver)?.lastName}<br />
                  </React.Fragment>
                }
                return;
              })}
            </ViewContentItem>
          )}
        </ViewContentItems>
      </ViewContent>
      
      <div className='col-md-6 p-1'>
        <StatsComponent
          currentRange={time} onRangeChange={(e) => getStats(e.target.value)} onCustomSelect={onCustomSelect}
          data={!stats ? [] : [
            { isPositive: stats.tripCount >= stats.prevTripCount, label: "Rides", value: stats.tripCount, percentage: stats.tripCountPercentage },
            { isPositive: stats.revenue >= stats.prevRevenue, label: "Earnings", value: getCurrencyFormatting(stats.revenue), percentage: stats.revenuePercentage },
            { isPositive: stats.distance >= stats.prevDistance, label: "Distance", value: getGeneralDistanceFormat(stats.distance), percentage: stats.distancePercentage },
            { isPositive: stats.rideTime >= stats.prevRideTime, label: "Shift Time", value: getGeneralTimeFormat(Math.floor(stats.rideTime)), percentage: stats.rideTimePercentage }
          ]}
        />
      </div>
    </ContentWrapper>
  );
}