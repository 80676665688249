import React, { useContext } from "react";
import { CallContext } from "../../context/call.context";
import { Call } from "../../enums/call.enum";
import { DraggableView } from "../draggable/drag.div";

export function CallModal() {
    const callContext = useContext(CallContext);
    return (
        <DraggableView visible={!!callContext.callStatus} className={"call-floating-button"}>
            <div style={{ width: '50vh', height: '100%', padding: '5%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' }}>
                <div style={{ flex: 0.7 }}>
                    {callContext.timePosition === "top" ? (
                        <>
                            <p className="calling">{callContext.time}</p>
                            <p className="time">{callContext.callMessage}</p>
                        </>
                    ) : (
                        <>
                            <p className="calling">{callContext.callMessage}</p>
                            <p className="time">{callContext.time}</p>
                        </>
                    )}
                </div>

                <div style={{ display: 'flex', flex: 0.3, flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>
                    <li style={{ listStyleType: "none" }}>
                        <a href="#group" onClick={() => callContext.setMic(!callContext.mic)}>
                            {callContext.mic && (
                                <i className={"os-icon os-icon-mic"} style={{ fontSize: 18, color: "white" }}></i>
                            )}
                            {!callContext.mic && (
                                <i className={"os-icon os-icon-mic-off"} style={{ fontSize: 18, color: "white" }}></i>
                            )}
                        </a>
                    </li>
                    <li style={{ listStyleType: "none" }}>
                        <a href="#group" onClick={() => callContext.endCall(true, Call.Status.PassengerDeclined)} style={{
                            borderRadius: 100 / 2,
                            backgroundColor: '#e65252',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }} >
                            <i className={`os-icon os-icon-cancel-circle`} style={{ fontSize: 18, color: 'white' }}></i>
                        </a>
                    </li>
                </div>
            </div>
        </DraggableView>
    )
}