import { Autocomplete, DirectionsRenderer, GoogleMap, TrafficLayer } from '@react-google-maps/api';
import { add, formatRelative } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { Booking, BookingStatus } from '../../../entities/booking.entity';
import { Driver } from '../../../entities/driver.entity';
import { Passenger } from '../../../entities/passenger.entity';
import { Zone } from '../../../entities/zone.entity';
import { Api, Endpoint } from '../../../services/api.service';
import { toSubUnit } from '../../../util/currency';
import * as Style from './dispatch.style';


interface FilterType {
  id: string;
  name: string;
  filters: BookingStatus[];
  count: number;
  icon: string;
  color?: FilterTypeColor;
}

type FilterTypeColor = 'green' | 'blue' | 'yellow' | 'red';

interface BookingListRequest {
  status: BookingStatus[];
}

interface BookingListResponse {
  items: Booking[];
  itemCount: number;
  pageCount: number;
  totalItems: number;
}

interface DriverListResponse {
  items: Driver[];
  itemCount: number;
  pageCount: number;
  totalItems: number;
}

const filterTypes: FilterType[] = [
  {
    id: 'pending',
    name: 'Pending',
    filters: [
      BookingStatus.AwaitingAccept,
      BookingStatus.NoDriver,
      BookingStatus.NotAccepted,
      BookingStatus.ScheduledPending,
      BookingStatus.ScheduledPendingLastAttempt,
    ],
    count: 5,
    icon: 'sidebar',
    color: 'yellow'
  },
  {
    id: 'ongoing',
    name: 'Ongoing',
    filters: [
      BookingStatus.Arrived,
      BookingStatus.DestinationEnroute,
      BookingStatus.DriverEnroute,
      BookingStatus.Dropoff,
      BookingStatus.MeterPaused,
      BookingStatus.Pickup,
      BookingStatus.ScheduledDriverEnroute,
    ],
    count: 1,
    icon: 'ui-92',
    color: 'blue',
  },
  {
    id: 'scheduled',
    name: 'Scheduled',
    filters: [
      BookingStatus.ScheduledTaken,
    ],
    count: 22,
    icon: 'ui-83',
    color: 'green',
  },
  {
    id: 'cancelled',
    name: 'Cancelled',
    filters: [
      BookingStatus.Abandoned,
      BookingStatus.DispatchCancelled,
      BookingStatus.DriverCancelled,
      BookingStatus.PassengerCancelled,
      BookingStatus.ScheduledTakenCancelled,
    ],
    count: 1,
    icon: 'delete',
    color: 'red',
  },
  {
    id: 'all',
    name: 'All',
    filters: [
      BookingStatus.Abandoned,
      BookingStatus.Arrived,
      BookingStatus.AwaitingAccept,
      BookingStatus.Completed,
      BookingStatus.DestinationEnroute,
      BookingStatus.DispatchCancelled,
      BookingStatus.DriverCancelled,
      BookingStatus.DriverEnroute,
      BookingStatus.Dropoff,
      BookingStatus.MeterPaused,
      BookingStatus.NoDriver,
      BookingStatus.NotAccepted,
      BookingStatus.PassengerCancelled,
      BookingStatus.Pickup,
      BookingStatus.ScheduledDriverEnroute,
      BookingStatus.ScheduledPending,
      BookingStatus.ScheduledPendingLastAttempt,
      BookingStatus.ScheduledTaken,
      BookingStatus.ScheduledTakenCancelled,
    ],
    count: 27,
    icon: 'aperture',
  },
];

let searchAutocompletePlaceChangedListener: google.maps.MapsEventListener;
let bookingMarkers: google.maps.marker.AdvancedMarkerView[] = []; 
let directionsService: google.maps.DirectionsService;

export function DashboardDispatch(props: any) {

  let map = useRef<GoogleMap | null>(null);
  let searchAutocompleteRef = useRef<Autocomplete>(null);

  const [showTrafficLayer, setShowTrafficLayer] = useState(false);
  const [center, setCenter] = useState<google.maps.LatLng>();
  const [zoom, setZoom] = useState(12);
  const [navOpen, setNavOpen] = useState(false);
  const [navSelected, setNavSelected] = useState<FilterType>(filterTypes[0]);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [filteredDrivers, setFilteredDrivers] = useState<Driver[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<Driver>();
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [selectedBooking, setSelectedBooking] = useState<Booking>();
  const [isDeletingBooking, setIsDeletingBooking] = useState(false);
  const [isDriverSearching, setIsDriverSearching] = useState(false);
  const [isDriverAssigning, setIsDriverAssigning] = useState(false);
  const [directions, setDirections] = useState<google.maps.DirectionsResult>();

  const init = () => {
    loadBookings();
    loadDrivers();

    return () => {
      searchAutocompletePlaceChangedListener?.remove();
    }
  }

  const loadBookings = async () => {
    const response = await Api.get<BookingListResponse, BookingListRequest>(Endpoint.BOOKING_LIST, {
      status: navSelected.filters
    });
    setBookings(response.items.filter(booking => navSelected.filters.includes(booking.status)));
  }

  const loadDrivers = async () => {
    const response = await Api.get<DriverListResponse, {}>(Endpoint.DRIVER_LIST);
    setDrivers(response.items);
  }

  const initMap = () => {
    const centerLat = process.env.REACT_APP_MAP_CENTER_LAT as unknown as number;
    const centerLng = process.env.REACT_APP_MAP_CENTER_LNG as unknown as number;

    if (centerLat && centerLng) {
      setCenter(new google.maps.LatLng(centerLat, centerLng));
    }

    directionsService = new google.maps.DirectionsService();
  }

  const initAutocomplete = () => {
    const autocomplete = searchAutocompleteRef.current?.state.autocomplete;
    if (!autocomplete) return;
    searchAutocompletePlaceChangedListener = autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place || !place.geometry || !place.geometry.location) return;
      const mapCenter = new google.maps.LatLng(
        place.geometry?.location.lat() as number,
        place.geometry?.location.lng() as number
      );

      setCenter(mapCenter);
      setZoom(15);
    });
  }

  const updateBookingMarkers = () => {
    if (!map.current || !map.current.state.map || !google.maps.marker) {
      return;
    }

    for (let marker of bookingMarkers) {
      marker.map = null;
    };

    bookingMarkers = [];
    
    const bounds = new google.maps.LatLngBounds()
    for (let booking of bookings) {
      const markerDiv = document.createElement('div');
      markerDiv.className = `dispatch-booking-marker ${getBookingColor(booking)}`;
      markerDiv.textContent = booking.code;
      markerDiv.id = booking.id;

      const marker = new google.maps.marker.AdvancedMarkerView({
        //@ts-ignore
        map: map.current.state.map,
        position: { lat: booking.pickupAddress.lat, lng: booking.pickupAddress.lng },
        content: markerDiv,
      });
      bookingMarkers.push(marker);

      bounds.extend(marker.position as google.maps.LatLng);
      marker.addListener('click', () => onBookingMarkerClick(marker, booking));
    }

    if (bookings.length) {
      map.current.state.map.fitBounds(bounds);
    }
    const z = map.current.state.map.getZoom()
    if (z && z > 11) {
      setZoom(12);
    }
  }

  const onBookingSelected = () => {
    for (let marker of bookingMarkers) {
      marker.content?.classList.remove('selected');
      marker.zIndex = null;
    }
    if (!selectedBooking) {
      setDirections(undefined);
      updateBookingMarkers();
      return;
    }
    const marker = bookingMarkers.find(marker => marker.content?.id === selectedBooking.id);
    if (marker) {
      marker.content?.classList.add('selected');
      marker.zIndex = 1;
    }

    const departureTime = add(new Date(), { minutes: 10, hours: 5 });
      if (directionsService && selectedBooking.pickupAddress && selectedBooking.dropOffAddress) {
         const directionsOptions: google.maps.DirectionsRequest = {
            origin: new google.maps.LatLng(selectedBooking.pickupAddress.lat, selectedBooking.pickupAddress.lng),
            destination: new google.maps.LatLng(selectedBooking.dropOffAddress.lat, selectedBooking.dropOffAddress.lng),
            travelMode: google.maps.TravelMode.DRIVING,
            durationInTraffic: true,
            drivingOptions: { departureTime },
         };
         directionsService.route(directionsOptions, (result, status) => {
            if (result && status === google.maps.DirectionsStatus.OK) {
               setDirections(result);
            }
         })
      } else {
        setDirections(undefined);
      }
  }

  const onBookingMarkerClick = (marker: google.maps.marker.AdvancedMarkerView, booking: Booking) => {
    setSelectedBooking(booking);
  }

  const onBookingHover = (booking?: Booking) => {
    for (let marker of bookingMarkers) {
      marker.content?.classList.remove('highlight');
      if (marker.content?.id !== selectedBooking?.id) {
        marker.zIndex = null;
      }
    }
    if (!booking) return;
    const marker = bookingMarkers.find(marker => marker.content?.id === booking.id);
    if (marker) {
      marker.content?.classList.add('highlight');
      marker.zIndex = 1;
    }
  }

  const onBookingClick = (booking: Booking) => {
    setSelectedBooking(booking);
  }

  const onSelectedBookingClose = () => {
    setSelectedBooking(undefined);
  }

  const onNavChanged = () => {
    loadBookings();
  }

  const onDriverSearch = (search: string) => {
    search = search.toLowerCase();
    setIsDriverSearching(true);
    setTimeout(() => {
      const _drivers = drivers.filter(driver => 
        driver.firstName.toLowerCase().startsWith(search)
        || driver.lastName.toLowerCase().startsWith(search)
        || driver.code.toLowerCase().includes(search)
      );
      setFilteredDrivers(_drivers);
      setIsDriverSearching(false);
    }, 500);
  }

  const onDriverAssign = async () => {
    if (!selectedBooking || !selectedDriver) return;
    setIsDriverAssigning(true);
    await Api.patch(Endpoint.ASSIGN_BOOKING, {
      driverId: selectedDriver.id,
      bookingId: selectedBooking.id,
    });
    setSelectedDriver(undefined);
    setIsDriverAssigning(false);
    loadBookings();
  }

  const cancelSelectedBooking = async () => {
    if (!selectedBooking) return;

    await Api.patch(Endpoint.CANCEL_BOOKING, { code: selectedBooking.code });
    setSelectedBooking(undefined);
    setIsDeletingBooking(false);
    loadBookings();
  }

  const onCenterChanged = () => {
    map.current?.state.map?.setOptions({ center });
  }
  const onZoomChanged = () => {
    map.current?.state.map?.setOptions({ zoom });
  }

  const getBookingColor = (booking: Booking): FilterTypeColor | undefined =>{
    const matchingFilterType = filterTypes.find((filterType: FilterType) =>
      filterType.filters.includes(booking.status) ? filterType : undefined);
    return matchingFilterType?.color;
  }

  const isBookingAssignable = (booking: Booking): boolean => {
    const filters = filterTypes.find(filterType => filterType.id === 'pending')!.filters
    return filters.includes(booking.status);
  }

  const isBookingCancelleable = (booking: Booking): boolean => {
    let filters = filterTypes.find(filterType => filterType.id === 'pending')!.filters
    filters = filters.concat(filterTypes.find(filterType => filterType.id === 'scheduled')!.filters)
    return filters.includes(booking.status);
  }
  
  const getPassengerFullname = (booking: Booking): string => {
    return [
      (booking.passenger as Passenger).firstName,
      (booking.passenger as Passenger).lastName,
    ].join(' ');
  }

  const getEstimate = (booking: Booking): string => {
    return toSubUnit(booking.estimate || 0).toString();
  }

  const getDateRelativeFromNow = (date: Date): string => {
    return formatRelative(new Date(date), new Date());
  }

  const getStatusText = (booking: Booking): string => {
    return booking.status.replaceAll('-', ' ').toUpperCase();
  }

  const getDriverText = (driver: Driver): string => {
    return `[${ (driver as Driver).code }] ${[driver.firstName, driver.lastName].join(' ')}`;
  }

  useEffect(updateBookingMarkers, [bookings]);
  useEffect(onBookingSelected, [selectedBooking]);
  useEffect(onNavChanged, [navSelected]);
  useEffect(onCenterChanged, [center]);
  useEffect(onZoomChanged, [zoom]);
  useEffect(init, []);

  return (
    <div style={Style.Container}>

      <div className="dispatch-sidebar" style={{ ...Style.Sidebar }}>
        <div className={`fancy-selector-w ${ navOpen && 'opened'}`}>
          <div className="fancy-selector-current">
            <div className="fs-main-info">
              <div className="fs-name">
                <i className={`os-icon os-icon-${navSelected.icon}`} />&nbsp;
                <span>{ navSelected.name }</span>
                {/* <strong>{ navSelected.count }</strong> */}
              </div>
            </div>
            <div className="fs-selector-trigger" onClick={ () => setNavOpen(!navOpen) }>
              <i className="os-icon os-icon-arrow-down4"></i>
            </div>
          </div>
          <div className="fancy-selector-options">
            { filterTypes.map(filterType => (
              <div key={filterType.id}
                className={`fancy-selector-option ${navSelected.name === filterType.name ? 'active' : ''}`}
                onClick={() => { setNavSelected(filterType); setNavOpen(false); }}>
                <div className="fs-main-info">
                  <div className="fs-name">
                    <i className={`os-icon os-icon-${filterType.icon}`} />&nbsp;
                    <span>{ filterType.name }</span>
                    {/* <strong>{ filterType.count }</strong> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="dispatch-bookings">
          { !!bookings.length && bookings.map(booking => (
          <div key={ booking.id }
            className={`pipeline-item ${selectedBooking?.id === booking.id ? 'selected' : ''}`}
            onClick={() => onBookingClick(booking)}
            onMouseEnter={() => onBookingHover(booking)}
            onMouseLeave={() => onBookingHover()}>
            <div className="pi-controls">
              <div className={`status status-${getBookingColor(booking)}`}
                data-placement="top" data-toggle="tooltip" title=""
                data-original-title="Active Status"></div>
            </div>
            <div className="pi-body">
              <div className="pi-info">
                <div className="h6 pi-name">{ booking.code }</div>
                <div className="pi-sub">{ booking.pickupAddress.text }</div>
                { booking.dropOffAddress && <div className="pi-sub">{ booking.dropOffAddress.text }</div> }
              </div>
            </div>
            <div className="pi-foot">
              { booking.scheduledTimestamp && (
              <a className="extra-info" href="#">
                <i className="os-icon os-icon-calendar-time"></i>
                <span>{ getDateRelativeFromNow(booking.scheduledTimestamp) }</span>
              </a>
              )}
            </div>
          </div>
          ))}
          { !bookings.length && (
            <div className="pi-body">
            <div className="pi-info">
              <div className="h6 pi-name"><br />No {navSelected.id !== 'all' ? navSelected.id : ''} bookings</div>
            </div>
          </div>
          )}
        </div>
      </div>

      <GoogleMap
        id="map"
        ref={map}
        options={{
          mapId: "1ca1729e2c7f8ab0",
          disableDefaultUI: true,
          scaleControl: true,
          zoomControl: true,
        }}
        mapContainerStyle={{ ...Style.MapContainer, flex: 4 }}
        center={center}
        zoom={zoom}
        onLoad={initMap}
        mapTypeId={ google.maps.MapTypeId.ROADMAP }
        extraMapTypes={[]}
      >
        {showTrafficLayer ? (
          <TrafficLayer
            onLoad={(layer: google.maps.TrafficLayer) => {
              layer.setMap(map.current?.state.map as google.maps.Map);
            }}
            options={{
              autoRefresh: true,
            }}
          />
        ) : null}
        <Autocomplete ref={searchAutocompleteRef} fields={["geometry"]} onLoad={initAutocomplete}>
          <input
            style={{ zIndex: "1" }}
            autoFocus
            className="dispatch-map-search"
            required
            placeholder="Search map..."
            type="search"
          />
        </Autocomplete>
        {directions && <DirectionsRenderer options={{ directions, preserveViewport: false }} />}
      </GoogleMap>

      { selectedBooking && (
        <div className="dispatch-selected-booking" style={{ ...Style.Sidebar, flex: 2 }}>
          <div className="property-single">
          <div className="property-info-w">
            <div className="property-info-side">
              <div className="side-section">
                <div className="side-section-header">
                  <span>Booking { selectedBooking.code }</span>
                  <a href="#" className="dispatch-selected-close" onClick={onSelectedBookingClose}>X</a>
                </div>
                <div className="side-section-content">
                  <div className="property-side-features">
                    <div className="feature">
                      <i className="os-icon os-icon-home"></i>
                      <div className="feature-text">
                        <span>Pickup Location</span>
                        <div>{ selectedBooking.pickupAddress.text }</div>
                      </div>
                    </div>
                    { selectedBooking.dropOffAddress && (
                    <div className="feature">
                      <i className="os-icon os-icon-map-pin"></i>
                      <div className="feature-text">
                        <span>Pickup Location</span>
                        <div>{ selectedBooking.dropOffAddress.text }</div>
                      </div>
                    </div>
                    )}
                    { selectedBooking.estimate && (
                    <div className="feature">
                      <i className="os-icon os-icon-dollar-sign"></i>
                      <div className="feature-text">
                        <span>Estimate</span>
                        <div>{ getEstimate(selectedBooking) }</div>
                      </div>
                    </div>
                    )}
                    { selectedBooking.scheduledTimestamp && (
                    <div className="feature">
                      <i className="os-icon os-icon-calendar-time"></i>
                      <div className="feature-text">
                        <span>Scheduled Time</span>
                        <div>{ getDateRelativeFromNow(selectedBooking.scheduledTimestamp) }</div>
                      </div>
                    </div>
                    )}
                     { selectedBooking.zone && (
                    <div className="feature">
                      <i className="os-icon os-icon-map"></i>
                      <div className="feature-text">
                        <span>Zone</span>
                        <div>{ (selectedBooking.zone as Zone).title }</div>
                      </div>
                    </div>
                    )}
                    { selectedBooking.passenger && (
                    <div className="feature">
                      <i className="os-icon os-icon-home-34"></i>
                      <div className="feature-text">
                        <span>Passenger</span>
                        <div>{ getPassengerFullname(selectedBooking) }</div>
                      </div>
                    </div>
                    )}
                    { selectedBooking.passenger && (
                    <div className="feature">
                      <i className="os-icon os-icon-user"></i>
                      <div className="feature-text">
                        <span>Phone Number</span>
                        <div>{ (selectedBooking.passenger as Passenger).phone }</div>
                      </div>
                    </div>
                    )}
                    <div className="feature">
                      <i className="os-icon os-icon-activity"></i>
                      <div className="feature-text">
                        <span>Status</span>
                        <div>{ getStatusText(selectedBooking) }</div>
                      </div>
                    </div>
                    { isBookingAssignable(selectedBooking) && !isDriverAssigning && (
                    <div className="feature">
                      <i className="os-icon os-icon-others-43"></i>
                      <div className="feature-text">
                        <span>Assign Driver</span>
                        <div>
                          <AsyncTypeahead
                            id="driver-search"
                            filterBy={ () => true }
                            onSearch={ onDriverSearch }
                            onChange={ (option) => setSelectedDriver(option[0] as Driver) }
                            isLoading={ isDriverSearching }
                            minLength={ 3 }
                            labelKey="code"
                            placeholder="Search driver by code or name" 
                            options={ filteredDrivers }
                            renderMenuItemChildren={ (driver: unknown) => (
                              <span>{ getDriverText(driver as Driver) }</span>
                            )}
                            flip={ true }
                            highlightOnlyResult={ true }
                            emptyLabel="No drivers"
                          >

                          <button className="btn btn-upper btn-icon-only btn-success"
                          disabled={ !selectedDriver } onClick={ onDriverAssign }>
                            <span className="os-icon os-icon-chevrons-right"></span>
                          </button>
                            </AsyncTypeahead>
                        </div>
                      </div>
                    </div>
                    )}
                    { isDriverAssigning && selectedDriver && (
                    <div className="feature warning">
                      <i className="os-icon os-icon-loader"></i>
                      <div className="feature-text">
                        <span>Assigning Driver...</span>
                        <div>{ `Assigning ${getDriverText(selectedDriver)} to booking ${selectedBooking.code}` }</div>
                      </div>
                    </div>
                    )}
                    { isBookingCancelleable(selectedBooking) && !isDeletingBooking && !isDriverAssigning && (
                    <div className="feature">
                      <i className="os-icon os-icon-zap"></i>
                      <div className="feature-text">
                        <span>Actions</span>
                        <div>
                        <a className="btn btn-sm btn-outline-danger" href="#" onClick={ () => setIsDeletingBooking(true) }>
                          Cancel Booking&nbsp;
                          <em className="os-icon os-icon-cancel-circle"></em></a>
                        </div>
                      </div>
                    </div>
                    )}
                    { isBookingCancelleable(selectedBooking) && isDeletingBooking  && !isDriverAssigning && (
                    <div className="feature">
                      <i className="os-icon os-icon-zap"></i>
                      <div className="feature-text">
                        <span>Confirm Delete Booking?</span>
                        <div>
                          <a className="btn btn-sm btn-danger" href="#"
                            onClick={ () => { cancelSelectedBooking() }}>
                            Yes, Cancel Booking
                          </a>
                          <a className="btn btn-sm btn-white" href="#"
                            onClick={ () => setIsDeletingBooking(false) }>
                              No, Don't Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      )}
    </div>
  );
}