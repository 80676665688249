import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useWindowScroll } from "react-use";
import { Loading } from "../../../components/alerts/loading.component";
import { GroupFilter, ListFilter } from "../../../components/list/filter.component";
import { ListItem, ListItems, ListItemStatus } from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { View } from "../../../components/view/wrapper.component";
import { Group } from "../../../entities/group.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormStyle } from "../../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../../services/api.service';
import { GroupPost } from "../post/post.component";
import { SelectedItemContent } from "./content.component";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function showToast(message: string) {
  toast.info(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

const groupFilters: GroupFilter[] = [
  { title: 'all', icon: 'phone-21' },
  { title: "deleted", icon: "trash" },

];

export function GroupList(props: any) {
  const { y: pageYOffset } = useWindowScroll();
  const [search, setSearch] = useState<string>('');
  const [allCount, setAllCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(1);
  const [groups, setGroups] = useState<Group[] | undefined>(undefined);
  const [selectedGroup, setSelectedGroup] = useState<Group>();
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);
  const [size, setSize] = useState<number>()
  const [deletedCount, setDeletedCount] = useState<number>(0);
  const { t } = useTranslation('list');

  useEffect(() => {
    setSelectedGroup(props.location.state)
  }, [props.location.state])

  const refresh = useCallback(async (e?: any) => {
    try {
      let group: string | undefined = activeGroupFilter?.title;
      group = group === 'all' ? undefined : group;

      let isDeleted = false;
      console.log('This is Group before:', group);
      if (group === "deleted") {
        group = undefined;
        isDeleted = true;
        console.log('This is Group Inside:', group);
      }
      const { items, totalItems } = await Api.get<IPaginationResponse<Group>, IPaginationOptions>(Endpoint.GROUP_LIST, { page: 0, limit, group, search, isDeleted });

      console.log('This is Group items:', group,items,totalItems);
      if (items && items.length !== totalItems) setHasMore(true)
      else setHasMore(false);

      if (items) {
        if (props.location.state !== null && !selectedGroup) {
          setSelectedGroup(props.location.state);
        }
        setGroups(items);
      }

      const response = await Api.get<any, any>(Endpoint.GROUP_DELETED_COUNT);
      setDeletedCount(response);
      const ITEMS = await Api.get<
      IPaginationResponse<Group>,
      IPaginationOptions
    >(Endpoint.GROUP_LIST, { limit: 10000000 });
      setAllCount(ITEMS.totalItems);
      console.log("This is deleted Count:", response)
      console.log("This is totalItems Count:",ITEMS.totalItems)
      if (items.length > 0 && !selectedGroup) {
        setSelectedGroup(items[0]);
      }
      if (selectedGroup && e) {
        const data = items.filter((item: Group) => { return item.id === selectedGroup.id })
        setSelectedGroup(data[0]);
      }
    }
    catch (err: any) {
      showToast(err.message || err);
    }
  }, [search, limit, activeGroupFilter]);

  useEffect(() => { refresh(); }, [refresh]);

  const fetchMoreData = () => {
    setLimit(limit + 10);
  };

  const deleteGroup = async () => {
    setConfirmingDelete(false);
    await Api.delete(Endpoint.GROUP, { id: selectedGroup?.id });
    setSelectedGroup(undefined);
    refresh();
  }

  const toListItems = (group?: Group): ListItem<Group> | undefined => {
    if (!group) {
      return;
    }

    return {
      id: group.id as string,
      title: group.title,
      status: ListItemStatus.Green,
      deleted: group.deletedTime,
      ref: group
    }
  }

  const addGroup = () => {
    setFormMode(FormMode.Adding);
  }

  const editGroup = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (groups) {
      const currentGroupIndex = groups.findIndex(group => !!selectedGroup && group.id === selectedGroup.id);
      if (currentGroupIndex + 1 < groups.length && currentGroupIndex !== -1) {
        setSelectedGroup(groups[currentGroupIndex + 1]);
      }
    }
  }

  const onPrev = () => {
    if (groups) {
      const currentGroupIndex = groups.findIndex(group => !!selectedGroup && group.id === selectedGroup.id);
      if (currentGroupIndex - 1 > -1 && currentGroupIndex !== -1) {
        setSelectedGroup(groups[currentGroupIndex - 1]);
      }
    }
  }

  const selectGroupFilter = (target: GroupFilter) => {
    setSelectedGroup(undefined);
    setActiveGroupFilter(target);
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }
  let history = useHistory();
  function checkAudit(id: string | undefined): void | undefined {
    history.push(`/audit/${id}`);
  }

  const updateSize = () => {
    setSize(window.screen.width)
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <div>
      {size && size < 1000 ?
        <List>
          <div className="row">
            <div className="col-xl-12">
              <ListFilter
                groupFilters={[
                  { title: 'all', icon: 'phone-21', count: allCount },
                  { title: "deleted", icon: "trash", count: deletedCount },
                ]}
                activeGroupFilter={activeGroupFilter}
                onGroupSelect={selectGroupFilter}
              />
            </div>
            <div className="col-xl-12">
              <ListItems
                items={groups?.map(toListItems) as ListItem<Group>[]}
                activeItem={toListItems(selectedGroup)}
                onSearchChange={onSearchChange}
                showSearch={true}
                showRefresh={true}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                onRefresh={() => { setGroups(undefined); refresh(); }}
                onClick={(item: ListItem<Group>) => { setSelectedGroup(item.ref); }}
              />
            </div>
            <div className="col-xl-12" style={{ marginTop: "40px" }}>
              {formMode === undefined ? (
                groups !== undefined ? (
                  selectedGroup ? (
                    <View
                      componentName={"group"}
                      showAdd={true}
                      showAudit={true}
                      showEdit={true}
                      showDelete={selectedGroup.deletedTime ? false : true}
                      showNext={true}
                      showPrev={true}
                      onAdd={addGroup}
                      onEdit={editGroup}
                      onDelete={deleteGroup}
                      onPrev={onPrev}
                      onNext={onNext}
                      onAudit={() => checkAudit(selectedGroup.id)}
                    >
                      <SelectedItemContent
                        selectedGroup={selectedGroup}
                        onRefresh={refresh}
                        setSelectedGroup={setSelectedGroup}
                      />
                    </View>
                  ) : (
                    <div className="mx-auto py-5 text-center">
                      <button onClick={addGroup} className="btn btn-primary text-bold">{t('group.addGroup')}</button>
                    </div>
                  )
                ) : (
                  <div className="mx-auto py-5 text-center">
                    <p>{t('group.wait')}</p>
                    <Loading loading={true} />
                  </div>
                )
              ) : (
                selectedGroup ? (
                  <GroupPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    entityId={selectedGroup.id}
                    onAfterSave={() => refresh(true)}
                  />
                ) : (
                  <GroupPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    onAfterSave={() => refresh(true)}
                  />
                )
              )}
            </div>
          </div>
        </List> :
        <List>
          <ListFilter
            groupFilters={[
              { title: 'all', icon: 'phone-21', count: allCount },
              { title: "deleted", icon: "trash", count: deletedCount },
            ]}
            activeGroupFilter={activeGroupFilter}
            onGroupSelect={selectGroupFilter}
          />

          <ListItems
            items={groups?.map(toListItems) as ListItem<Group>[]}
            activeItem={toListItems(selectedGroup)}
            onSearchChange={onSearchChange}
            showSearch={true}
            showRefresh={true}
            hasMore={hasMore}
            fetchMoreData={fetchMoreData}
            onRefresh={() => { setGroups(undefined); refresh(); }}
            onClick={(item: ListItem<Group>) => { setSelectedGroup(item.ref); }}
          />

          {formMode === undefined ? (
            groups !== undefined ? (
              selectedGroup ? (
                <View
                  componentName={"group"}
                  showAdd={true}
                  showAudit={true}
                  showEdit={true}
                  showDelete={selectedGroup.deletedTime ? false : true}
                  showNext={true}
                  showPrev={true}
                  onAdd={addGroup}
                  onEdit={editGroup}
                  onDelete={deleteGroup}
                  onPrev={onPrev}
                  onNext={onNext}
                  onAudit={() => checkAudit(selectedGroup.id)}
                  childViewStyles={{ padding: 0 }}
                >
                  <SelectedItemContent
                    selectedGroup={selectedGroup}
                    onRefresh={refresh}
                    setSelectedGroup={setSelectedGroup}
                  />
                </View>
              ) : (
                <div className="mx-auto py-5 text-center">
                  <button onClick={addGroup} className="btn btn-primary text-bold">{t('group.addGroup')}</button>
                </div>
              )
            ) : (
              <div className="mx-auto py-5 text-center">
                <p>{t('group.wait')}</p>
                <Loading loading={true} />
              </div>
            )
          ) : (
            selectedGroup ? (
              <GroupPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                entityId={selectedGroup.id}
                onAfterSave={() => refresh(true)}
              />
            ) : (
              <GroupPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh(true)}
              />
            )
          )}
        </List>
      }
      <ToastContainer theme="dark" />
    </div>
  );

}
