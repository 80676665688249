import React, { useCallback, useEffect, useState } from "react";
import { useWindowScroll } from 'react-use';
import { Loading } from "../../../../components/alerts/loading.component";
import { GroupFilter, ListFilter } from "../../../../components/list/filter.component";
import { ListItem, ListItems, ListItemStatus } from "../../../../components/list/items.component";
import { List } from "../../../../components/list/wrapper.component";
import { View } from "../../../../components/view/wrapper.component";
import { FormMode } from "../../../../enums/core.enum";
import { FormStyle } from "../../../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../../../services/api.service';
// import { FeaturePost } from "../post/post.component";
import { SelectedItemContent } from "./content.component";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CorporateAccountStatus, CorporateAccount } from "../../../../entities/corporate-account.entity";
import { CorporateBookingPost } from "../post/post.component";
import { getStorageUrl } from "../../../../util/file.util";

const groupFilters: GroupFilter[] = [
  { title: "all", icon: 'phone-21' },
  { title: "active", icon: 'ui-15' },
  { title: "pending", icon: 'ui-15' },
  { title: "blocked", icon: 'ui-15' },
];
export enum ProfilePicSize {
  List = "admin_list",
  Single = "admin_single",
}

export function CorporateAccountList(props: any) {

  const { t } = useTranslation('list');
  const [search, setSearch] = useState<string>('');
  const [allCount, setAllCount] = useState<number>(0);
  const { y: pageYOffset } = useWindowScroll()
  const [limit, setLimit] = useState<number>(20)
  const [count, setCount] = useState<number>(1);
  const [activeCount, setActiveCount] = useState<number>(0);
  const [pendingCount, setPendingCount] = useState<number>(0);
  const [blockedCount, setBlockedCount] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [corporateAccounts, setCorporateAccounts] = useState<CorporateAccount[] | undefined>(undefined);
  const [selectedCorporateAccount, setSelectedCorporateAccount] = useState<CorporateAccount>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);
  const [size, setSize] = useState<number>()
  const [changeStatus, setChangeStatus] = useState<CorporateAccountStatus | undefined>(undefined)


  const refresh = useCallback(async () => {
    
    let group: string | undefined = activeGroupFilter?.title;
    group = group === 'all' ? undefined : group;
    const { items, totalItems } = await Api.get<IPaginationResponse<CorporateAccount>, IPaginationOptions>(Endpoint.CORPORATE_LIST, { page: 0, limit, group, search });
    console.log("cooperate",items)
    if (items && items.length !== totalItems) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (items) {
      if (props.location.state !== null && !selectedCorporateAccount) {
        setSelectedCorporateAccount(props.location.state);
      }
      setCorporateAccounts(items);

    } 

    const ITEMS = await Api.get<IPaginationResponse<CorporateAccount>, IPaginationOptions>(Endpoint.CORPORATE_LIST, { limit: 10000000 });


    setAllCount(ITEMS.totalItems);
    setActiveCount(ITEMS.items.filter(item => item.status === 'active').length)
    setBlockedCount(ITEMS.items.filter(item => item.status === 'blocked').length)
    setPendingCount(ITEMS.items.filter(item => item.status === 'pending').length)

    if (selectedCorporateAccount?.status === CorporateAccountStatus.Blocked) {
    
      setChangeStatus(CorporateAccountStatus.Active)
    } else if (selectedCorporateAccount?.status === CorporateAccountStatus.Active) {
      console.log("status",CorporateAccountStatus.Active)
      setChangeStatus(CorporateAccountStatus.Blocked)
    } else {
      setChangeStatus(undefined)
    }

    if (items.length > 0 && !selectedCorporateAccount) {
      console.log("item",items[0])
      setSelectedCorporateAccount(items[0]);
    }

   
  }, [search, limit, activeGroupFilter, selectedCorporateAccount]);

  useEffect(() => { refresh(); }, [refresh,]);

  const fetchMoreData = () => {
    setLimit(limit + 10);
  };

  const deleteCorporateAccount = async () => {
    setConfirmingDelete(false);
    // await Api.delete(Endpoint.FEATURE, { id: selectedCorporateAccount?.id });
    setSelectedCorporateAccount(undefined);
    refresh();
  }

  const toListItems = (corporateAccount?: CorporateAccount): ListItem<CorporateAccount> | undefined => {
    if (!corporateAccount) {
      return;
    }

    return {
      id: corporateAccount.id as string,
      title: corporateAccount.companyName,
      status: ListItemStatus.Green,
      ref: corporateAccount
    }
  }

  const addFeature = () => {
    setFormMode(FormMode.Adding);
  }

  const editFeature = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (corporateAccounts) {
      const currentAccountIndex = corporateAccounts.findIndex(corporateAccount => !!selectedCorporateAccount && corporateAccount.id === selectedCorporateAccount.id);
      if (currentAccountIndex + 1 < corporateAccounts.length && currentAccountIndex !== -1) {
        setSelectedCorporateAccount(corporateAccounts[currentAccountIndex + 1]);
      }
    }
  }

  const onPrev = () => {
    if (corporateAccounts) {
      const currentAccountIndex = corporateAccounts.findIndex(corporateAccount => !!selectedCorporateAccount && corporateAccount.id === selectedCorporateAccount.id);
      if (currentAccountIndex - 1 > -1 && currentAccountIndex !== -1) {
        setSelectedCorporateAccount(corporateAccounts[currentAccountIndex - 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedCorporateAccount(undefined);
    setActiveGroupFilter(target);
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  let history = useHistory();
  function checkAudit(id: string | undefined): void | undefined {
    history.push(`/audit/${id}`);
  }

  const updateSize = () => {
    setSize(window.screen.width)
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [])

  const getLogoPic = (corporate: CorporateAccount, type: ProfilePicSize): string|undefined => {
    if (corporate?.logoFile?.id && corporate?.id) {
      return `${getStorageUrl()}/corporateAccount/${corporate.id}/logo/${corporate.logoFile?.id
        }/${type}.${corporate.logoFile?.extension}`;
    } else {
      return undefined;
    }
  };

  const statusChange = async () => {
    if (selectedCorporateAccount?.status === CorporateAccountStatus.Active) {
       // @ts-ignore
      const { data } = await Api.post(Endpoint.CORPORATE_DEACTIVATE, { id: selectedCorporateAccount.id })
      setSelectedCorporateAccount(data);
      // refresh();
    } else if (selectedCorporateAccount?.status === CorporateAccountStatus.Blocked) {
      // @ts-ignore
      const { data } = await Api.post(Endpoint.CORPORATE_ACTIVATE, { id: selectedCorporateAccount.id });
      setSelectedCorporateAccount(data);
      // refresh();
    }
  }

  return (
    <div>
      {size && size < 1000 ?
        <List>
          <div className="row">
            <div className="col-xl-12">
              <ListFilter
                groupFilters={[
                  { title: "all", icon: 'phone-21', count: allCount },
                  { title: "active", icon: 'ui-15', count: activeCount },
                  { title: "blocked", icon: 'ui-15', count: blockedCount },
                ]}
                activeGroupFilter={activeGroupFilter}
                onGroupSelect={selectGroup}
              />
            </div>
            <div className="col-xl-12">
              <ListItems
                items={corporateAccounts?.map(toListItems) as ListItem<CorporateAccount>[]}
                activeItem={toListItems(selectedCorporateAccount)}
                onSearchChange={onSearchChange}
                showSearch={true}
                showRefresh={true}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                onRefresh={() => { setCorporateAccounts(undefined); refresh(); }}
                onClick={(item: ListItem<CorporateAccount>) => { setSelectedCorporateAccount(item.ref); }}
              />
            </div>
            <div className="col-xl-12" style={{ marginTop: "40px" }}>
              {formMode === undefined ? (
                corporateAccounts !== undefined ? (
                  selectedCorporateAccount ? (
                    <View
                      componentName={"corporate"}
                      showAdd={true}
                      showEdit={true}
                      showDelete={false}
                      showAudit={false}
                      showNext={true}
                      showPrev={true}
                      onAdd={addFeature}
                      onEdit={editFeature}
                      onStateChange={statusChange}
                      // onDelete={deleteFeature}
                      changeCorporateAccountStatus={changeStatus}
                      showStateChange={true}
                      onNext={onNext}
                      onPrev={onPrev}
                      onAudit={() => checkAudit(selectedCorporateAccount.id)}
                    >
                      <SelectedItemContent
                        selectedCorporateAccount={selectedCorporateAccount}
                        getLogoPic={getLogoPic}
                      />
                    </View>
                  ) : (
                    <div className="mx-auto py-5 text-center">
                      <button onClick={addFeature} className="btn btn-primary text-bold">{t('corporate.addCorporateAccount')}</button>
                    </div>
                  )
                ) : (
                  <div className="mx-auto py-5 text-center">
                    <p>{t('corporate.wait')}</p>
                    <Loading loading={true} />
                  </div>
                )
              ) : (
                selectedCorporateAccount ? (
                  <CorporateBookingPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    entityId={selectedCorporateAccount.id}
                    onAfterSave={() => refresh()}
                  />
                ) : (
                  <CorporateBookingPost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    onAfterSave={() => refresh()}
                  />
                )
              )}
            </div>
          </div>
        </List> :
        <List>
          <ListFilter
           groupFilters={[
            { title: "all", icon: 'phone-21', count: allCount },
            { title: "active", icon: 'ui-15', count: activeCount },
            { title: "blocked", icon: 'ui-15', count: blockedCount },
          ]}
            activeGroupFilter={activeGroupFilter}
            onGroupSelect={selectGroup}
          />

          <ListItems
            items={corporateAccounts?.map(toListItems) as ListItem<CorporateAccount>[]}
            activeItem={toListItems(selectedCorporateAccount)}
            onSearchChange={onSearchChange}
            showSearch={true}
            showRefresh={true}
            hasMore={hasMore}
            fetchMoreData={fetchMoreData}
            onRefresh={() => { setCorporateAccounts(undefined); refresh(); }}
            onClick={(item: ListItem<CorporateAccount>) => { setSelectedCorporateAccount(item.ref); }}
          />

          {formMode === undefined ? (
            corporateAccounts !== undefined ? (
              selectedCorporateAccount ? (
                <View
                  componentName={"corporate"}
                  showAdd={true}
                  showEdit={true}
                  showDelete={false}
                  showAudit={false}
                  showNext={true}
                  showPrev={true}
                  onAdd={addFeature}
                  onEdit={editFeature}
                  onStateChange={statusChange}
                  changeCorporateAccountStatus={changeStatus}
                  showStateChange={true}
                  // onDelete={deleteFeature}
                  onNext={onNext}
                  onPrev={onPrev}
                  onAudit={() => checkAudit(selectedCorporateAccount.id)}
                >
                  <SelectedItemContent
                    selectedCorporateAccount={selectedCorporateAccount}
                    getLogoPic={getLogoPic}
                  />
                </View>
              ) : (
                <div className="mx-auto py-5 text-center">
                  <button onClick={addFeature} className="btn btn-primary text-bold">{t('corporate.addCorporateAccount')}</button>
                </div>
              )
            ) : (
              <div className="mx-auto py-5 text-center">
                <p>{t('corporate.wait')}</p>
                <Loading loading={true} />
              </div>
            )
          ) : (
            selectedCorporateAccount ? (
              <CorporateBookingPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                entityId={selectedCorporateAccount.id}
                onAfterSave={() => refresh()}
              />
            ) : (
              <CorporateBookingPost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh()}
              />
            )
          )}
        </List>
      }
    </div>
  );

}
