import classNames from 'classnames';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CorporateAccountStatus } from '../../entities/corporate-account.entity';
import { CorporateUserStatus } from '../../entities/corporate-user.entity';
import { DriverStatus } from '../../entities/driver.entity';
import { OperatorStatus } from '../../entities/operator.entity';
import { PassengerStatus } from '../../entities/passenger.entity';
import { Role, RoleAssignment } from '../../entities/role.entity';
import { UserStatus } from '../../entities/user.entity';
import { AuthService } from '../../services/auth.service';

interface Props {
  changeDriverStatus?: DriverStatus;
  changeUserStatus?: UserStatus;
  changePassengerStatus?: PassengerStatus;
  changeOperatorStatus?: OperatorStatus;
  changeCorporateAccountStatus?: CorporateAccountStatus
  changeCorporateUserStatus?:CorporateUserStatus
  showEdit?: boolean;
  showImport?: boolean;
  showDelete?: boolean;
  showNext?: boolean;
  showPrev?: boolean;
  showAdd?: boolean;
  showAudit?: boolean;
  disabled?: boolean;
  wrapper?: boolean;
  showStateChange?: boolean;
  className?: any;
  childClassName?: any;
  componentName: string;
  onAdd?: () => void
  onEdit?: () => void
  onImport?: () => void
  onDelete?: () => void
  onStateChange?: () => void
  onNext?: () => void
  onPrev?: () => void
  onAudit?: () => void
  childViewStyles?: React.CSSProperties | undefined;
}

export function View({
  showEdit, showImport, showDelete, className, children, disabled, showStateChange, changeDriverStatus, changeUserStatus, changeOperatorStatus, changePassengerStatus, changeCorporateAccountStatus, componentName,
  childClassName, onDelete, onNext, onPrev, onImport, onEdit, showAdd, showAudit, onAdd, onAudit, onStateChange, childViewStyles
}: PropsWithChildren<Props>) {
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const [confirmingDriverBloked, setConfirmingDriverBloked] = useState<boolean>(false);
  const [confirmingDriverActive, setConfirmingDriverActive] = useState<boolean>(false);
  const [confirmingPassengerBloked, setConfirmingPassengerBloked] = useState<boolean>(false);
  const [confirmingPassengerActive, setConfirmingPassengerActive] = useState<boolean>(false);
  const [confirmingCorporateAccountBlocked, setConfirmingCorporateAccountBlocked] = useState<boolean>(false);
  const [confirmingCorporateAccountActive, setConfirmingCorporateAccountActive] = useState<boolean>(false);
  const [confirmingOperatorBloked, setConfirmingOperatorBloked] = useState<boolean>(false);
  const [confirmingOperatorActive, setConfirmingOperatorActive] = useState<boolean>(false);
  const [confirmingUserBlocked, setConfirmingUserBlocked] = useState<boolean>(false);
  const [confirmingUserActive, setConfirmingUserActive] = useState<boolean>(false);
  const [module, setModule] = useState<string>(componentName);
  const [add, setAdd] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);
  const [remove, setRemove] = useState<boolean>(false);
  const [audit, setAudit] = useState<boolean>(false);
  const [importButton, setImportButton] = useState<boolean>(false);
  const Payload: any | undefined = AuthService.getUser();
  const { user } = Payload;
  const assignments: RoleAssignment[] = user ? [...((user?.role as Role).assignments as RoleAssignment[])] : [];
  const moduleCodes = Array.from(assignments, assignment => ({
    module: assignment.module,
    action: assignment.action
  }));
  const { t } = useTranslation('main');

  useEffect(() => {
    console.log("component Name ")
    moduleCodes.filter((el) => {
      if ((el.module === componentName || el.module === 'settings') && (el.action === "create" || el.action === "create-currency" || el.action === "create-account")) {
        console.log("el.", el.module, el.action)
        setAdd(true)
        // showAdd = true;
      }
      if (componentName === 'role') {
        setAdd(true)
        setUpdate(true)
        // showAdd = true;
      }
      if (componentName === 'service') {
        setAdd(true)
        setUpdate(true)
        setAudit(true)
        // showAdd = true;
        // console.log("el module",el);
      }
      if ((el.module === componentName || el.module === 'settings') && (el.action === "update" || el.action === "update-currency" || el.action === "update-account")) {
        setUpdate(true)
        // showEdit = true;
        // console.log("el module",el);
      }
      // if(el.module===componentName && el.action === "delete"){
      //   setRemove(true)
      //   // showEdit = true;
      //   console.log("el module",el);
      // }
      if (el.module === componentName && el.action === "audit") {
        setAudit(true)
        // showEdit = true;
        // console.log("el module",el);
      }
      if (el.module === componentName && el.action === "import") {
        setImportButton(true)
        // showEdit = true;
        // console.log("el module",el);
      }
    });
  }, [componentName])

  return (
    <div className={classNames('ae-content-w', className)}>
      {(showEdit || showDelete || showAdd || showStateChange) && (
        <div className="aec-head">
          <div className="actions-right">
            <div className="row aeh-actions">
              {add && (
                <button onClick={onAdd} className="btns"><i className="os-icon os-icon-plus"></i> {t('form.controls.add')}</button>
                // <a href="#add" onClick={onAdd}><i className="os-icon os-icon-plus"></i></a>
              )}
              {audit && (
                <button onClick={onAudit} className="btns"><i className="os-icon os-icon-folder"></i> {t('form.controls.audit')}</button>
              )}
              {(update && showEdit) && onEdit && (
                <button onClick={onEdit} className="btns"><i className="os-icon os-icon-pencil-1"></i> {t('form.controls.edit')}</button>
              )}
              {importButton && onImport && (
                <button onClick={onImport} className="btns"><i className="os-icon os-icon-download"></i> {t('form.controls.import')}</button>
              )}
              {showStateChange && onStateChange && changeDriverStatus && (
                <button className="btns" onClick={() => {
                  if (changeDriverStatus === DriverStatus.Active) {
                    setConfirmingDriverBloked(!confirmingDriverBloked)
                  }
                  if (changeDriverStatus === DriverStatus.Blocked) {
                    setConfirmingDriverActive(!confirmingDriverActive);
                  }
                }}
                >
                  {changeDriverStatus === DriverStatus.Active ?
                    <div>
                      <i className="os-icon os-icon-user-check"></i> {t('form.controls.active')}
                    </div>
                    : changeDriverStatus === undefined ?
                      <i className=""></i>
                      : <div><i className="os-icon os-icon-user-x"></i> {t('form.controls.block')}</div>
                  }
                </button>
              )}
              {changeDriverStatus === DriverStatus.Active ?
                confirmingDriverBloked && (
                  <span className="delete-confirm">
                    <button
                      className="btn btn-danger"
                      onClick={() => { setConfirmingDriverBloked(false); onStateChange && onStateChange(); }}>
                      Confirm Active
                    </button>
                    <button
                      className="btn btn-link"
                      onClick={() => setConfirmingDriverBloked(false)}>
                      Cancel
                    </button>
                  </span>) :
                confirmingDriverActive && (
                  <span className="delete-confirm">
                    <button
                      className="btn btn-danger"
                      onClick={() => { setConfirmingDriverActive(false); onStateChange && onStateChange(); }}>
                      Confirm Blocked
                    </button>
                    <button
                      className="btn btn-link"
                      onClick={() => setConfirmingDriverActive(false)}>
                      Cancel
                    </button>
                  </span>)
              }
              {showStateChange && onStateChange && changePassengerStatus && (
                <button className='btns' onClick={() => {
                  if (changePassengerStatus === PassengerStatus.Active) {
                    setConfirmingPassengerBloked(!confirmingPassengerBloked)
                  }
                  if (changePassengerStatus === PassengerStatus.Blocked) {
                    setConfirmingPassengerActive(!confirmingPassengerActive);
                  }
                }}
                >
                  {changePassengerStatus === PassengerStatus.Active ?
                    <div>
                      <i className="os-icon os-icon-user-check"></i> Active
                    </div>
                    : changePassengerStatus === undefined ?
                      <i className=""></i>
                      : <div><i className="os-icon os-icon-user-x"></i> Block</div>
                  }
                </button>
              )}
              {showStateChange && onStateChange && changeCorporateAccountStatus && (
                <button className='btns' onClick={() => {
                  if (changeCorporateAccountStatus === CorporateAccountStatus.Active) {
                    setConfirmingCorporateAccountBlocked(!confirmingCorporateAccountBlocked)
                  }
                  if (changeCorporateAccountStatus === CorporateAccountStatus.Blocked) {
                    setConfirmingCorporateAccountActive(!confirmingCorporateAccountActive);
                  }
                }}
                >
                  {changeCorporateAccountStatus === CorporateAccountStatus.Active ?
                    <div>
                      <i className="os-icon os-icon-user-check"></i> Active
                    </div>
                    : changeCorporateAccountStatus === undefined ?
                      <i className=""></i>
                      : <div><i className="os-icon os-icon-user-x"></i> Block</div>
                  }
                </button>
              )}
              {changeCorporateAccountStatus === CorporateAccountStatus.Active ?
                confirmingCorporateAccountBlocked && (
                  <span className="delete-confirm">
                    <button
                      className="btn btn-danger"
                      onClick={() => { setConfirmingCorporateAccountBlocked(false); onStateChange && onStateChange(); }}>
                      Confirm Active
                    </button>
                    <button
                      className="btn btn-link"
                      onClick={() => setConfirmingCorporateAccountBlocked(false)}>
                      Cancel
                    </button>
                  </span>) : confirmingCorporateAccountActive && (
                    <span className="delete-confirm">
                      <button
                        className="btn btn-danger"
                        onClick={() => { setConfirmingCorporateAccountActive(false); onStateChange && onStateChange(); }}>
                        Confirm Blocked
                      </button>
                      <button
                        className="btn btn-link"
                        onClick={() => setConfirmingCorporateAccountActive(false)}>
                        Cancel
                      </button>
                    </span>)}
              {changePassengerStatus === PassengerStatus.Active ?
                confirmingPassengerBloked && (
                  <span className="delete-confirm">
                    <button
                      className="btn btn-danger"
                      onClick={() => { setConfirmingPassengerBloked(false); onStateChange && onStateChange(); }}>
                      Confirm Active
                    </button>
                    <button
                      className="btn btn-link"
                      onClick={() => setConfirmingPassengerBloked(false)}>
                      Cancel
                    </button>
                  </span>) :
                confirmingPassengerActive && (
                  <span className="delete-confirm">
                    <button
                      className="btn btn-danger"
                      onClick={() => { setConfirmingPassengerActive(false); onStateChange && onStateChange(); }}>
                      Confirm Blocked
                    </button>
                    <button
                      className="btn btn-link"
                      onClick={() => setConfirmingPassengerActive(false)}>
                      Cancel
                    </button>
                  </span>)
              }
              {showStateChange && onStateChange && changeUserStatus && (
                <button disabled={disabled} className='btns' onClick={() => {
                  if (changeUserStatus === UserStatus.Active) {
                    setConfirmingUserBlocked(!confirmingUserBlocked)
                  }
                  if (changeUserStatus === UserStatus.Blocked) {
                    setConfirmingUserActive(!confirmingUserActive);
                  }
                }}
                >
                  {changeUserStatus === UserStatus.Active ?
                    <div><i className="os-icon os-icon-user-check"></i> Active</div>
                    : changeUserStatus === undefined ?
                      <i className=""></i>
                      : <div><i className="os-icon os-icon-user-x"></i> Block</div>
                  }
                </button>
              )}
              {changeUserStatus === UserStatus.Active ?
                confirmingUserBlocked && (
                  <span className="delete-confirm">
                    <button
                      className="btn btn-danger"
                      onClick={() => { setConfirmingUserBlocked(false); onStateChange && onStateChange(); }}>
                      Confirm Active
                    </button>
                    <button
                      className="btn btn-link"
                      onClick={() => setConfirmingUserBlocked(false)}>
                      Cancel
                    </button>
                  </span>) :
                confirmingUserActive && (
                  <span className="delete-confirm">
                    <button
                      className="btn btn-danger"
                      onClick={() => { setConfirmingUserActive(false); onStateChange && onStateChange(); }}>
                      Confirm Blocked
                    </button>
                    <button
                      className="btn btn-link"
                      onClick={() => setConfirmingUserActive(false)}>
                      Cancel
                    </button>
                  </span>)
              }
              {showStateChange && onStateChange && changeOperatorStatus && (
                <button className='btns' onClick={() => {
                  if (changeOperatorStatus === OperatorStatus.Active) {
                    setConfirmingOperatorBloked(!confirmingOperatorBloked)
                  }
                  if (changeOperatorStatus === OperatorStatus.Blocked) {
                    setConfirmingOperatorActive(!confirmingOperatorActive);
                  }
                }}
                >
                  {changeOperatorStatus === OperatorStatus.Active ?
                    <div><i className="os-icon os-icon-user-check"></i> Active</div>
                    : changeOperatorStatus === undefined ?
                      <i className=""></i>
                      : <div><i className="os-icon os-icon-user-x"></i> Block</div>
                  }
                </button>
              )}
              {changeOperatorStatus === OperatorStatus.Active ?
                confirmingOperatorBloked && (
                  <span className="delete-confirm">
                    <button
                      className="btn btn-danger"
                      onClick={() => { setConfirmingOperatorBloked(false); onStateChange && onStateChange(); }}>
                      Confirm Active
                    </button>
                    <button
                      className="btn btn-link"
                      onClick={() => setConfirmingOperatorBloked(false)}>
                      Cancel
                    </button>
                  </span>) :
                confirmingOperatorActive && (
                  <span className="delete-confirm">
                    <button
                      className="btn btn-danger"
                      onClick={() => { setConfirmingOperatorActive(false); onStateChange && onStateChange(); }}>
                      Confirm Blocked
                    </button>
                    <button
                      className="btn btn-link"
                      onClick={() => setConfirmingOperatorActive(false)}>
                      Cancel
                    </button>
                  </span>)
              }
              {showDelete && (
                <button className="btns" disabled={disabled} onClick={() => setConfirmingDelete(!confirmingDelete)}>
                  <i className="os-icon os-icon-trash-2"></i> {t('form.controls.delete')}
                </button>
              )}
              {confirmingDelete && (
                <span className="delete-confirm">
                  <button
                    className="btn btn-danger"
                    onClick={() => { setConfirmingDelete(false); onDelete && onDelete(); }}>
                    Confirm Delete
                  </button>
                  <button
                    className="btn btn-link"
                    onClick={() => setConfirmingDelete(false)}>
                    Cancel
                  </button>
                </span>
              )}
              <button className="btns" onClick={onPrev}>
                <i className="os-icon os-icon-arrow-left2"></i> {t('form.controls.prev')}
              </button>
              <button className="btns" onClick={onNext}>
                <i className="os-icon os-icon-arrow-right4"></i> {t('form.controls.next')}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className={classNames('ae-content', childClassName)} style={childViewStyles}>
        {children}
      </div>
    </div>
  )
}