import React, { useEffect, useState } from "react";
import { Role, RoleAssignment } from "../../../entities/role.entity";
import { Api, Endpoint } from "../../../services/api.service";
import { AuthService } from "../../../services/auth.service";
import { DashboardOverviewStats } from "./stats.component";
import { DashboardOverviewTodo } from "./todo.component";

export function DashboardOverview() {

  // const {user} = Payload;
  const [showDashboard, setShowDashboard] = useState(false)
  const [userData, setUserData] = useState<any>()
  const checkStatus = async () => {
    const Payload: any | undefined = AuthService.getUser();
    if (Payload) {
      const user = await Api.get<any, any>(Endpoint.USER, { id: Payload.user ? Payload.user.id : Payload.id })
      console.log("user", user);
      setUserData(user);
      let status = false;
      const assignments: RoleAssignment[] = user ? [...((user?.role as Role).assignments as RoleAssignment[])] : [];
      const moduleCodes = Array.from(assignments, assignment => ({
        module: assignment.module,
        action: assignment.action
      }));

      await moduleCodes.filter((el) => {
        if ((el.module === "dashboard") && (el.action === "overview")) {
          status = true
        }
      })
      setShowDashboard(status)
    }
    else console.log("NO USER IN ASYNC STORAGE");
  }

  useEffect(() => {    
    checkStatus()
  }, []);

  return (
    <div className="row">
      {showDashboard ?
        <><DashboardOverviewStats />
          <DashboardOverviewTodo />
        </>
        :
        <div className="col-lg-12">
          {userData && <h1 style={{ textAlign: "center" }}>Welcome {`${(userData?.firstName)?.toUpperCase()} ${(userData?.lastName)?.toUpperCase()} !`}</h1>
          }</div>
      }
    </div>
  )
}
