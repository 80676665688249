import { Emergency, EmergencyStatus } from '../../entities/emergency.entity';
import { IceServers } from '../../entities/ice.servers.entity';
import { Api, ApiType, Endpoint } from '../../services/api.service';

let PendingRequests: Emergency[] = [];
let CurrentRequest: Emergency | null;
let STUN_SERVERS: IceServers | null = null;

function ActivateRequest(request: Emergency) {
    localStorage.setItem("@current", JSON.stringify(request));
    CurrentRequest = request;
}
function GetAllRequests(): Emergency[] {
    console.log("Pending requests for Emergency: ", PendingRequests.reverse());
    return PendingRequests.reverse();
}
function updatePendings(request: Emergency) {
    console.log("request.type === ", request.type);
    if (request.type === "DriverApp") {
        PendingRequests = PendingRequests.filter((item => {
            if (item.type === "DriverApp" && item.driver.id! === request.driver.id!) return false;
            return true;
        }));
    }
    else if (request.passenger) {
        PendingRequests = PendingRequests.filter((item) => {
            if (item.type === "PassengerApp" && item?.passenger?.id === request?.passenger?.id) return false;
            return true;
        })
    }
}
function CountPendingRequests(): number {
    return PendingRequests.length;
}
function activeRequest(): Emergency | null {
    const response = localStorage.getItem("@current")
    if (response) {
        CurrentRequest = JSON.parse(response);
        return CurrentRequest;
    }
    else return null;
}

function UpdateRequest(data: Emergency): Emergency | null {
    const response = localStorage.setItem("@current", JSON.stringify(data));
    return data;
}
function getTotalTimeInSeconds(timeStart: string) {
    const time = new Date().toUTCString();
    const date1 = new Date(time);
    const date2 = new Date(timeStart);
    //@ts-ignore 
    const diffMs = (date1 - date2);
    return Math.round(diffMs / 1000);
}
function handleRequest(request: Emergency) {
    PendingRequests.push(request);
}

async function updateStatus(status: EmergencyStatus, Request?: Emergency) {
    let requestToAlter: Emergency | null = null;
    if (Request) requestToAlter = Request;
    else if (CurrentRequest) {
        requestToAlter = CurrentRequest;
        CurrentRequest = null;
    }

    if (requestToAlter) {
        requestToAlter.status = status;
        console.log("requestToAlter.status = ", requestToAlter.status);
        console.log("actual requestToAlter.status = ", status);
        await Api.post(Endpoint.EMEGENCY_MODIFY, requestToAlter, ApiType.Socket);
    }
}
async function deleteEmerencyRequest(id: Emergency['id']) {
    return await Api.delete(Endpoint.Emergency, { id });
}
async function loadIceServers(): Promise<IceServers | undefined> {
    return getSTUNServers();
}
async function getSTUNServers(): Promise<IceServers | undefined> {
    const response = await Api.get<IceServers, {}>(Endpoint.ICE, {}, ApiType.Socket);
    if (typeof response === "boolean" || typeof response === "undefined") {
        return undefined;
    }
    else {
        return response;
    }
}
async function postEmergencyRequest(data: Emergency): Promise<boolean|Emergency> {
    console.log("before postEmergencyRequest = ", data);
    if (data.type === "DriverApp") {
        const vehicle = await Api.get<any, any>(Endpoint.GET_DRIVER_VEHICLE, { driverId: data.driver.id });
        console.log("after postEmergencyRequest = ", vehicle);
        data.vehicle = vehicle;
        const response = await Api.post<any, Emergency>(Endpoint.Emergency, data);
        console.log("response postEmergencyRequest = ", response);
        if (response.error) {
            return false;
        }
        else {
            return response;;
        }
    }
    else return false
}

const HandlerRequests = {
    handleRequest,
    ActivateRequest,
    CountPendingRequests,
    GetAllRequests,
    activeRequest,
    updateStatus,
    deleteEmerencyRequest,
    updatePendings,
    UpdateRequest,
    loadIceServers,
    postEmergencyRequest
}
export default HandlerRequests;