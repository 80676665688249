import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentWrapper, ViewContent } from '../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';
import { Driver } from '../../../entities/driver.entity';
import { Inspection, InspectionType } from "../../../entities/inspection.entity";
import { InspectionAssignment, InspectionAssignmentStatus } from '../../../entities/inspectionAssignment.entity';
import { Api, Endpoint } from '../../../services/api.service';
import { capitalize } from '../../../util/string.util';
import { ProfilePicSize } from './list.component';

interface DriverStatisticsResponse {
    tripCount: number
    revenue: number
    distance: number
    totalTime: number
    tripPercentage: number
    revenuePercentage: number
    distancePercentage: number
    prevTripCount: number
    prevRevenue: number
    prevDistance: number
    prevTotalTime: number
    timePercentage: number
}

interface Props {
    selectedDriver: Driver;
    getFullName: (driver: Driver) => string;
    getProfilePic: (driver: Driver, size: ProfilePicSize) => string;
    onApprove?: () => void,
}

interface Time {
    time: string;
    id: string | undefined
}

export function SelectedItemContent({
    selectedDriver, getFullName, getProfilePic, onApprove
}: Props) {

    const { t } = useTranslation('dashboard');

    const [driver, setDriver] = useState<Driver>();
    const [state, setState] = useState<DriverStatisticsResponse>(undefined!);
    const [time, setTime] = useState<string>('today');
    const [, setInspection] = useState<Inspection[]>();
    const [open, setOpen] = useState(false);
    const [log, setLog] = useState([]);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    const [callData, setCallData] = useState<any>([]);

    const [inspectionAssignment, setInspectionAssignment] = useState<InspectionAssignment[]>([]);




    useEffect(() => {
        init();
        refresh();
    }, [selectedDriver])

    const refreshStats = useCallback(async () => {
        console.log("CALL COtent", selectedDriver);
    }, [time, driver])

    useEffect(() => {
        refreshStats();
    }, [refreshStats])

    const refresh = useCallback(async () => {
        let items = await Api.get<any, any>(Endpoint.PASSENGER_CALL_HISTORY)
        items = JSON.parse(items);
        if (items) {
            const data = items.filter((items: any) => (items.type === InspectionType.Driver))
            setInspection(data);
            setInspectionAssignment([]);

            data.forEach((DriverInspectionData: any) => {
                let finalStatus: InspectionAssignmentStatus = InspectionAssignmentStatus.Pending;

                setInspectionAssignment((items) => {
                    selectedDriver?.driverCheckList?.forEach((InspectionData) => {
                        if (InspectionData.checklistItem === DriverInspectionData.title) {
                            finalStatus = InspectionAssignmentStatus.Done;
                        }
                    })
                    return ([...items, {
                        id: selectedDriver?.id,
                        inspection: DriverInspectionData,
                        entity: 'Driver',
                        status: finalStatus,
                    }])
                })
            })
        }

        console.log('Inspection Assignment :', inspectionAssignment);
        //   setInspectionAssignment()
    }, [selectedDriver]);

    const init = useCallback(async () => {
        setDriver(selectedDriver);
    }, [selectedDriver]);

    const [confirmingApproval, setConfirmingApproval] = useState(false);
    const [approved, setApproved] = useState(false);

    useEffect(() => {
        setApproved(false);
        setConfirmingApproval(false);
    }, [selectedDriver]);

    const stats = [
        {
            "title": "Rides",
            "value": "0",
            "percentage": "0",
            "trend": "neutral"
        },
        {
            "title": "Earnings",
            "value": "$0",
            "percentage": "0",
            "trend": "neutral"
        },
        {
            "title": "Earnings per Ride",
            "value": "$0",
            "percentage": "0",
            "trend": "neutral"
        },
        {
            "title": "Shift Time",
            "value": "0 Hrs",
            "percentage": "0",
            "trend": "neutral"
        },
    ]

    return (
        <ContentWrapper className="row">
            <ViewContent
                className="col-md-6"
                title={getFullName(selectedDriver)}
                subtitle={selectedDriver.code}
                imgSrc={getProfilePic(selectedDriver, ProfilePicSize.Single)}
            >
                <ViewContentItems>

                    <ViewContentItem title={t('history.from')}>
                        <a href={`tel:${driver?.from}`}>{driver?.from}</a>

                    </ViewContentItem>

                    <ViewContentItem title={t('history.to')}>
                        <a href={`tel:${driver?.to}`}>{driver?.to}</a>
                    </ViewContentItem>

                    <ViewContentItem title={t('history.status')}>
                        {capitalize(driver?.status)}
                    </ViewContentItem>

                    <ViewContentItem title={t('history.startTime')}>
                        {new Date(driver?.startTime).toLocaleString()}
                    </ViewContentItem>

                    <ViewContentItem title={t('history.endTime')}>
                        {new Date(driver?.endTime).toLocaleString()}
                    </ViewContentItem>

                    <ViewContentItem title={t('history.duration')}>
                        {driver?.duration} sec
                    </ViewContentItem>

                    <ViewContentItem title={t('history.passenger')}>
                        {driver?.passenger?.firstName}
                    </ViewContentItem>



                </ViewContentItems>
            </ViewContent>

        </ContentWrapper >
    );
}