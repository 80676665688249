import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../../../components/form/input/input.component";
import { PhoneInputs } from "../../../../../components/form/input/phoneNumber";
import { FormRadio } from "../../../../../components/form/input/radio.component";
import { FormSection } from "../../../../../components/form/section.component";
import { FormMode } from "../../../../../enums/core.enum";

interface Props {
  value: string;
  onChange: any;
  formMode:FormMode|undefined
}

export function UserPostPersonal({ value, onChange,formMode }: Props) {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();

  return (
    <FormSection
      title={t("user.personalSection.title")}
      subtitle={t("user.personalSection.subTitle")}
    >
      <div className="row">
        <FormInput
          label={t("common.firstName")}
          name="firstName"
          type="text"
          className="col-md-4"
          autoFocus
          tabIndex={1}
          validation={{ required: true }}
          {...formProps}
        />
        <FormInput
          label={t("common.lastName")}
          name="lastName"
          type="text"
          className="col-md-4"
          validation={{ required: true }}
          tabIndex={2}
          {...formProps}
        />
        <div className="col-md-4 col-sm-12">
          <FormRadio label={t("common.gender")} name="gender" validation={{ required: true }} {...formProps}
            options={[
              { label: t("common.genderMale"), value: 'male' },
              { label: t("common.genderFemale"), value: 'female' },
            ]}
            tabIndex={3}
          />
        </div>
      </div>
      <div className="row">
        <FormInput
          label={t("common.email")}
          name="email"
          type="email"
          className="col-sm-6"
          validation={{ required: true }}
          tabIndex={4}
          {...formProps}
        />
        <PhoneInputs
          placeholder={t("common.phone")}
          value={value}
          onChange={onChange}
          name={'phone'}
          className="col-md-4"
          {...formProps}
        /> 
      </div>
      <div className="row">
   {  formMode === 0?   <FormInput
          label={t("common.password")}
          name="password"
          type="password"
          className="col-sm-6"
          validation={{ required: true }}
          tabIndex={6}
          {...formProps}
        />:null}
         
      </div>
      {/* <div className="row">
        <FormInput
          label={t("common.status")}
          name="status"
          type="text"
          className="col-sm-4"
          validation={{ required: true }}
          tabIndex={7}
          {...formProps}
        />
      </div> */}
    </FormSection>
  );

}
