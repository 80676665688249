import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { StatsComponent } from '../../../components/stats/stats.component';
import { ContentWrapper, ViewContent } from '../../../components/view/content.component';
import { ViewContentFiles } from '../../../components/view/files.component';
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';
import { IFile } from '../../../entities/file.entity';
import { Inspection, InspectionType } from "../../../entities/inspection.entity";
import { InspectionAssignment, InspectionAssignmentStatus } from '../../../entities/inspectionAssignment.entity';
import { Vehicle } from '../../../entities/vehicle.entity';
import { VehicleCheckList } from '../../../entities/vehicleCheckList.entity copy';
import { IPaginationOptions, IPaginationResponse } from '../../../interfaces/paginate.interface';
import { getDatesForFilters, StatsFilterPeriod, StatsRequest, StatsResponse, StatsType } from '../../../interfaces/stats.interface';
import { Api, Endpoint } from '../../../services/api.service';
import { getCurrencyFormatting, getGeneralDistanceFormat, getGeneralTimeFormat } from '../../../util/distance';
import { getStorageUrl } from '../../../util/file.util';
import { Checklist } from './checklist.component';
import { ProfilePicSize } from './list.component';
import { RideDetails } from './ride-details.component';

interface Props {
  onRefresh?: () => void;
  selectedVehicle: Vehicle;
  getModel: (vehicle: Vehicle) => string;
  setSelectedVehicle?: (vehicle: undefined | Vehicle,) => void;
  getProfilePic: (vehicle: Vehicle, size: ProfilePicSize) => string;
}
export function SelectedItemContent({ selectedVehicle, getProfilePic, getModel, onRefresh, setSelectedVehicle}: Props) {
  const history = useHistory();
  const translation = useTranslation('forms');
  const [vehicle, setVehicle] = useState<Vehicle>();
  const [, setInspection] = useState<Inspection[]>();
  const [stats, setStats] = useState<StatsResponse>();
  const [activity, setActivity] = useState<StatsType>();
  const CUSTOM_DATES = useRef<{ start: Date, end: Date }>();
  const [time, setTime] = useState<StatsFilterPeriod>(StatsFilterPeriod.Today);
  const [inspectionAssignment, setInspectionAssignment] = useState<InspectionAssignment[]>([]);

  useEffect(() => {
    getStats(time);
  }, []);

  async function getStats(time: StatsFilterPeriod, customStart?: Date, customEnd?: Date) {
    setTime(time); if (time === StatsFilterPeriod.Custom && !customStart) return;
    setStats(undefined);
    let startDate: Date, endDate: Date;
    if (customStart && customEnd) {
      startDate = customStart; endDate = customEnd;
    }
    else ({ startDate, endDate } = getDatesForFilters(time));
    const response = await Api.get<StatsResponse, StatsRequest>(Endpoint.STATS, {
      timeline: time, vehicle: selectedVehicle.id!, startDate, endDate
    });
    setStats(response);
  }

  const ref = useCallback(() => {
    const refresh = async () => {
      const { items } = await Api.get<IPaginationResponse<Inspection>, IPaginationOptions>(Endpoint.INSPECTION_LIST, { page: 0, limit: 20, });
      if (items) {
        const data = items.filter(items => (items.type === InspectionType.Vehicle))
        setInspection(data);
        setInspectionAssignment([]);

        data.forEach((VehicleInspectionData) => {
          let finalStatus: InspectionAssignmentStatus = InspectionAssignmentStatus.Pending;
          setInspectionAssignment((items) => {
            selectedVehicle?.vehicleCheckList?.forEach((InspectionData) => {
              if (InspectionData.checklistItem === VehicleInspectionData.title) {
                finalStatus = InspectionAssignmentStatus.Done;
              }
            })
            return ([...items, {
              id: selectedVehicle?.id,
              inspection: VehicleInspectionData,
              entity: 'Vehicle',
              status: finalStatus,
            }])
          })
        })
      }

      console.log('Inspection Assignment :', inspectionAssignment);
    };
    refresh();
  }, [selectedVehicle, inspectionAssignment])

  const init = useCallback(async () => {
    setVehicle(selectedVehicle);
    const vehicle = await Api.get<Vehicle, { id: string }>(Endpoint.VEHICLE, { id: selectedVehicle.id as string });
    console.log('inside Vehicle:', vehicle, 'Selceted VEhicle:', selectedVehicle)
    setVehicle(vehicle);
    console.log(selectedVehicle);
  }, [selectedVehicle]);

  useEffect(() => {
    init();
    ref();
  }, [selectedVehicle, init])

  const {
    plate,
    vin,
    registrationExpiryDate,
    numLuggage,
    numPassengers,
    plateFile,
    profileFiles,
    registrationFiles,
    insuranceFiles
  } = selectedVehicle;

  const getFileSrc = (file: IFile): { thumb: string, full: string } => {
    return {
      thumb: `${getStorageUrl()}/vehicle/${vehicle?.id}/${file.type}/${file.id}/admin_single.${file.extension}`,
      full: `${getStorageUrl()}/vehicle/${vehicle?.id}/${file.type}/${file.id}/admin_large.${file.extension}`,
    }
  }

  const newVal = async (i: InspectionAssignment) => {
    if (i?.status === InspectionAssignmentStatus.Done) {
      if (i.inspection) {
        const vehicleCheckListData: VehicleCheckList = { vehicle: selectedVehicle?.id as string, checklistItem: i.inspection.title, checked: true }
        selectedVehicle.vehicleCheckList?.push(vehicleCheckListData);
        await Api.patch(Endpoint.VEHICLE, { id: selectedVehicle.id, vehicleCheckList: selectedVehicle.vehicleCheckList });
      }
    }
    else if (i.status === InspectionAssignmentStatus.Pending) {
      selectedVehicle.vehicleCheckList = selectedVehicle?.vehicleCheckList?.filter(item => item.checklistItem !== i.inspection?.title)
      await Api.patch(Endpoint.VEHICLE, { id: selectedVehicle.id, vehicleCheckList: selectedVehicle.vehicleCheckList });
    }
    ref();
  };

  const onclickRecover = async (selectedVehicle: Vehicle) => {
    console.log('Recover This Vehicle:', selectedVehicle);
    const response = await Api.post<Vehicle, { id: string }>(Endpoint.VEHICLE_RECOVER, { id: selectedVehicle.id as string });
    console.log('Recover This Vehicle Response:', response)
    if (onRefresh && setSelectedVehicle) {
      setSelectedVehicle(undefined);
      onRefresh();
    }
  }

  function updateActivity(this: { type: StatsType }) {
    setActivity(this.type);
  }

  function onCustomSelect(startDate: Date, endDate: Date) {
    CUSTOM_DATES.current = { start: startDate, end: endDate };
    getStats(StatsFilterPeriod.Custom, startDate, endDate);
  }


  return (
    <ContentWrapper className="row">
      <ViewContent
        componentName='vehicle'
        className="col-md-6"
        title={plate}
        imgSrc={getProfilePic(selectedVehicle, ProfilePicSize.Single)}>
        <ViewContentItems>
          {selectedVehicle.deletedTime ? <div className="alert alert-success borderless">
            <h5 className="alert-heading">Vehicle Deleted</h5>
            <p>Do you want to recover this vehicle data?</p>
            <div className="alert-btn">
              <a className="btn btn-white-gold" href={'#href'} onClick={() => onclickRecover(selectedVehicle)}>
                <i className="os-icon os-icon-ui-92"></i>
                <span>{'Recover Vehicle'}</span>
              </a>
            </div>
          </div> : null
          }
          <ViewContentItem title={translation.t('vehicle.registrationSection.vinDigits')}>{vin}</ViewContentItem>

          <ViewContentItem title={translation.t('vehicle.registrationSection.registrationExpiry')}>
            {registrationExpiryDate}
          </ViewContentItem>

          <ViewContentItem title={translation.t('vehicle.modelSection.model')}>
            {getModel(selectedVehicle)}
          </ViewContentItem>

          <ViewContentItem title={translation.t('request.basicSection.passenger')}>{numPassengers}</ViewContentItem>

          <ViewContentItem title={translation.t('vehicle.modelSection.numberOfLuggages')}>{numLuggage}</ViewContentItem>

          <ViewContentItem title={translation.t('vehicle.fleetSection.title')}>
            {vehicle?.fleets?.map((n) => n.title).join(", ")}
          </ViewContentItem>

          <ViewContentItem title={translation.t('vehicle.networkSection.title')}>
            {vehicle?.networks?.map((n) => n.title).join(", ")}
          </ViewContentItem>

          <ViewContentItem title={translation.t('vehicle.operatorSection.title')}>{vehicle?.operator?.title}</ViewContentItem>

          <ViewContentItem title={translation.t('vehicle.serviceSection.title')}>
            {vehicle?.services?.map((f) => f.title).join(", ")}
          </ViewContentItem>

          <ViewContentItem title={translation.t('vehicle.featureSection.title')}>
            {vehicle?.features?.map((f) => f.title).join(", ")}
          </ViewContentItem>
        </ViewContentItems>

        <ViewContentFiles
          key={vehicle?.id}
          groups={[
            {
              title: translation.t('vehicle.modelSection.vehiclePictureLabel'),
              files: vehicle?.profileFiles?.map(getFileSrc),
            },
            {
              title:translation.t('vehicle.registrationSection.title'),
              files: vehicle?.registrationFiles?.map(getFileSrc),
            },
            {
              title: translation.t('vehicle.modelSection.insurancePictureLabel'),
              files: vehicle?.insuranceFiles?.map(getFileSrc),
            },
            { title: translation.t('vehicle.registrationSection.platePictureLabel'), files: plateFile ? [getFileSrc(plateFile)] : [] },
          ]}
        />
      </ViewContent>
      
      <div className='col-md-6 p-1'>
        {!activity && (
          <>
            <StatsComponent
              currentRange={time} onRangeChange={(e) => getStats(e.target.value)} onCustomSelect={onCustomSelect} data={!stats ? [] : [
                {
                  isPositive: stats.tripCount >= stats.prevTripCount, label: translation.t('driver.stats.rides'), value: stats.tripCount,
                  percentage: stats.tripCountPercentage, onClick: updateActivity.bind({ type: "Rides" })
                },
                {
                  isPositive: stats.revenue >= stats.prevRevenue, label: translation.t('driver.stats.earnings'), value: getCurrencyFormatting(stats.revenue),
                  percentage: stats.revenuePercentage, onClick: updateActivity.bind({ type: "Earnings" })
                },
                {
                  isPositive: stats.distance >= stats.prevDistance, label: translation.t('driver.stats.distance'), value: getGeneralDistanceFormat(stats.distance),
                  percentage: stats.distancePercentage, onClick: updateActivity.bind({ type: "Distance" })
                },
                {
                  isPositive: stats.rideTime >= stats.prevRideTime, label: translation.t('driver.stats.shiftTime'), value: getGeneralTimeFormat(Math.round(stats.rideTime)),
                  percentage: stats.rideTimePercentage, onClick: updateActivity.bind({ type: "Shift Time" })
                }
              ]}
            />
            {inspectionAssignment.map((inspectionAssignment) => (
              <Checklist
                key={inspectionAssignment.inspection?.id}
                value={inspectionAssignment}
                onChange={newVal}
              />
            ))}
          </>
        )}
        {activity && (() => {
          const buffer = [];
          const nav = <div className='d-flex flex-row justify-space-around align-items-center p-1'> 
            <i onClick={() => setActivity(undefined)} className="os-icon os-icon-arrow-left2" style={{ 
              color: 'blue', fontSize: '16px', marginRight: '0.5rem', cursor: "pointer"
            }}></i>
            <p className='text-uppercase font-weight-bold mb-0 text-center' style={{ fontSize: '15px' }}>Showing {activity} Details for {time}</p>
          </div>
          buffer.push(nav);
          buffer.push(<RideDetails activity={activity} CUSTOM={CUSTOM_DATES.current} time={time} vehicle={selectedVehicle.id!} openLink={(link) => history.push(link)} />);
          return buffer;
        })()}
      </div>
    </ContentWrapper>
  );
}
