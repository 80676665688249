import { GoogleMap } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import { useRef } from "react"
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { FormInput } from '../../../components/form/input/input.component';
import { ContentWrapper, ViewContent } from '../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';
import { Api, Endpoint } from '../../../services/api.service';
import { getCurrencyFormatting, getGeneralDistanceFormat, getGeneralTimeFormat } from '../../../util/distance';
import { showToastError } from '../../../util/notification';
import { getCarSVG } from '../../dashboard/map/car.svg';
import { decode } from '@mapbox/polyline';
import pointA from '../../../img/Point_A.png';
import pointB from '../../../img/Point_B.png';

type Summary = { 
    rides: number, commission: number, tips: number, netBank: number, fee: number, tolls: number, extras: number, fares: number,
    levy: number, bookingCharge: number, distance: number, paths: { code: string, encodedPath: string, polyline: string }[]
};
type ShiftSummary = { 
    code: string, driver: string, vehicle: string, device: string, startTime: string, endTime: string, shiftTime: number, status: "ACTIVE" | "ENDED",
    sessionPath: string, vehicleColor: string
};
type TrackShiftResponse = { 
    summary: ShiftSummary, tripCC: Summary, tripCash: Summary, bookingCC: Summary, bookingCash: Summary,
    lastLocation?: { latitude: number, longitude: number, heading: number }
};


export function ShiftTrackComponent() {
    const CENTER = useRef<google.maps.LatLng>();
    const RECENTER_NEEDED = useRef<boolean>(false);
    const CAR_MARKER = useRef<google.maps.Marker>();
    const MAP = useRef<google.maps.Map>();
    const SHIFT_TRACK = useRef<google.maps.Polyline>();
    const TRACK_LINE = useRef<google.maps.Polyline>();
    const MARKER_A = useRef<google.maps.Marker>();
    const MARKER_B = useRef<google.maps.Marker>();

    const params: any = useParams();
    const formProps = useFormContext();
    const { t } = useTranslation('main');
    const [shiftCode, setShiftCode] = useState<string>();
    const [driverCode, setDriverCode] = useState<string>();
    const [toggleTrack, setToggleTrack] = useState<boolean>(false);
    const [trackResponse, setTrackResponse] = useState<TrackShiftResponse>();

    function createRecenterButton(remove: Function) {
        const div = document.createElement("div");
        div.setAttribute("style", "margin: 10px; height: 40px; width: 40px; justify-content: center; align-items: center; border-radius: 2px; box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;");

        const button = document.createElement("button");
        button.setAttribute("style", "width: 100%; height: 100%; border-radius: 2px; border: none; background-color: white");

        const image = document.createElement("img");
        image.setAttribute("src", "https://cdn.iconscout.com/icon/free/png-256/compass-2451562-2082565.png");
        image.setAttribute("style", "width: 100%; height: 100%; object-fit: contain");

        button.onclick = function () {
            if (CENTER.current) {
                MAP.current!.setCenter(CENTER.current);
                remove();
            }
        }
        button.append(image);
        div.append(button);
        return div;
    }

    function createTripButtons(code: string, type: string, polyline: string) {
        const div = document.createElement("div");
        div.setAttribute("style", "display: flex; flex-direction: column; margin: 10px; justify-content: center; align-items: center; cursor: pointer; border-radius: 2px; box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px; background-color: white; padding: 10px");

        const title = document.createElement("p");
        title.innerText = code;
        title.setAttribute("style", "font-size: 18px; font-weight: bold; margin-bottom: 0px;")

        const subtitle = document.createElement("p");
        subtitle.innerText = type;
        subtitle.setAttribute("style", "font-size: 14px; font-weight: bold; margin-bottom: 0px;");

        div.onclick = function () {
            if (TRACK_LINE.current) {
                MARKER_A.current?.setMap(null);
                MARKER_B.current?.setMap(null);
                TRACK_LINE.current.setMap(null);
                
                MARKER_A.current = undefined;
                MARKER_B.current = undefined;
                TRACK_LINE.current = undefined;
            }
            if (type === "ALL") { SHIFT_TRACK.current?.setOptions({ strokeWeight: 5, strokeColor: 'black' }); }
            else SHIFT_TRACK.current?.setOptions({ strokeWeight: 0 });

            const mapBounds = new google.maps.LatLngBounds();
            const decoded = decode(polyline);
            TRACK_LINE.current = new google.maps.Polyline({
                path: decoded.map(([lat, lng], index) => {
                    const point = new google.maps.LatLng(lat, lng);
                    if (type !== "ALL") {
                        if (index === 0) MARKER_A.current = new google.maps.Marker({ position: point, map: MAP.current!, icon: pointA });
                        if (index === decoded.length-1) MARKER_B.current = new google.maps.Marker({ position: point, map: MAP.current!, icon: pointB, });
                    }
                    mapBounds.extend(point);
                    return point;
                }), map: MAP.current!, strokeColor: 'blue', strokeWeight: 2
            });

            MAP.current?.fitBounds(mapBounds, { top: 10, bottom: 10, right: 10, left: 10 });
            CENTER.current = MAP.current?.getCenter();
        }
        div.append(title, subtitle);
        return div;
    }

    function onShiftCodeSubmit(e: any) {
        onCodeReceived(e.target.value);
    }

    function onDriverCodeSubmit(e: any) {

    }

    async function onCodeReceived(code: string) {
        console.log("Running code received!");
        try {
            if ((/[A-Z]{3}-\d{4}/g).test(code) === false) {
                throw new Error('Invalid Code Pattern, Your code can only use the format ABC-0000');
            }

            const response = await Api.get<TrackShiftResponse, any>(Endpoint.GPS_SHIFT, { sessionCode: code });
            setShiftCode(code); setTrackResponse(response);

            const mapBounds = new google.maps.LatLngBounds();
            const decodedPath = decode(response.summary.sessionPath || "");
            SHIFT_TRACK.current = new google.maps.Polyline({ 
                path: decodedPath.map(([lat, lng]) => {
                    const point = new google.maps.LatLng(lat, lng);
                    mapBounds.extend(point);
                    return point;
                }), map: MAP.current!, strokeColor: 'black', strokeWeight: 5
            });

            if (response.lastLocation) {
                mapBounds.extend(new google.maps.LatLng(response.lastLocation.latitude, response.lastLocation.longitude));
                const icon = {
                    url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(getCarSVG(response.summary.vehicleColor, response.lastLocation.heading)),
                    scaledSize: new google.maps.Size(50, 100),
                    anchor: new google.maps.Point(32, 50),
                }

                CAR_MARKER.current = new google.maps.Marker({
                    clickable: false, draggable: false, map: MAP.current!, icon, title: `${response.summary.driver}\nLast seen position`,
                    position: new google.maps.LatLng(response.lastLocation.latitude, response.lastLocation.longitude),
                });
            }

            MAP.current?.fitBounds(mapBounds, { top: 10, bottom: 10, right: 10, left: 10 });
            CENTER.current = MAP.current?.getCenter();

            MAP.current!.controls[google.maps.ControlPosition.LEFT_TOP].push(createTripButtons(response.summary.code, "ALL", response.summary.sessionPath));
            for (let path of response.bookingCC.paths) {
                MAP.current!.controls[google.maps.ControlPosition.LEFT_TOP].push(createTripButtons(path.code, "CC BOOKING", path.polyline));
            }

            for (let path of response.bookingCash.paths) {
                MAP.current!.controls[google.maps.ControlPosition.LEFT_TOP].push(createTripButtons(path.code, "CASH BOOKING", path.polyline));
            }

            for (let path of response.tripCC.paths) {
                MAP.current!.controls[google.maps.ControlPosition.LEFT_TOP].push(createTripButtons(path.code, "CC TRIP", path.polyline));
            }

            for (let path of response.tripCash.paths) {
                MAP.current!.controls[google.maps.ControlPosition.LEFT_TOP].push(createTripButtons(path.code, "CASH TRIP", path.polyline));
            }
        }
        catch(e: any) {
            console.log("error = ",e);
            showToastError(e.message || e);
        }
    }

    useEffect(() => {
        MAP.current = new google.maps.Map(document.getElementById('googleMaps@tracker')!);
        MAP.current.addListener("dragend", () => {
            if (RECENTER_NEEDED.current === false) {
                RECENTER_NEEDED.current = true;
                MAP.current!.controls[google.maps.ControlPosition.RIGHT_CENTER].push(createRecenterButton(() => {
                    MAP.current!.controls[google.maps.ControlPosition.RIGHT_CENTER].pop();
                    RECENTER_NEEDED.current = false;
                }));
            }
        });

        google.maps.event.addListenerOnce(MAP.current, "idle", () => {
            const code = params.code;
            if (code && code !== "") onCodeReceived(code)
            else showToastError('No code found to track, please enter valid code');
        });
    }, []);
    return (
        <ContentWrapper className="container-fluid">
            <div className="row" style={{justifyContent: 'space-between'}}>
                <FormInput
                    label={""}
                    name="Track"
                    type="text"
                    className="col-sm-3 mb-0 ml-3"
                    tabIndex={10}
                    value={shiftCode}
                    placeholder={t('mainMenu.track.enterShiftCode')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setShiftCode(e.target.value) }}
                    {...formProps}
                />
                <button
                    style={{ height: "100%", marginTop: "23px", padding: "8px" }}
                    className="btn btn-primary text-bold col-sm-1"
                    onClick={onShiftCodeSubmit}>
                    {t('mainMenu.track.track')}
                </button>
                <FormInput
                    label={""}
                    name="code"
                    type="text"
                    className="col-sm-3 ml-3"
                    tabIndex={12}
                    value={driverCode}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setDriverCode(e.target.value) }}
                    placeholder={t('mainMenu.track.enterDriverCode')}
                    {...formProps}
                />
                <button
                    style={{ height: "100%", marginTop: "23px", padding: "8px" }}
                    className="btn btn-primary text-bold col-sm-1"
                    onClick={onDriverCodeSubmit}>
                    {t('mainMenu.track.track')}
                </button>
                <div style={{ display: "flex", alignItems: "flex-end", flexDirection: "row", justifyContent: "flex-end" }} className="form-group col-sm-3 ml-3">
                    <label style={{ marginBottom: "-10px", marginRight: "25px" }}>Map Track
                        <input
                            type="checkbox"
                            className="form-check-input"
                            style={{ marginLeft: "10px" }}
                            checked={toggleTrack}
                            onChange={() => setToggleTrack(!toggleTrack)}
                        />
                    </label>
                </div>
            </div>
            <div className="row">
                <div className="col-md-2">
                    {trackResponse && (
                        <React.Fragment>
                            <ViewContent title="Summary">
                                <ViewContentItems>
                                    <ViewContentItem title="Code">{trackResponse.summary.code}</ViewContentItem>
                                    <ViewContentItem title="Status">{trackResponse.summary.status === "ENDED"
                                        ? <span className="badge badge-danger">Ended</span>
                                        : <span className="badge badge-success">Active</span>}
                                    </ViewContentItem>
                                    <ViewContentItem title="Driver">{trackResponse.summary.driver}</ViewContentItem>
                                    <ViewContentItem title="Vehicle">{trackResponse.summary.vehicle}</ViewContentItem>
                                    <ViewContentItem title="Device">{trackResponse.summary.device}</ViewContentItem>
                                    <ViewContentItem title="Start Time">{new Date(trackResponse.summary.startTime).toLocaleString()}</ViewContentItem>
                                    <ViewContentItem title="End Time">{trackResponse.summary.endTime ? new Date(trackResponse.summary.endTime).toLocaleString(): ""}</ViewContentItem>
                                    <ViewContentItem title="Shift time">{getGeneralTimeFormat(trackResponse.summary.shiftTime || 0)}</ViewContentItem>
                                </ViewContentItems>
                            </ViewContent>
                        </React.Fragment>
                    )}
                </div>
                <div className="col-md-10">
                    <GoogleMap
                        id="googleMaps@tracker"
                        mapContainerStyle={{
                            marginLeft: 20,
                            height: 645,
                            width: "98%",
                            padding: 8,
                            marginBottom: 10,
                        }}>
                    </GoogleMap>
                </div>
            </div>
            
            {trackResponse && (
                <div className="row" style={{ marginTop: "20px" }}>
                    <div className='col-md-3'>
                        <ViewContent title='Cash Trips'>
                            <ViewContentItems >
                                <ViewContentItem title='Rides'>{trackResponse.tripCash.rides}</ViewContentItem>
                                <ViewContentItem title='Distance'>{getGeneralDistanceFormat(trackResponse.tripCash.distance)}</ViewContentItem>
                                <ViewContentItem title='Fares'>{getCurrencyFormatting(trackResponse.tripCash.fares)}</ViewContentItem>
                                <ViewContentItem title='Levy'>{getCurrencyFormatting(trackResponse.tripCash.levy)}</ViewContentItem>
                                <ViewContentItem title='Tips'>{getCurrencyFormatting(trackResponse.tripCash.tips)}</ViewContentItem>
                                <ViewContentItem title='Tolls'>{getCurrencyFormatting(trackResponse.tripCash.tolls)}</ViewContentItem>
                                <ViewContentItem title='Extras'>{getCurrencyFormatting(trackResponse.tripCash.extras)}</ViewContentItem>
                                <ViewContentItem title='Booking Charge'>{getCurrencyFormatting(trackResponse.tripCash.bookingCharge)}</ViewContentItem>
                                <ViewContentItem title='Fee Deduction'>{getCurrencyFormatting(trackResponse.tripCash.fee)}</ViewContentItem>
                                <ViewContentItem title='Commission'>{getCurrencyFormatting(trackResponse.tripCash.commission)}</ViewContentItem>
                                <ViewContentItem title='Net Bank'>{getCurrencyFormatting(trackResponse.tripCash.netBank)}</ViewContentItem>
                            </ViewContentItems>
                        </ViewContent>
                    </div>
                    <div className="col-md-3">
                    <ViewContent title='CC Trips'>
                            <ViewContentItems >
                                <ViewContentItem title='Rides'>{trackResponse.tripCC.rides}</ViewContentItem>
                                <ViewContentItem title='Distance'>{getGeneralDistanceFormat(trackResponse.tripCC.distance)}</ViewContentItem>
                                <ViewContentItem title='Fares'>{getCurrencyFormatting(trackResponse.tripCC.fares)}</ViewContentItem>
                                <ViewContentItem title='Levy'>{getCurrencyFormatting(trackResponse.tripCC.levy)}</ViewContentItem>
                                <ViewContentItem title='Tips'>{getCurrencyFormatting(trackResponse.tripCC.tips)}</ViewContentItem>
                                <ViewContentItem title='Tolls'>{getCurrencyFormatting(trackResponse.tripCC.tolls)}</ViewContentItem>
                                <ViewContentItem title='Extras'>{getCurrencyFormatting(trackResponse.tripCC.extras)}</ViewContentItem>
                                <ViewContentItem title='Booking Charge'>{getCurrencyFormatting(trackResponse.tripCC.bookingCharge)}</ViewContentItem>
                                <ViewContentItem title='Fee Deduction'>{getCurrencyFormatting(trackResponse.tripCC.fee)}</ViewContentItem>
                                <ViewContentItem title='Commission'>{getCurrencyFormatting(trackResponse.tripCC.commission)}</ViewContentItem>
                                <ViewContentItem title='Net Bank'>{getCurrencyFormatting(trackResponse.tripCC.netBank)}</ViewContentItem>
                            </ViewContentItems>
                        </ViewContent>
                    </div>
                    <div className="col-md-3">
                    <ViewContent title='Cash Bookings'>
                            <ViewContentItems >
                                <ViewContentItem title='Rides'>{trackResponse.bookingCash.rides}</ViewContentItem>
                                <ViewContentItem title='Distance'>{getGeneralDistanceFormat(trackResponse.bookingCash.distance)}</ViewContentItem>
                                <ViewContentItem title='Fares'>{getCurrencyFormatting(trackResponse.bookingCash.fares)}</ViewContentItem>
                                <ViewContentItem title='Levy'>{getCurrencyFormatting(trackResponse.bookingCash.levy)}</ViewContentItem>
                                <ViewContentItem title='Tips'>{getCurrencyFormatting(trackResponse.bookingCash.tips)}</ViewContentItem>
                                <ViewContentItem title='Tolls'>{getCurrencyFormatting(trackResponse.bookingCash.tolls)}</ViewContentItem>
                                <ViewContentItem title='Extras'>{getCurrencyFormatting(trackResponse.bookingCash.extras)}</ViewContentItem>
                                <ViewContentItem title='Booking Charge'>{getCurrencyFormatting(trackResponse.bookingCash.bookingCharge)}</ViewContentItem>
                                <ViewContentItem title='Fee Deduction'>{getCurrencyFormatting(trackResponse.bookingCash.fee)}</ViewContentItem>
                                <ViewContentItem title='Commission'>{getCurrencyFormatting(trackResponse.bookingCash.commission)}</ViewContentItem>
                                <ViewContentItem title='Net Bank'>{getCurrencyFormatting(trackResponse.bookingCash.netBank)}</ViewContentItem>
                            </ViewContentItems>
                        </ViewContent>
                    </div>
                    <div className='col-md-3'>
                    <ViewContent title='CC Bookings'>
                            <ViewContentItems >
                                <ViewContentItem title='Rides'>{trackResponse.bookingCC.rides}</ViewContentItem>
                                <ViewContentItem title='Distance'>{getGeneralDistanceFormat(trackResponse.bookingCC.distance)}</ViewContentItem>
                                <ViewContentItem title='Fares'>{getCurrencyFormatting(trackResponse.bookingCC.fares)}</ViewContentItem>
                                <ViewContentItem title='Levy'>{getCurrencyFormatting(trackResponse.bookingCC.levy)}</ViewContentItem>
                                <ViewContentItem title='Tips'>{getCurrencyFormatting(trackResponse.bookingCC.tips)}</ViewContentItem>
                                <ViewContentItem title='Tolls'>{getCurrencyFormatting(trackResponse.bookingCC.tolls)}</ViewContentItem>
                                <ViewContentItem title='Extras'>{getCurrencyFormatting(trackResponse.bookingCC.extras)}</ViewContentItem>
                                <ViewContentItem title='Booking Charge'>{getCurrencyFormatting(trackResponse.bookingCC.bookingCharge)}</ViewContentItem>
                                <ViewContentItem title='Fee Deduction'>{getCurrencyFormatting(trackResponse.bookingCC.fee)}</ViewContentItem>
                                <ViewContentItem title='Commission'>{getCurrencyFormatting(trackResponse.bookingCC.commission)}</ViewContentItem>
                                <ViewContentItem title='Net Bank'>{getCurrencyFormatting(trackResponse.bookingCC.netBank)}</ViewContentItem>
                            </ViewContentItems>
                        </ViewContent>
                    </div>
                </div>
            )}

            <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-md-6">
                    {/* {session ? <ShiftPaymentDetails toSubUnit={toSubUnit} sessionPaymentData={{ sessionPaymentCCData, sessionPaymentCashData }} session={session as Session} timezone={generalTimezone as string} /> : ""} */}
                </div>
                <div className="col-md-6">
                    {/* {console.log("trips", trips)}
                    {trips && trips.length > 0 ? <ShiftTrips trips={trips} timezone={generalTimezone as string} /> : ""} */}
                </div>
            </div>
            <ToastContainer theme="dark" />
        </ContentWrapper>
    )
}