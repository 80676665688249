import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { StatsComponent } from '../../../components/stats/stats.component';
import { ContentWrapper, ViewContent } from '../../../components/view/content.component';
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';
import { Fleet } from '../../../entities/fleet.entity';
import { getDatesForFilters, StatsFilterPeriod, StatsRequest, StatsResponse } from '../../../interfaces/stats.interface';
import { Api, Endpoint } from '../../../services/api.service';
import { getCurrencyFormatting, getGeneralDistanceFormat, getGeneralTimeFormat } from '../../../util/distance';

type FleetStats = "Rides" | "Shift Time" | "Distance" | "Earnings" | "Activity";
interface Props {
  selectedFleet: Fleet;
}
export function SelectedItemContent({ selectedFleet }: Props) {
  const { t: translation } = useTranslation(['forms', 'dashboard']);
  const [stats, setStats] = useState<StatsResponse>();
  const [time, setTime] = useState<StatsFilterPeriod>(StatsFilterPeriod.Today);

  useEffect(() => {
    getStats(time);
  }, [])

  async function getStats(time: StatsFilterPeriod, customStart?: Date, customEnd?: Date) {
    setTime(time); if (time === StatsFilterPeriod.Custom && !customStart) return;
    setStats(undefined);
    let startDate: Date, endDate: Date;
    if (customStart && customEnd) {
      startDate = customStart; endDate = customEnd;
    }
    else ({ startDate, endDate } = getDatesForFilters(time));
    const response = await Api.get<StatsResponse, StatsRequest>(Endpoint.STATS, {
      fleet: selectedFleet.id!, timeline: time, startDate, endDate
    });
    setStats(response);
  }

  function onCustomSelect(startDate: Date, endDate: Date) {
    getStats(StatsFilterPeriod.Custom, startDate, endDate);
  }

  return(
    <ContentWrapper className="row">
      <ViewContent
        className="col-md-6"
        title={ selectedFleet.plate }
        childClassName={"p-0"}
      >
        <ViewContentItems className={"col-md-6"}>
          <ViewContentItem title={ translation("fleet.basicSection.title") }>
            { selectedFleet.title }
          </ViewContentItem>

          <ViewContentItem title={ translation("fleet.basicSection.color") }>
            <div style={{ width: 200, height: 30, backgroundColor: selectedFleet.color }}></div>
          </ViewContentItem>
          <ViewContentItem title={translation("fleet.basicSection.contactPerson")}>
            { selectedFleet.contactPerson }
          </ViewContentItem>
          <ViewContentItem title={translation("fleet.basicSection.contactPhone")}>
            { selectedFleet.contactPhone }
          </ViewContentItem>
          <ViewContentItem title={translation("fleet.basicSection.contactEmail") }>
            { selectedFleet.contactEmail }
          </ViewContentItem>
          <ViewContentItem title={translation("fleet.basicSection.address") }>
            { selectedFleet.address?.text }
          </ViewContentItem>
          <ViewContentItem title={translation("fleet.basicSection.corporateACN")}>
            { selectedFleet.businessRegistration }
          </ViewContentItem>
          <ViewContentItem title={translation("fleet.basicSection.manager")}>
            { selectedFleet?.manager?.email }
          </ViewContentItem>
        </ViewContentItems>
      </ViewContent>
      <div className="col-md-6 p-1">
        <StatsComponent 
          currentRange={time} onRangeChange={(e) => getStats(e.target.value)} onCustomSelect={onCustomSelect}
          data={!stats ? []: [
            {
              isPositive: stats.tripCount >= stats.prevTripCount, label: translation('driver.stats.rides'), value: stats.tripCount,
              percentage: stats.tripCountPercentage
            },
            {
              isPositive: stats.revenue >= stats.prevRevenue, label: translation('driver.stats.earnings'), value: getCurrencyFormatting(stats.revenue),
              percentage: stats.revenuePercentage
            },
            {
              isPositive: stats.distance >= stats.prevDistance, label: translation('driver.stats.distance'), value: getGeneralDistanceFormat(stats.distance),
              percentage: stats.distancePercentage
            },
            {
              isPositive: stats.rideTime >= stats.prevRideTime, label: translation('driver.stats.shiftTime'), value: getGeneralTimeFormat(Math.round(stats.rideTime)),
              percentage: stats.rideTimePercentage
            }
          ]}
        />
      </div>
    </ContentWrapper>
  );
}