import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useWindowScroll } from "react-use";
import { Loading } from "../../../components/alerts/loading.component";
import {
  GroupFilter,
  ListFilter
} from "../../../components/list/filter.component";
import {
  ListItem,
  ListItems
} from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { View } from "../../../components/view/wrapper.component";
import { Zone } from "../../../entities/zone.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormStyle } from "../../../enums/form.enum";
import {
  IPaginationOptions,
  IPaginationResponse
} from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../services/api.service";
import { ZonePost } from "../post.component";
import { SelectedItemContent } from "./content.component";

const groupFilters: GroupFilter[] = [
  { title: "All", icon: "phone-21" },
  { title: "deleted", icon: "trash" },

];

interface Id {
  id: String | undefined
}

export const CurrentZone = React.createContext<Zone | undefined>(undefined);

export function ZoneList(props: any) {
  const { t } = useTranslation("list");
  const { y: pageYOffset } = useWindowScroll();

  const [search, setSearch] = useState<string>('');
  const [limit, setLimit] = useState<number>(20);
  const [allCount, setAllCount] = useState<number>(0);
  const [count, setCount] = useState<number>(1);
  const [zone, setZone] = useState<Zone>()
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [zones, setZones] = useState<Zone[] | undefined>(undefined);
  const [selectedZone, setSelectedZone] = useState<Zone>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(
    groupFilters[0]
  );
  const [size, setSize] = useState<number>()
  const [deletedCount, setDeletedCount] = useState<number>(0);


  useEffect(() => {
    if(props.location.state){
    setSelectedZone(props.location.state)
    }
  }, [props.location.state])

  const refresh = useCallback(async () => {
    let group: string | undefined = activeGroupFilter?.title;
    group = group === 'all' ? undefined : group;
    let isDeleted = false;
    if (group === "deleted") {
      group = undefined;
      isDeleted = true;
    }
    const { items, totalItems } = await Api.get<
      IPaginationResponse<Zone>,
      IPaginationOptions
    >(Endpoint.ZONE_LIST, { page: 0, limit, group, search, isDeleted });

    if (items && items.length !== totalItems) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (items) {
      if (props.location.state !== null && !selectedZone) {
        setSelectedZone(props.location.state);
      }
      setZones(items);

    }

    const response = await Api.get<any, any>(Endpoint.ZONE_DELETED_COUNT);
    setDeletedCount(response);
    setAllCount(totalItems)

    if (items.length > 0 && !selectedZone) {
      setSelectedZone(items[0]);
    }
  }, [search, limit, activeGroupFilter, selectedZone]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const fetchMoreData = () => {
    setLimit(limit + 10);
  };

  const deleteZone = async () => {
    setConfirmingDelete(false);
    await Api.delete(Endpoint.ZONE, { id: selectedZone?.id });
    setSelectedZone(undefined);
    refresh();
  };

  const toListItems = (zone?: Zone): ListItem<Zone> | undefined => {
    if (!zone) {
      return;
    }
    console.log('Selected Zone:', zone);
    return {
      id: zone.id as string,
      title: zone.title,
      ref: zone,
      deleted: zone.deletedTime,

    };
  };

  const addZone = () => {
    setFormMode(FormMode.Adding);
  };

  const editZone = () => {
    setFormMode(FormMode.Editing);
  };

  const cancelEditing = () => {
    setFormMode(undefined);
  };

  const onNext = () => {
    if (zones) {
      const currentZoneIndex = zones.findIndex(
        (zone) => !!selectedZone && zone.id === selectedZone.id
      );
      if (currentZoneIndex + 1 < zones.length && currentZoneIndex !== -1) {
        setSelectedZone(zones[currentZoneIndex + 1]);
      }
    }
  };

  const onPrev = () => {
    if (zones) {
      const currentZoneIndex = zones.findIndex(
        (zone) => !!selectedZone && zone.id === selectedZone.id
      );
      if (currentZoneIndex - 1 > -1 && currentZoneIndex !== -1) {
        setSelectedZone(zones[currentZoneIndex - 1]);
      }
    }
  };

  const selectGroup = (target: GroupFilter) => {
    setSelectedZone(undefined);
    setActiveGroupFilter(target);
  };

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  let history = useHistory();
  function checkAudit(id: string | undefined): void | undefined {
    history.push(`/audit/${id}`);
  }

  const currentZone = useCallback(async () => {
    if(selectedZone){
    const item = await Api.get<Zone, Id>(Endpoint.ZONE, { id: selectedZone?.id })
    setZone(item)
    refresh()
    }
  }, [selectedZone])

  useEffect(() => {
    currentZone()
  }, [currentZone])

  const updateSize = () => {
    setSize(window.screen.width)
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [])

  return (
    <div>
      {size && size < 1000 ?
        <List>
          <div className="row">
            <div className="col-xl-12">
              <ListFilter
                groupFilters={[
                  { title: 'All', icon: 'phone-21', count: allCount },
                  { title: "deleted", icon: "trash", count: deletedCount },
                ]}
                activeGroupFilter={activeGroupFilter}
                onGroupSelect={selectGroup}
              />
            </div>
            <div className="col-xl-12">
              <ListItems
                items={zones?.map(toListItems) as ListItem<Zone>[]}
                activeItem={toListItems(selectedZone)}
                onSearchChange={onSearchChange}
                showSearch={true}
                showRefresh={true}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                onRefresh={() => {
                  setZones(undefined);
                  refresh();
                }}
                onClick={(item: ListItem<Zone>) => {
                  setSelectedZone(item.ref);
                }}
              />
            </div>
            <div className="col-xl-12" style={{ marginTop: "40px" }}>
              {formMode === undefined ? (
                zones !== undefined ? (
                  selectedZone ? (
                    <View
                      componentName={"zone"}
                      showAdd={true}
                      showEdit={true}
                      showDelete={selectedZone.deletedTime ? false : true}
                      showAudit={true}
                      showNext={true}
                      showPrev={true}
                      onAdd={addZone}
                      onEdit={editZone}
                      onDelete={deleteZone}
                      onNext={onNext}
                      onPrev={onPrev}
                      onAudit={() => checkAudit(selectedZone.id)}
                    >
                      <CurrentZone.Provider value={zone} >
                        <SelectedItemContent selectedZone={selectedZone}
                          onRefresh={refresh}
                          setSelectedZone={setSelectedZone} />
                      </CurrentZone.Provider>
                    </View>
                  ) : (
                    <div className="mx-auto py-5 text-center">
                      <button onClick={addZone} className="btn btn-primary text-bold">
                        {t("zone.addZone")}
                      </button>
                    </div>
                  )
                ) : (
                  <div className="mx-auto py-5 text-center">
                    <p>{t("zone.wait")}</p>
                    <Loading loading={true} />
                  </div>
                )
              ) : selectedZone ? (
                <ZonePost
                  style={FormStyle.Containerized}
                  formMode={formMode}
                  cancel={cancelEditing}
                  entityId={selectedZone.id}
                  selectedZone={selectedZone}
                  onAfterSave={() => refresh()}
                />
              ) : (
                <ZonePost
                  style={FormStyle.Containerized}
                  formMode={formMode}
                  cancel={cancelEditing}
                  onAfterSave={() => refresh()}
                />
              )}
            </div>
          </div>
        </List> :
        <List>
          <ListFilter
            groupFilters={[
              { title: "All", icon: "phone-21", count: allCount },
              { title: "deleted", icon: "trash", count: deletedCount },
            ]}
            activeGroupFilter={activeGroupFilter}
            onGroupSelect={selectGroup}
          />

          <ListItems
            items={zones?.map(toListItems) as ListItem<Zone>[]}
            activeItem={toListItems(selectedZone)}
            onSearchChange={onSearchChange}
            showSearch={true}
            showRefresh={true}
            hasMore={hasMore}
            fetchMoreData={fetchMoreData}
            onRefresh={() => {
              setZones(undefined);
              refresh();
            }}
            onClick={(item: ListItem<Zone>) => {
              setSelectedZone(item.ref);
            }}
          />

          {formMode === undefined ? (
            zones !== undefined ? (
              selectedZone ? (
                <View
                  key={selectedZone.id}
                  componentName={"zone"}
                  showAdd={true}
                  showEdit={true}
                  showDelete={selectedZone.deletedTime ? false : true}
                  showAudit={true}
                  showNext={true}
                  showPrev={true}
                  onAdd={addZone}
                  onEdit={editZone}
                  onDelete={deleteZone}
                  onNext={onNext}
                  onPrev={onPrev}
                  onAudit={() => checkAudit(selectedZone.id)}
                >
                  <CurrentZone.Provider value={selectedZone} >
                    <SelectedItemContent selectedZone={selectedZone}
                      onRefresh={refresh}
                      setSelectedZone={setSelectedZone} />
                  </CurrentZone.Provider>
                </View>
              ) : (
                <div className="mx-auto py-5 text-center">
                  <button onClick={addZone} className="btn btn-primary text-bold">
                    {t("zone.addZone")}
                  </button>
                </div>
              )
            ) : (
              <div className="mx-auto py-5 text-center">
                <p>{t("zone.wait")}</p>
                <Loading loading={true} />
              </div>
            )
          ) : selectedZone ? (
            <ZonePost
              key={selectedZone.id}
              style={FormStyle.Containerized}
              formMode={formMode}
              cancel={cancelEditing}
              entityId={selectedZone.id}
              selectedZone={selectedZone}
              onAfterSave={() => refresh()}
            />
          ) : (
            <ZonePost
              style={FormStyle.Containerized}
              formMode={formMode}
              cancel={cancelEditing}
              onAfterSave={() => refresh()}
            />
          )}
        </List>
      }
    </div>
  );
}
