import { differenceInDays,startOfMonth, endOfMonth, endOfWeek, endOfYear, startOfDay, startOfWeek, startOfYear, subDays, subMonths, subWeeks, subYears } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { useFormContext } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { FormInput } from "../../../components/form/input/input.component";
import { Currency } from "../../../entities/currency.entity";
import { Settings } from "../../../entities/settings.entity";
import { Api, Endpoint } from "../../../services/api.service";
import { SettingsService } from "../../../services/settings.service";

const doughnutOptions = {
  legend: {
    display: true,
  },
  
}

const chartOptions = {
  fill: true,
  borderJoinStyle: "miter",
  scales: {
   
    xAxes: [{
      gridLines: {
        display: true,
      },
      ticks: {
        display: true,
      },
      
    }],
    yAxes: [{
      gridLines: {
        display: true,
      },
      ticks: {
        display: true,
      }
    }]
  },
  legend: {
    display: false,
  },
}

interface RevenueStatisticsResponse {
  revenueChartData: any[];
  serviceBreakdownChartData: any[];
  typeBreakdownChartData: any[];
  zoneBreakdownChartData: any[];
}

interface Time {
  time: string
  from?: string|Date
  to?: string
}

export function DashboardRevenue() {
  const [time, setTime] = useState<string>('today');
  const [from, setFrom] = useState<string>('');
  const [to, setTo] = useState<string>('');
  const [subUnit, setSubUnit] = useState<number>(1)
  const [state, setState] = useState<RevenueStatisticsResponse>(undefined!);
  const [revenueChartData, setRevenueChartData] = useState({});
  const [serviceBreakdownChartData, setServiceBreakdownChartData] = useState({});
  const [typeBreakdownChartData, setTypeBreakdownChartData] = useState({});
  const [zoneBreakdownChartData, setZoneBreakdownChartData] = useState({});
  const [tooltipsDate] = useState<Date>(new Date())
  const [symbol, setSymbol] = useState<string | null>('')
  const [dataAvail,setDataAvail] = useState<boolean>(true)


  const formProps = useFormContext();
  const { t } = useTranslation('dashboard');

  const refresh = useCallback(async () => { 
    try{
    if (time === 'custome' && from && to) {
      const datetime=new Date();
      if(new Date(from).getFullYear() >= 2000 &&  new Date(from).getFullYear() <= datetime.getFullYear() && new Date(to).getFullYear() <= datetime.getFullYear()){
      const response = await Api.get<RevenueStatisticsResponse, Time>(Endpoint.DASHBOARD_REVENUESTATISTICS, { time: time, from: from, to: to })
      setState(response)
    }
    } else if (time === 'today' || time === 'yesterday' || time === 'this-week' || time === 'last-week' || time === 'this-month' || time === 'last-month' || time === 'this-year') {
      const response = await Api.get<RevenueStatisticsResponse, Time>(Endpoint.DASHBOARD_REVENUESTATISTICS, { time: time,from: new Date() })
      console.log("response",response)
      

      console.log("response1",response.revenueChartData)
      setState(response)
      
    }
    }
    catch(error){
      setDataAvail(false)

    }
  }, [time, from, to])

  useEffect(() => {
    refresh();
  }, [refresh])

  useEffect(()=>{
    const settings: Settings | undefined = SettingsService.getSetting(); 
    const currency = settings?.generalCurrency ? (settings?.generalCurrency as Currency).symbol : null;
    const unit = settings?.generalCurrency ? (settings?.generalCurrency as Currency).subunits : 100; 
    setSubUnit(unit)
    setSymbol(currency)
  },[])

  useEffect(() => {

    let revenueLabel: any[] = [];
    let revenueData: any[] = [];

    let serviceLabel: any[] = [];
    let serviceData: any[] = [];

    let typeLabel: any[] = [];
    let typeData: any[] = [];

    let zoneLabel: any[] = [];
    let zoneData: any[] = [];

    const today = new Date(Date.now())
    today.setMinutes(0)
    today.setSeconds(0)
    today.setMilliseconds(0)


    if (time === 'today') {
      revenueLabel = Array.from(Array(24).keys()).map(i => {
        const d = startOfDay(today); 
        d.setUTCHours(i); 
        return d.toISOString();
      });
      revenueData = revenueLabel.map(l => {
        const d = state?.revenueChartData.find(d => d.period.substr(11, 2) === l.substr(11, 2));
        return d ? d.count : 0;
      });
    }

    if (time === 'yesterday') {
      const yesterday = subDays(today,1) 
      revenueLabel = Array.from(Array(24).keys()).map(i => {
        const d = startOfDay(yesterday);
        d.setUTCHours(i); 
        return d.toISOString();
      });
      revenueData = revenueLabel.map(l => {
        const d = state?.revenueChartData.find(d => d.period.substr(11, 2) === l.substr(11, 2));
        return d ? d.count : 0;
      });
    }

    if (time === 'this-week') {
      for(let i = 1; i<8; i++){
        const d = startOfWeek(today,{weekStartsOn: 1});
        d.setDate(d.getDate()+i); 
        revenueLabel.push(d.toISOString());
      } 
      revenueData = revenueLabel.map(l => {
        const d = state?.revenueChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });
    }

    if (time === 'last-week') {
      const lastWeek = subWeeks(today, 1)
      console.log("last week date",lastWeek)
      for(let i = 1; i<8; i++){
        const d = startOfWeek(lastWeek,{weekStartsOn: 1}); 
        console.log("start week date",d)
        d.setDate(d.getDate()+i)   
        revenueLabel.push(d.toISOString());
      }
      console.log("revenue label last week",revenueLabel)
      revenueData = revenueLabel.map(l => {
        const d = state?.revenueChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });

      console.log("revenue data last week",revenueData)
    }

    if (time === 'this-month') {
      const daysInMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()
      for(let i = 1; i<= daysInMonth; i++){
        const d = startOfMonth(today); 
        d.setDate(i) 
        revenueLabel.push(d.toISOString());
      }
   

      revenueLabel = revenueLabel.map(data => {
        let date = new Date(data);
        let year = date.getFullYear();
        let month: number | string = date.getMonth() + 1;
        let dt: number | string = date.getDate();
        let hr: number | string = date.getHours();

        if (hr < 10) {
          hr = '0' + hr;
        }
        if (dt < 10) {
          dt = '0' + dt;
        }
        if (month < 10) {
          month = '0' + month;
        }
        return `${year}-${month}-${dt}-${hr}`
      })

      revenueData = revenueLabel.map(l => {
        const d = state?.revenueChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });
    }

    if (time === 'last-month') {
      const lastMonth = subMonths(today, 1)
      today.setMonth(new Date().getMonth() - 1)
      const month = new Date()
      month.setMonth(month.getMonth()-1);
      const daysInMonth = new Date(new Date().getFullYear(), month.getMonth() + 1, 0).getDate() 
      for(let i = 1; i<= daysInMonth; i++){
        const d = startOfMonth(lastMonth); 
        d.setDate(i) 
        revenueLabel.push(d.toISOString());
      } 

      revenueLabel = revenueLabel.map(data => {
        let date = new Date(data);
        let year = date.getFullYear();
        let month: number | string = date.getMonth() + 1;
        let dt: number | string = date.getDate();
        let hr: number | string = date.getHours();

        if (hr < 10) {
          hr = '0' + hr;
        }
        if (dt < 10) {
          dt = '0' + dt;
        }
        if (month < 10) {
          month = '0' + month;
        }
        return `${year}-${month}-${dt}-${hr}`
      })
      revenueData = revenueLabel.map(l => {
        const d = state?.revenueChartData.find(d =>d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      });
    }

    if (time === 'this-year') { 

      for(let i = 0; i< 12; i++){
        const d = startOfYear(today);
        d.setMonth(i); 
        revenueLabel.push(d.toISOString());
      }

      revenueLabel = revenueLabel.map(data => {
        let date = new Date(data);
        let year = date.getFullYear();
        let month: number | string = date.getMonth();
        let dt: number | string = date.getDate();
        let hr: number | string = date.getHours();

        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
        ];
        
        month = monthNames[month]
        if (hr < 10) {
          hr = '0' + hr;
        }
        if (dt < 10) {
          dt = '0' + dt;
        }
        // if (month < 10) {
        //   month = '0' + month;
        // }
        return `${year}-${month}`
      })
      revenueData = revenueLabel.map(l => {   
        const d = state?.revenueChartData.find(d =>d.period.substr(0, 7) === l.substr(0, 7));
        return d ? d.count : 0;
      });

      
    }

    if(time === 'custome'){
      const date1:any = new Date(from);
      const date2:any = new Date(to);
      date1.setHours(0)
      date2.setHours(23)
      const diffTime = differenceInDays(date2, date1); 
      for(let i = 0; i<= diffTime; i++){  
        const d = new Date(date1); 
        d.setDate(d.getDate()+i); 
        revenueLabel.push(d.toISOString());
      }   
      revenueData = revenueLabel.map(l => {
        const d = state?.revenueChartData.find(d => d.period.substr(5, 5) === l.substr(5, 5));
        return d ? d.count : 0;
      }); 
    }
    if(time=="last-week" || time === "last-month" || time === "today" || time==="yesterday" || time === "this-week" || time === "this-month" || time=="this-year" ){

    }
    else{
    revenueLabel = revenueLabel.map(data=>{
      console.log('dataka:', data)
      let date = new Date(data);
      let year = date.getFullYear();
      let month:number| string = date.getMonth();
      let dt:number| string = date.getDate();
      let hr:number| string = date.getHours();

      if (hr < 10) {
        hr = '0' + hr;
      }
      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      return `${year}-${month}-${dt}-${hr}` 
    }) 
  }
    console.log('revenueLabel:', revenueLabel)

    for (let i = 0; i < revenueLabel.length; ++i) {
      if (time === 'today' || time === 'yesterday') {
        revenueLabel[i] = revenueLabel[i].substr(11, 2);
      } else {
        revenueLabel[i] = revenueLabel[i].substr(5, 5);
      }
    }

    state?.serviceBreakdownChartData.map(items => {
      serviceData.push(items.count);
      serviceLabel.push(items.period);
    })

    state?.typeBreakdownChartData.map(items => {
      typeData.push(items.count);
      typeLabel.push(items.period);
    })

    state?.zoneBreakdownChartData.map(items => {
      zoneData.push(items.count);
      zoneLabel.push(items.period);
    })

    setRevenueChartData({
      labels: revenueLabel,
      datasets: [
        {
          label: 'stats.revenue',
          fill: true,
          backgroundColor: revenueData.map(v => (v < 30 ? '#ff0000' : (v >= 30 ? '#00ff00' : '#1b55e2'))),
          borderColor: '#00aa00',
          data: revenueData.map(data=> data/subUnit)//generateData(6, 2000),
        },
      ]
    })

    setServiceBreakdownChartData({
      labels: serviceLabel,
      datasets: [
        {
          label: 'Revenue',
          fill: true,
          backgroundColor: ["#4ecc48", "#ffcc29", "#fff012"],
          data: serviceData
        },
      ]
    })

    setTypeBreakdownChartData({
      labels: typeLabel,
      datasets: [
        {
          label: 'Revenue',
          fill: true,
          backgroundColor: ["#4ecc48", "#ffcc29"],
          data: typeData
        },
      ]
    })

    setZoneBreakdownChartData({
      labels: zoneLabel,
      datasets: [
        {
          label: 'Revenue',
          fill: true,
          backgroundColor: ["#4ecc48", "#ffcc29"],
          data: zoneData
        },
      ]
    })

  }, [state])

  const changeTime = (e: any) => {
    setTime(e.target.value)
  }

  const onChangeFrom = (e: any) => {
    setFrom(e.target.value)
  }

  const onChangeTo = (e: any) => {
    setTo(e.target.value)
  }

  return (
    <>
    {(!dataAvail)?<h1>No Data Available</h1>:
    <div className="row content-box">
      <div className="element-wrapper col-md-6 col-sm-12">
      {time === 'custome' ?
        <div className="row">
          <FormInput
            label={t("From")}
            name="from"
            type="date"
            onChange={onChangeFrom}
            className="col-sm-3"
            validation={{ required: true }}
            tabIndex={7}
            {...formProps}
          />
          <FormInput
            label={t("To")}
            name="to"
            type="date"
            onChange={onChangeTo}
            className="col-sm-3"
            validation={{ required: true }}
            tabIndex={7}
            {...formProps}
          />
        </div> : null}
        <div className="element-actions">
          <form className="form-inline justify-content-sm-end">
            <select value={time} onChange={changeTime} className="form-control form-control-sm rounded">
              <option value="today">{t('dropdown.today')}</option>
              <option value="yesterday">{t('dropdown.yesterday')}</option>
              <option value="this-week">{t('dropdown.thisWeek')}</option>
              <option value="last-week">{t('dropdown.lastWeek')}</option>
              <option value="this-month">{t('dropdown.thisMonth')}</option>
              <option value="last-month">{t('dropdown.lastMonth')}</option>
              <option value="this-year">{t('dropdown.thisYear')}</option>
              <option value="custome">{t('dropdown.custome')}</option>
            </select>
          </form>
        </div>
        <div className="element-box">
          <div className="os-tabs-w">
            <div className="tab-content">
              <div className="tab-pane active" id="tab_overview">
                <div className="el-chart-w">
                  <div className="centered-header"><h6>{t("revenue.today")}</h6></div>
                  <Bar data={revenueChartData} options={{
                    ...chartOptions,
                    tooltips: {
                      enabled: true,
                      displayColors: false,
                      backgroundColor: 'rgb(150,50,0)',
                      titleFontColor: 'rgb(255,255,255)',
                      bodyFontColor: 'rgb(255,255,255)',
                      footerFontColor: 'rgb(255,255,255)',
                      footerFontStyle: 'normal',
                      borderColor: 'rgb(100,50,0)',
                      borderWidth: 1,
                      callbacks: {
                        label: function (item: any, everything: any) {
                          let value = item.yLabel
                          value = `Revenue: ${symbol} ${new Intl.NumberFormat().format(value)}`
                          return value
                        },
                        title: function (item: any, everything: any) {
                          let times = item[0].xLabel
                          if (time === 'today') {
                            times = `Time: ${tooltipsDate.toString().substr(0, 15)} ${times}:00:00`
                            return times
                          }
                          if (time === 'yesterday') {
                            const yesterday = subDays(tooltipsDate, 1)
                            times = `Time: ${yesterday.toString().substr(0, 15)} ${times}:00:00`
                            return times
                          }
                          else {
                            times = `Date: ${tooltipsDate.getFullYear()}-${times}`
                            return times
                          }
                        }
                      },
                    }
                  }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-sm-12">
        <div className="row">
          <div className="element-wrapper col-md-6 col-sm-12">
            <div className="element-box">
              <div className="centered-header"><h6>{t('revenue.zoneBreakdown')}</h6></div>
              <Doughnut data={zoneBreakdownChartData} options={{...doughnutOptions,
      tooltips: {
        callbacks: {
          label: function(tooltipItem:any, data:any) {
            //get the concerned dataset
            var dataset = data.datasets[tooltipItem.datasetIndex];
            //get the current items value
            var currentValue = dataset.data[tooltipItem.index];
            //Format value in international format with currency
            var formattedValue = `${symbol} ${new Intl.NumberFormat().format(currentValue/subUnit)}`;
      
            return formattedValue;
          }
        }
     }
              
                
              
              }} />
            </div>
          </div>
          <div className="element-wrapper col-md-6 col-sm-12">
            <div className="element-box">
              <div className="centered-header"><h6>{t('revenue.serviceBreakdown')}</h6></div>
              <Doughnut data={serviceBreakdownChartData} options={{...doughnutOptions,
      tooltips: {
        callbacks: {
          label: function(tooltipItem:any, data:any) {
            //get the concerned dataset
            var dataset = data.datasets[tooltipItem.datasetIndex];
            //get the current items value
            var currentValue = dataset.data[tooltipItem.index];
            //Format value in international format with currency
            var formattedValue = `${symbol} ${new Intl.NumberFormat().format(currentValue/subUnit)}`;
      
            return formattedValue;
          }
        }
     }
              
                
              
              }} />
            </div>
          </div>
          <div className="element-wrapper col-md-6 col-sm-12">
            <div className="element-box">
              <div className="centered-header"><h6>{t('revenue.typeBreakdown')}</h6></div>
              <Doughnut data={typeBreakdownChartData} options={{...doughnutOptions,
      tooltips: {
        callbacks: {
          label: function(tooltipItem:any, data:any) {
            //get the concerned dataset
            var dataset = data.datasets[tooltipItem.datasetIndex];
            //get the current items value
            var currentValue = dataset.data[tooltipItem.index];
            //Format value in international format with currency
            var formattedValue = `${symbol} ${new Intl.NumberFormat().format(currentValue/subUnit)}`;
      
            return formattedValue;
          }
        }
     }
              
                
              
              }} />
            </div>
          </div>
        </div>
      </div>
    </div>}
    </>
  );

}
function lastOfWeek(arg0: Date): any {
  throw new Error("Function not implemented.");
}

