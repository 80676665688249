import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import { Column, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { Loading } from '../alerts/loading.component';
import { Currency } from '../../entities/currency.entity';
import { Settings } from '../../entities/settings.entity';
import { Api, Endpoint } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { SettingsService } from '../../services/settings.service';
import { getGeneralZoneDistanceTitle, getGeneralZoneDistanceValue } from '../../util/distance';
import { debounce } from "lodash";
import { Zone } from '../../entities/zone.entity';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { FormInput } from '../form/input/input.component';
import { transactionReport } from '../../pages/report/list/list.component';
import { sessionReport } from '../../pages/report/list/shift.component';


interface Sort {
  sortDirection: string | undefined,
  accessor: string | undefined
}

interface props {
  isCorporate?: boolean | undefined,
  cooperateCol?: any
  colData : any
  disableExportCsvDisplay?: boolean
  disableTimeFilterDisplay?:boolean
  translationColumnName?:string
  disablePaginationDisplay?:boolean
  endpoint: Endpoint
  endpointParams?:any
  timeDuration?:string
  disableSearchForColumns?: String[]
  
}

enum Unit {
  Metric = "metric",
  Imperial = "imperial"
}

interface ReportResponse {
  data: Object[],
  count: number
}


export function ReportTable({ disableSearchForColumns,isCorporate, cooperateCol, colData, disableExportCsvDisplay, disableTimeFilterDisplay, translationColumnName, disablePaginationDisplay,endpoint,endpointParams, timeDuration }: props) {
  let unit:any;
  let history = useHistory();  
  const [from, setFrom] = useState<string>('');
  const { t } = useTranslation('dashboard');
  const translationReport = useTranslation('main')
  const formProps = useFormContext();
  const [time, setTime] = useState<string>('today');
  const [to, setTo] = useState<string>('');
  const [transaction, setTransaction] = useState<any>(undefined)
  const [transactionCSV, setTransactionCSV] = useState<any>("")
  const [pageCounts, setPageCounts] = useState<number>(1);
  const [count, setCount] = useState(0)
  const [search, setSearch] = useState<string>('')
  const [itemCount, setItemCount] = useState<number>(0)
  const [user, setUser] = useState(AuthService.getUser());
  const [columnData, setColumnData] = useState([]);
  const [sort, setSort] = useState<Sort>({ sortDirection: undefined, accessor: undefined });
  const [generalTimezone, setGeneralTimezone] = useState();
  const ref = useRef<any>(null);
  const [intervalId,setIntervalId] = useState<any>();
  const [leftScrollIntervalId,setLeftScrollIntervalId] = useState<any>();
  const [loading,setLoading] = useState<boolean>(true)
  const [zoneData,setZoneData] = useState<Zone>();
  let trans:any;
  let selectedZone:Zone;



  // getting column data for the table
    useEffect(() => {
     getColumnData()
    }, [])

  const onChangeTo = (e: any) => {
    setTo(e.target.value)
  }

  const onChangeFrom = (e: any) => {
    setFrom(e.target.value)
  }

  const changeTime = (e: any) => {
    setTime(e.target.value)
  }

  useLayoutEffect(()=>{
    getZone()
  }, [transaction,to,from,time])

  const getZone = async()=>{

    const zone=  await Api.get<any, any>(Endpoint.ZONE_SELECTED, {})
    selectedZone =zone
    setZoneData(zone)
    
   
  }


  
  const dateFormate = (date: any) => {
    const settings: Settings | undefined = SettingsService.getSetting();
    let d: any = new Date(date)
    return d.toLocaleString(undefined, { timeZone: (settings as Settings)?.zone?.generalTimezone });

  }

  const scroll = (scrollOffset:any) => {
    ref.current.scrollLeft += scrollOffset;
  };

  const calculateTimeDifference = (startDate: Date, endDate: Date) => {

    let diffTime = Math.abs(startDate.valueOf() - endDate.valueOf());
    let days: any = diffTime / (24 * 60 * 60 * 1000);
    let hours: any = (days % 1) * 24;
    let minutes: any = (hours % 1) * 60;
    let secs: any = (minutes % 1) * 60;
    [days, hours, minutes, secs] = [Math.floor(days), Math.floor(hours), Math.floor(minutes), Math.floor(secs)]

    hours = ("0" + hours).slice(-2);
    secs = ("0" + secs).slice(-2);
    minutes = ("0" + minutes).slice(-2);
    days = ("0" + days).slice(-2);



    return `${days}:${hours}:${minutes}:${secs}`

  }


  const refresh = useCallback(async (limit: number, offset: number, search?: string, direction?: string, column?: string, total?: any, sumVal?: any) => {
    setLoading(true);
    console.log("time",column)
    if (column && search) {
      let number;
      const unit = selectedZone?.generalCurrency ? (selectedZone?.generalCurrency as Currency).subunits : 100;
      // for distance
      if ((column ===  'TotalCCFee') || (column === "StartCharge") || (column ==='TotalFare') || (column ==='GST') || (column ==='Extras') || (column === 'Levy') || (column === 'Tolls') || (column === 'BookingFee') || (column === 'DiscountValue') || (column ==='Tips') || (column === 'ccCharge') || (column === 'WaitTimeFare') || (column === 'DistanceFare') ) {
        number = search.split(" ", 2)
        const cashValue = parseInt(number[1]) * unit
        search = `${number[0]} ${cashValue}`
      }
      if(column === 'Distance'){
        number = search.split(" ")
        const distance = selectedZone?.generalUnit === Unit.Metric ? parseFloat(number[1])* 1000: parseFloat(number[1])* 1609.34
        search = `${number[0]} ${distance}`
      }
    }
    if (time === 'custome' && from && to) {
      const datetime = new Date();
      if (new Date(from).getFullYear() >= 2000 && new Date(from).getFullYear() <= datetime.getFullYear() && new Date(to).getFullYear() <= datetime.getFullYear()) {
     trans = await Api.get<sessionReport, any>(endpoint as Endpoint, { limit, offset, search, column, direction, isCorporate,time: time, to: to, from: from ,  ...endpointParams });
     const temp = Math.ceil(trans?.count / pageSize)
      const tempOffset = count * pageSize
      setPageCounts(temp)
    }} 
    else if (time === 'today' || time === 'yesterday' || time === 'this-week' || time === 'last-week' || time === 'this-month' || time === 'last-month' || time === 'this-year') 
    {     
      console.log("endpoint",endpoint)
       trans = await Api.get<ReportResponse, any>(endpoint as Endpoint, { limit, offset, search, column, direction, isCorporate,time: time });
       const temp = Math.ceil(trans?.count / pageSize)
      const tempOffset = count * pageSize
      setPageCounts(temp)
      }
      console.log("trans",trans)

    const currency = selectedZone?.generalCurrency ? (selectedZone?.generalCurrency as Currency).symbol : null;
    
      let ro: any = [];
      if (trans && trans?.data && trans?.data.length > 0) {
        switch(endpoint){
          case Endpoint.GEO_REFERENCE:

          trans.data.map((key: any) => {
          ro.push({
            VehiclePlate: key.vehicle.plate === null ? "N/A" : key.vehicle.plate,
            DriverID: `${key.driver?.firstName} ${key.driver?.lastName}`,
            Zone :  key.zone ? key.zone.title : "N/A",
            TripCode: key.trip ? key.trip.code : "N/A",
            BookingCode: key.booking ? key.booking.code : "N/A",
            EnterTime: dateFormate(key.createTime),
            ExitTime: `${key.endTime === null ? 'Still Inside' : dateFormate(key.endTime)
              }`,
            TimeDuration : calculateTimeDifference(new Date(key.createTime),key.endTime ? new Date(key.endTime): new Date()),
            
           
          });
        });

            break
        }
        // trans.data.map((key: any) => {
        //   ro.push({
        //     ShiftCode: key.code === null ? "" : key.code,
        //     TaxiPlate: key.vehicle.plate === null ? "" : key.vehicle.plate,
        //     DriverId: `${key.driver?.firstName} ${key.driver?.lastName}`,
        //     ShiftStartTime: dateFormate(key.createTime),
        //     ShiftEndTime: `${key.endTime === null ? 'Open' : dateFormate(key.endTime)
        //       }`,
        //     TotalOfflineTime:
        //       key?.offlineMinutes === null ? "0 Mins" : `${key?.offlineMinutes} Mins`,
        //     // ShiftCheckList: <button onClick={() => onclick(key.id)} className="btn btn-primary">{translationReport.t('reports.shifts.View')}</button>,
        //     TotalTravelledDistance: key?.totalRideDistance === null
        //       ? `${(0 as number).toFixed(3)} ` + `${getGeneralZoneDistanceTitle()}`
        //       : `${(key?.totalRideDistance / getGeneralZoneDistanceValue()).toFixed(3)} ${getGeneralZoneDistanceTitle()}`,
        //     TotalTripDistance: key?.distance === null
        //       ? `${(0 as number).toFixed(3)} ` + `${getGeneralZoneDistanceTitle()}`
        //       : `${(key?.distance / (getGeneralZoneDistanceValue())).toFixed(3)} ${getGeneralZoneDistanceTitle()}`,
        //     BookingCounts: key?.bookingCount === null ? "0" : key?.bookingCount,
        //     HailCounts: key?.tripCount === null ? "0" : key?.tripCount,
        //     TripCounts: (key?.tripCount + key?.bookingCount) === null ? "0" : key?.tripCount + key?.bookingCount,
        //     Tolls:
        //       key?.totalCharges_Tolls === null
        //         ? `${currency} 0`
        //         : `${currency} ${toSubUnit(key.totalCharges_Tolls)?.toFixed(Math.round((Math.log(unit) / Math.log(10))))}`,
        //     Extras:
        //       key?.totalCharges_Extras === null
        //         ? `${currency} 0`
        //         : `${currency} ${toSubUnit(key.totalCharges_Extras)?.toFixed(Math.round((Math.log(unit) / Math.log(10))))} `,
        //     TotalCCFee:
        //       key?.totalCharges_CCFee === null
        //         ? `${currency} 0`
        //         : `${currency} ${toSubUnit(key.totalCharges_CCFee)?.toFixed(Math.round((Math.log(unit) / Math.log(10))))}`,
        //     TotalFare:
        //       key?.totalCharges_Fares === null
        //         ? `${currency} 0`
        //         : `${currency} ${toSubUnit(key?.totalCharges_Fares)?.toFixed(Math.round((Math.log(unit) / Math.log(10))))}`,
        //     Fares:
        //       key?.totalCharges_Fares === null
        //         ? `${currency} 0`
        //         : `${currency} ${toSubUnit(key.totalCharges_Fares)?.toFixed(Math.round((Math.log(unit) / Math.log(10))))}`,
        //     Fuel:
        //       (key?.fuel === null && key.endTime === null)
        //         ? `N/A`
        //         : `${currency} ${toSubUnit(key.fuel)?.toFixed(Math.round((Math.log(unit) / Math.log(10))))}`,
        //     Wash:
        //       (key?.wash === null && key.endTime === null)
        //         ? `N/A`
        //         : `${currency} ${toSubUnit(key.wash)?.toFixed(Math.round((Math.log(unit) / Math.log(10))))}`,
        //     VacantTolls:
        //       (key?.vacantTolls === null && key.endTime === null)
        //         ? `N/A`
        //         : `${currency} ${toSubUnit(key.vacantTolls)?.toFixed(Math.round((Math.log(unit) / Math.log(10))))}`,
        //     TotalTips:
        //       key?.totalCharges_Tip === null
        //         ? `${currency} 0`
        //         : `${currency} ${toSubUnit(key.totalCharges_Tip)?.toFixed(Math.round((Math.log(unit) / Math.log(10))))}`,
        //     CreditCardPayments:
        //       key?.totalCharges_CC === null
        //         ? `${currency} 0`
        //         : `${currency} ${toSubUnit(key.totalCharges_CC)?.toFixed(Math.round((Math.log(unit) / Math.log(10))))}`,
        //     CashPayments:
        //       key?.totalCharges_Cash === null
        //         ? `${currency} 0`
        //         : `${currency} ${toSubUnit(key.totalCharges_Cash)?.toFixed(Math.round((Math.log(unit) / Math.log(10))))}`,
        //     TransactionFeeDeduction:
        //       key?.totalTransaction_Fee_Deduction === null
        //         ? `${currency} 0`
        //         : `${currency} ${toSubUnit(
        //           key.totalTransaction_Fee_Deduction
        //         )?.toFixed(Math.round((Math.log(unit) / Math.log(10))))}`,
        //     PassengerLevyDeduction:
        //       key?.totalPassenger_Levy_Deduction === null
        //         ? `${currency} 0`
        //         : `${currency} ${toSubUnit(key.totalPassenger_Levy_Deduction)?.toFixed(Math.round((Math.log(unit) / Math.log(10))))}`,
        //     TotalBookingFeeDeduction:
        //       key?.totalBooking_Fee_Deduction === null
        //         ? `${currency} 0`
        //         : `${currency} ${toSubUnit(key.totalBooking_Fee_Deduction)?.toFixed(Math.round((Math.log(unit) / Math.log(10))))}`,
        //     NetBankPayment:
        //       key?.totalNet_Bank === null
        //         ? `${currency} 0`
        //         : `${currency} ${toSubUnit(key.totalNet_Bank)?.toFixed(Math.round((Math.log(unit) / Math.log(10))))}`,
        //     NetRevenue:
        //       (key?.totalNet_Revenue === null || key.endTime === null)
        //         ? `N/A`
        //         : `${currency} ${toSubUnit(key.totalNet_Revenue)?.toFixed(Math.round((Math.log(unit) / Math.log(10))))}`,
        //     PaymentStatus: key?.paymentStatus,
        //     PayableGST:
        //       key?.totalPayable_GST === null
        //         ? `${currency} 0`
        //         : `${currency} ${toSubUnit(key.totalPayable_GST)?.toFixed(Math.round((Math.log(unit) / Math.log(10))))}`,
        //   });
        // });
      }
      setTransaction(ro)
      setLoading(false)
  }, [transaction,time,from,to])

  const data = React.useMemo(
    () => {
      return transaction ?[...transaction]:[]
    },
    [transaction]
  )

  const columns: Array<Column<any>> = React.useMemo(
    () => {
      const col: any = [];
      columnData?.map((column: any) => {
        col.push({
          Header: column,
          accessor: column?.replaceAll(" ", ""),
          sortType: 'basic',
          sortDirection: sort.accessor === column?.replaceAll(" ", "") ? sort.sortDirection : undefined

        })


      })

      return col
    }

    , [columnData]
  )





  useEffect(() => {

    if (!columnData) {

      history.push("/dashboard/overview")

    }

  }, [columnData])

  const getColumnData = async () => {
    setColumnData(colData)
    setLoading(true)
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    setPageSize,
    prepareRow,
  } = useTable({
    columns, data, manualPagination: true, manualSortBy: true, manualFilters: true
  }, useFilters, useSortBy, usePagination)

  const { pageSize } = state

  const getPageCount = async () => {
    setLoading(true);
    const data = ""
    // const d = await Api.get<any, any>(Endpoint.TRANSACTION_RIDES_SUM, {});
    const tempOffset = count * pageSize
    refresh(pageSize, tempOffset, search, sort.sortDirection, sort.accessor, data)
  }

  const handler = useCallback(debounce((text: string) => { 
    setLoading(true)
    setSearch(text) 
  }, 2000), []);

  useEffect(() => {
    getPageCount()
  }, [search, pageSize, count,time,from,to])

  const onNextPage = () => {
    setCount(prevCount => prevCount + 1)
  }

  const onPreviousPage = () => {
    setCount(prevCount => prevCount - 1)
  }

  const onNextNextPage = () => {
    setCount(pageCounts - 1)
  }

  const onPreviousPreviousPage = () => {
    setCount(0)
  }

  const onClickHeader = (column: any) => {
    console.log("sort direction",column.sortDirection)
    switch (column.sortDirection) {
      case undefined:
        column.sortDirection = undefined
        setSort({ sortDirection: undefined, accessor: column.id,  });
        break;
      case 'ASC':
        column.sortDirection = 'DESC'
        setSort({ sortDirection: 'DESC', accessor: column.id });
        break;
      case 'DESC':
        column.sortDirection = undefined
        setSort({ sortDirection: undefined, accessor: column.id });
        break;
    }
  }

  const onChangeSeaech = (e: any, column: any) => {
    handler(e.target.value)
  }
  const clickMethod = (column: any) => {
    history.push(`/security/track/${column.original.TripCode}`);
  }

  
  const handleRightScroll = ()=>{
    
    setIntervalId(
    setInterval(()=>{
      scroll(200)
    },50)
    )
  }

  const handleLeftScroll = ()=>{
    
    setLeftScrollIntervalId(
    setInterval(()=>{
      scroll(-200)
    },50)
    )
  }

  // useEffect(()=>{
  //   handleCSV()
  // },[search,time,from,to])

  // async function handleCSV (){

  //   const CSVData = await Api.get<transactionReport, any>(Endpoint.TRANSACTION_RIDES_LIST, { limit:999,search:search, column:sort.accessor, direction:sort.sortDirection, isCorporate:isCorporate,time: time, to: to, from: from});
  //   let ro:any = [];
  //   const currency = zoneData?.generalCurrency ? (zoneData?.generalCurrency as Currency).symbol : null;
  //   if (CSVData && CSVData?.transaction && CSVData?.transaction.length > 0) {
  //     CSVData?.transaction.map((key: any) => {
  //       ro.push({
  //         'TripCode': key.trip?.code === null ? "" : key.trip.code,
  //         'CreateTime': new Date(key.createTime).toLocaleString(),
  //         'TransactionID': key.shortId === null ? "" : key.shortId,
  //         'VehiclePlate': key.trip?.session?.vehicle?.plate === null ? "" : key.trip?.session?.vehicle?.plate,
  //         'StartTime': dateFormate(key.trip?.startTime),
  //         'EndTime': `${key.trip?.endTime === null ? 0 : dateFormate(key.trip.endTime)}`,
  //         'Distance': key.trip?.distance === null ? `${(0 as number).toFixed(3)}` + `${getGeneralZoneDistanceTitle()}` : `${(key.trip.distance / getGeneralZoneDistanceValue()).toFixed(3)} ${getGeneralZoneDistanceTitle()}`,
  //         'WaitTime': `${key.trip?.waitTime === null ? 0 : new Date(key.trip.waitTime * 1000).toISOString().substr(11, 8)}`,
  //         'TotalCCFee': key.trip?.ccCharge === null ? "" : `${currency} ${toSubUnit(key.trip.ccCharge)}`,
  //         'StartCharge': key.trip?.startCharge === null ? "" : `${currency} ${toSubUnit(key.trip.startCharge)}`,
  //         'TotalFare': key.trip?.totalFare === null ? "" : `${currency} ${toSubUnit(key.trip.totalFare)}`,
  //         'TotalTime': key.trip?.rideTime === null ? "" : new Date(key.trip.rideTime * 1000).toISOString().substr(11, 8),
  //         "GST": key.trip?.tax === null ? "" : `${currency} ${toSubUnit(key.trip.tax)}`,
  //         "Extras": key.trip?.extraCharge === null ? "" : `${currency} ${toSubUnit(key.trip.extraCharge)}`,
  //         "Levy": key.trip?.levy === null ? "" : `${currency} ${toSubUnit(key.trip.levy)}`,
  //         "Tolls": key.trip?.tolls === null ? "" : `${currency} ${toSubUnit(key.trip.tolls)}`,
  //         "BookingFee": key.trip?.bookingCharge === null ? "" : `${currency} ${toSubUnit(key.trip.bookingCharge)}`,
  //         "Type": key.trip?.type === null ? "" : key.trip.type,
  //         "RateType": key.trip?.rateType === null ? "" : key.trip.rateType,
  //         "DiscountType": key.trip?.discountType === null ? "" : key.trip.discountType,
  //         "DiscountValue": key.trip?.discountValue === null ? "" : `${currency} ${toSubUnit(key.trip.discountValue)}`,
  //         "Tips": key.trip?.tip === null ? "" : `${currency} ${toSubUnit(key.trip.tip)}`,
  //         "ccCharge": key.trip?.ccCharge === null ? "" : `${currency} ${toSubUnit(key.trip.ccCharge)}`,
  //         'WaitTimeFare': key.trip?.waitCharge === null ? "" : `${currency} ${toSubUnit(key.trip.waitCharge)}`,
  //         'DistanceFare': key.trip?.distanceCharge === null ? "" : `${currency} ${toSubUnit(key.trip.distanceCharge)}`,
  //         "Service": key.trip?.service.title === null ? "" : key.trip.service.title,
  //         'Zone': key.trip?.zone.title === null ? "" : key.trip.zone.title,
  //         'Fare': key.trip?.fare.title === null ? "" : key.trip.fare.title,
  //         'DriverID': key.trip?.session === null ? "" : `${key.trip?.session.driver.firstName} ${key.trip.session.driver.lastName}`,
  //         'Passenger': key.trip?.passenger?.firstName === undefined ? "" : `${key.trip?.passenger?.firstName} ${key.trip?.passenger?.lastName}`,
  //         'CompanyName': key.trip?.booking?.corporateBooking.companyName === null ? "" : `${key.trip?.booking?.corporateBooking.companyName}`,
  //         'ShiftCode': key.trip?.session.code === null ? "" : key.trip?.session.code,
  //       });
  //     })
  //     setTransactionCSV([...ro])
  //   }
   
  // }

  const searchFilter = (column: any) => {

    if (!disableSearchForColumns?.includes(column.id)) {
      return <input onChange={(e) => onChangeSeaech(e, column)}
      />
    }
      return <input style={{
        border: "0px",
        background: "bottom"
      }} disabled onChange={(e) => onChangeSeaech(e, column)} />

  }
  

  return (
    <>
        <div>
          <div className=" py text-center" style={{ display: 'flow-root' }}>
            {(transaction)?
            // CSV button
            !disableExportCsvDisplay &&<CSVLink style={{ float: "right", marginTop: "26px", marginRight: "20px" }} filename={"rides-report.csv"} className="btn btn-primary" data={transactionCSV}>{translationReport.t('reports.exportCSV')}</CSVLink>:null}

            <button style={{ float: "right", marginTop: "26px", marginRight: "20px" }} className='btn btn-primary' onClick={()=>{scroll(200)}} onMouseDown={handleRightScroll}  onMouseUp={()=>clearInterval(intervalId)} onMouseLeave={()=>clearInterval(intervalId)}>{'>'}</button>
            <button style={{ float: "right", marginTop: "26px", marginRight: "20px" }} className='btn btn-primary'  onClick={()=>{scroll(-200)}} onMouseDown={handleLeftScroll}  onMouseUp={()=>clearInterval(leftScrollIntervalId)} onMouseLeave={()=>clearInterval(leftScrollIntervalId)}>{'<'}</button>
            {loading==true?<button disabled onClick={(e) => { e.preventDefault(); getPageCount() }} style={{ float: "right", marginTop: "26px", marginRight: "20px" }} className={`btn btn-primary`}>
            Loading.....</button>:<button onClick={(e) => { e.preventDefault(); getPageCount() }} style={{ float: "right", marginTop: "26px", marginRight: "20px" }} className={`btn btn-primary os-icon os-icon-refresh-ccw`}>
            </button>}
          </div>
          <div> 
            {time === 'custome' ?
          <>
            <div className="row">
              <FormInput
                label={t("From")}
                name="from"
                type="date"
                onChange={onChangeFrom}
                className="col-sm-3"
                validation={{ required: true }}
                tabIndex={7}
                {...formProps}
              />
              <FormInput
                label={t("To")}
                name="to"
                type="date"
                onChange={onChangeTo}
                className="col-sm-3"
                validation={{ required: true }}
                tabIndex={7}
                {...formProps}
              />
            </div>

          </> : null}
          {/*  Date And Time Filter  */}
          {!disableTimeFilterDisplay &&<div style={{ marginRight: '15px', marginBottom: '10px', marginTop: '15px' }}>
            <form className="form-inline justify-content-sm-end">
              <select value={time} onChange={changeTime} className="form-control form-control-sm rounded">
                <option value="today">{t('dropdown.today')}</option>
                <option value="yesterday">{t('dropdown.yesterday')}</option>
                <option value="this-week">{t('dropdown.thisWeek')}</option>
                <option value="last-week">{t('dropdown.lastWeek')}</option>
                <option value="this-month">{t('dropdown.thisMonth')}</option>
                <option value="last-month">{t('dropdown.lastMonth')}</option>
                <option value="this-year">{t('dropdown.thisYear')}</option>
                <option value="custome">{t('dropdown.custome')}</option>
              </select>
            </form>
          </div>}
           </div>
          <div ref={ref} style={{ marginLeft: "5px", marginRight: "5px", overflowX: "scroll", width: "100%" }} className=" py-5 text-center r2">
        <table {...getTableProps()} className="table table-striped table-Info table-hover table-bordered">
              <thead>
                {headerGroups.map((headerGroup: any) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())} onClick={() => onClickHeader(column)} className="bg-primary text-white">
                        {column.render('Header')?translationReport.t(`reports.${translationColumnName}.${column.id}`):""}
                        <span>
                          {column.sortDirection === 'ASC' ? (
                            ' 🔽'
                          ) : column.sortDirection === 'DESC' ? (
                            ' 🔼'
                          ) : null}
                        </span>

                        {/* Search in the table */}
                        <div><span>
                         
                                                    {searchFilter(column)}

                        </span>
                        </div>
                        {/* End of Search */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
       {transaction?.length>0?<tbody {...getTableBodyProps()}>
                {page.map((row: any) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()} onClick={() => clickMethod(row)}>
                      {row.cells.map((cell: any) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>:<tbody>
                <tr>
                  <td colSpan={columnData.length-12}>
                  No results to display
                  </td>
                </tr>
                </tbody>}
            </table>
          </div>
          {!disablePaginationDisplay &&
          <div className=" py-5 text-center">
            <span>
            {translationReport.t('reports.page')}{' '}
              <strong>
                {count + 1} {translationReport.t("reports.of")} {pageCounts}
              </strong>{' '}
            </span>
            <span>
              | {translationReport.t("reports.goPage")}{''}
              <input
                type='number'
                defaultValue={count + 1}
                onChange={(e: any) => {
                  const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                  setCount(pageNumber)
                }}
                style={{ marginRight: "10px", width: "70px", marginLeft: "5px", borderRadius: "5px" }}
              />
            </span>
            <select
              value={pageSize}
              onChange={(e: any) => setPageSize(Number(e.target.value))}
              style={{ marginRight: "10px", width: "80px", marginLeft: "5px", borderRadius: "5px" }}
            >
              {[10, 25, 50, 100].map((pageSize: any) => {
                return <option key={pageSize} value={pageSize}>
                  {translationReport.t('reports.show')} {pageSize}
                </option>
              })}
            </select>
            <button type="button" className="btn btn-primary" onClick={() => onPreviousPreviousPage()} disabled={count > 0 ? false : true}>{'<<'}</button>
            <button type="button" style={{ marginRight: "10px", marginLeft: "10px" }} className="btn btn-primary" onClick={() => onPreviousPage()} disabled={count > 0 ? false : true}>{translationReport.t('reports.previous')}</button>
          <button type="button" style={{ marginRight: "10px" }} className="btn btn-primary" onClick={() => onNextPage()} disabled={(count + 1) < pageCounts ? false : true}>{translationReport.t('reports.next')}</button>
            <button type="button" className="btn btn-primary" onClick={() => onNextNextPage()} disabled={(count + 1) < pageCounts ? false : true}>{'>>'}</button>
          </div>}
        </div>
    </>
  )
}