import { Api, Endpoint } from "../services/api.service";

export const checkCCStatus = async () => {

    let CC = await Api.get(Endpoint.SETTINGS_CC);
      if (CC === 'true') {
        return true
      }
      else { return false }

} 