import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormUpload, selectedUrl, UploadPreviewUrl } from "../../../../components/form/input/upload.component";
import { FormSection } from "../../../../components/form/section.component";
import { getUrl } from "../../../../components/form/util";
import { Driver, DriverFileType } from "../../../../entities/driver.entity";
import { IFile } from "../../../../entities/file.entity";
import { Api, Endpoint } from "../../../../services/api.service";

interface id {
  id: string | undefined;
  picId: string  
  fileType: DriverFileType
}

export function DriverPostVerification() {

  const [citizen, setCitizen] = useState<boolean>(false) 
  const { t } = useTranslation('forms');
  const formProps = useFormContext();
  const { getValues } = formProps;

  const getUrls = (type: DriverFileType): UploadPreviewUrl[] | undefined => {
    const driver: Driver = formProps.control.defaultValuesRef.current as Driver;
    console.log('urlThumbka:', driver)
    switch (type) {
      case DriverFileType.License:
        let LicenseId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${driver.id}/license/`).toString()
        LicenseId = LicenseId?.substring(1, 37); 
        const licenseDocument = driver.licenseFiles?.filter(file => file.id !== LicenseId) 
        return licenseDocument ? licenseDocument.map(file => fileToUrl(driver, file)) : []; 
        case DriverFileType.IdentityFront:
          let identityFrontId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${driver.id}/identity/`).toString()
          identityFrontId = identityFrontId?.substring(1, 37);
          const identityFrontDocument = driver.identityFiles?.filter(file => file.id !== identityFrontId)
          return identityFrontDocument ? identityFrontDocument.map(file => fileToUrl(driver, file)) : [];
          case DriverFileType.IdentityBack:
          let identityBackId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${driver.id}/identity/`).toString()
          identityBackId = identityBackId?.substring(1, 37);
          const identityBackDocument = driver.identityFiles?.filter(file => file.id !== identityBackId)
          return identityBackDocument ? identityBackDocument.map(file => fileToUrl(driver, file)) : [];
        case DriverFileType.Police:
      case DriverFileType.Police:
        let PoliceId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${driver.id}/police/`).toString()
        PoliceId = PoliceId?.substring(1, 37); 
        const policeDocument = driver.policeFiles?.filter(file => file.id !== PoliceId) 
        return policeDocument ? policeDocument.map(file => fileToUrl(driver, file)) : [];
      case DriverFileType.History:
        let HistoryId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${driver.id}/history/`).toString()
        HistoryId = HistoryId?.substring(1, 37); 
        const historyDocument = driver.historyFiles?.filter(file => file.id !== HistoryId) 
        return historyDocument ? historyDocument.map(file => fileToUrl(driver, file)) : [];
      case DriverFileType.Vevo:
        let VevoId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${driver.id}/vevo/`).toString()
        VevoId = VevoId?.substring(1, 37); 
        const vevoDocument = driver.vevoFiles?.filter(file => file.id !== VevoId) 
        return vevoDocument ? vevoDocument.map(file => fileToUrl(driver, file)) : [];
      case DriverFileType.Medicare:
        let MedicareId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${driver.id}/medicare/`).toString()
        MedicareId = MedicareId?.substring(1, 37); 
        const medicareDocument = driver.medicareCardFiles?.filter(file => file.id !== MedicareId) 
        return medicareDocument ? medicareDocument.map(file => fileToUrl(driver, file)) : [];
      case DriverFileType.Proficiency:
        let ProficiencyId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${driver.id}/proficiency/`).toString()
        ProficiencyId = ProficiencyId?.substring(1 , 37);  
        const proficiencyDocument = driver.englishLanguageProficiencyFiles?.filter(file=> file.id !== ProficiencyId) 
        return proficiencyDocument ? proficiencyDocument.map(file => fileToUrl(driver, file)) : []; 
    }
    return;
  }

  const fileToUrl = (driver: Driver, file: IFile): UploadPreviewUrl => {
    return {
      thumb: getUrl(`/driver/${driver.id}/${file.type}/${file.id}/admin_single.${file.extension}`),
      full: getUrl(`/driver/${driver.id}/${file.type}/${file.id}/admin_large.${file.extension}`),
    }
  }

  const FormIdentityFrontOnclick = async (e: any) => {
    e.preventDefault()  
    const form: Driver = getValues() as Driver;
    let identityId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${form.id}/identity/`).toString()
    identityId = identityId?.substring(1, 37); 
    console.log('urlThumbka:', identityId,'form.medicareCardFiles:', form)
    if (form.identityFiles && form.identityFiles.length > 0 && identityId) { 
      await Api.post<any, id>(Endpoint.FILE_DELETE, { id: form.id, picId: identityId, fileType: DriverFileType.IdentityFront });
    } 
  }

  const FormIdentityBackOnclick = async (e: any) => {
    e.preventDefault()  
    const form: Driver = getValues() as Driver;
    let identityId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${form.id}/identity/`).toString()
    identityId = identityId?.substring(1, 37); 
    console.log('urlThumbka:', identityId,'form.medicareCardFiles:', form)
    if (form.identityFiles && form.identityFiles.length > 0 && identityId) { 
      await Api.post<any, id>(Endpoint.FILE_DELETE, { id: form.id, picId: identityId, fileType: DriverFileType.IdentityBack });
    } 
  }
  
  const FormVevoOnclick = async (e: any) => {
    e.preventDefault() 
    const form: Driver = getValues() as Driver;
    let identityId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${form.id}/vevo/`).toString()
    identityId = identityId?.substring(1, 37); 
    if (form.vevoFiles && form.vevoFiles.length > 0 && identityId) { 
      await Api.post<any, id>(Endpoint.FILE_DELETE, { id: form.id, picId: identityId, fileType: DriverFileType.Vevo });
    } 
  }

  const FormLicenseOnclick = async (e: any) => {
    e.preventDefault() 
    const form: Driver = getValues() as Driver;
    let identityId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${form.id}/license/`).toString()
    identityId = identityId?.substring(1, 37); 
    if (form.licenseFiles && form.licenseFiles.length > 0 && identityId) { 
      await Api.post<any, id>(Endpoint.FILE_DELETE, { id: form.id, picId: identityId, fileType: DriverFileType.License });
    }
  }

  const FormMedicareOnclick = async (e: any) => {
    e.preventDefault() 
    const form: Driver = getValues() as Driver;
    let identityId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${form.id}/medicare/`).toString()
    identityId = identityId?.substring(1, 37);  
    if (identityId) { 
      await Api.post<any, id>(Endpoint.FILE_DELETE, { id: form.id, picId: identityId, fileType: DriverFileType.Medicare });
    }
  }

  const FormProficiencyOnclick = async (e: any) => {
    e.preventDefault() 
    const form: Driver = getValues() as Driver;
    let identityId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${form.id}/proficiency/`).toString()
    identityId = identityId?.substring(1, 37); 
    if (identityId) {  
      await Api.post<any, id>(Endpoint.FILE_DELETE, { id: form.id, picId: identityId, fileType: DriverFileType.Proficiency });
    }
  }

  const FormPoliceOnclick = async (e: any) => {
    e.preventDefault() 
    const form: Driver = getValues() as Driver;
    let identityId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${form.id}/police/`).toString()
    identityId = identityId?.substring(1, 37); 
    if (form.policeFiles && form.policeFiles.length > 0 && identityId) { 
      await Api.post<any, id>(Endpoint.FILE_DELETE, { id: form.id, picId: identityId, fileType: DriverFileType.Police });
    }
  }

  const FormHistoryOnclick = async (e: any) => {
    e.preventDefault() 
    const form: Driver = getValues() as Driver;
    let identityId = selectedUrl?.split(`${process.env.REACT_APP_STORAGE_URL}/${process.env.REACT_APP_STORAGE_BUCKET}/driver/${form.id}/history/`).toString()
    identityId = identityId?.substring(1, 37); 
    if (form.historyFiles && form.historyFiles.length > 0 && identityId) { 
      await Api.post<any, id>(Endpoint.FILE_DELETE, { id: form.id, picId: identityId, fileType: DriverFileType.History });
    }
  }

  return (
    <FormSection
      title={t("driver.verificationSection.title")}
      subtitle={t("driver.verificationSection.subTitle")}
    >
      <FormUpload
        urls={getUrls(DriverFileType.IdentityFront)}
        label={t("driver.verificationSection.poiLabel")}
        name="newIdentityFrontFiles"
        originalName="identityFiles"
        description={t("driver.verificationSection.poiDescription")}
        FormEditingOnclick={FormIdentityFrontOnclick}
        multiple={true}
        validation={{ required: false }}
        tabIndex={16}
        {...formProps}
      />
      <div>
        <label className="register-radio">If not citizen
          <input
            name="citizen"
            type="checkbox"
            onChange={() => setCitizen(!citizen)}
          />
        </label>
      </div>
      {citizen === true ?
        <FormUpload
          urls={getUrls(DriverFileType.Vevo)}
          label={t("driver.verificationSection.vevoLabel")}
          name="newVevoFiles"
          originalName="vevoFiles"
          description={t("driver.verificationSection.vevoDescription")}
          multiple={true}
          FormEditingOnclick = {FormVevoOnclick}
          validation={{ required: false }}
          tabIndex={17}
          {...formProps}
        /> : null}
      <FormUpload
        urls={getUrls(DriverFileType.License)}
        label={t("driver.licenseSection.licenseLabel")}
        name="newLicenseFiles"
        originalName="licenseFiles"
        description={t("driver.licenseSection.licenseDescription")}
        multiple={true}
        FormEditingOnclick = {FormLicenseOnclick}
        validation={{ required: false }}
        tabIndex={23}
        {...formProps}
      />
      <FormUpload
        urls={getUrls(DriverFileType.Medicare)}
        label={t("driver.verificationSection.medicareLabel")}
        name="newMedicareFiles"
        originalName="medicareFiles"
        description={t("driver.verificationSection.medicareDescription")}
        multiple={true}
        FormEditingOnclick = {FormMedicareOnclick}
        validation={{ required: false }}
        tabIndex={25}
        {...formProps}
      />
      <FormUpload
        urls={getUrls(DriverFileType.Proficiency)}
        label={t("driver.verificationSection.proficiencyLabel")}
        name="newProficiencyFiles"
        originalName="proficiencyFiles"
        description={t("driver.verificationSection.proficiencyDescription")}
        multiple={true}
        FormEditingOnclick = {FormProficiencyOnclick}
        validation={{ required: false }}
        tabIndex={24}
        {...formProps}
      />
      <FormUpload
        urls={getUrls(DriverFileType.Police)}
        label={t("driver.verificationSection.policeCheckLabel")}
        name="newPoliceFiles"
        originalName="policeFiles"
        description={t("driver.verificationSection.policeCheckDescription")}
        multiple={true}
        FormEditingOnclick = {FormPoliceOnclick}
        validation={{ required: false }}
        tabIndex={18}
        {...formProps}
      />
      <FormUpload
        urls={getUrls(DriverFileType.History)}
        label={t("driver.verificationSection.drivingHistoryLabel")}
        name="newHistoryFiles"
        originalName="historyFiles"
        description={t("driver.verificationSection.drivingHistoryDescription")}
        multiple={true}
        FormEditingOnclick = {FormHistoryOnclick}
        validation={{ required: false }}
        tabIndex={19}
        {...formProps}
      />
    </FormSection>
  );

}