import { Acl } from "../../interfaces/core.interface";

export enum DashboardAclCode {
  Overview = 'overview',
  Map = 'map',
  Dispatch = 'dispatch',
  Revenue = 'revenue',
  Booking = 'booking',
  Trip = 'trip',
  Session = 'session',
  ViewCompletedNCancelledTrips = 'view-completed-cancelled-trip',
  ViewOngoingTrip = 'view-ongoing-trip' 
}

export const DashboardAcl = [
  {
    code: DashboardAclCode.Overview,
    title: 'dashboard.overview.title',
    description: 'dashboard.overview.subTitle',
  },
  {
    code: DashboardAclCode.Map,
    title: 'dashboard.map.title',
    description: 'dashboard.map.subTitle',
  },
  {
    code: DashboardAclCode.Dispatch,
    title: 'dashboard.dispatch.title',
    description: 'dashboard.dispatch.subTitle',
  },
  {
    code: DashboardAclCode.Revenue,
    title: 'dashboard.revenue.title',
    description: 'dashboard.revenue.subTitle',
  },
  {
    code: DashboardAclCode.Booking,
    title: 'dashboard.booking.title',
    description: 'dashboard.booking.subTitle',
  },
  {
    code: DashboardAclCode.Trip,
    title: 'dashboard.trip.title',
    description: 'dashboard.trip.subTitle',
  },
  {
    code: DashboardAclCode.ViewCompletedNCancelledTrips,
    title: 'dashboard.trip.viewTrips.ccTitle',
    description: 'dashboard.trip.viewTrips.ccSubTitle',
  },

  {
    code: DashboardAclCode.ViewOngoingTrip,
    title: 'dashboard.trip.viewTrips.ongoingTitle',
    description: 'dashboard.trip.viewTrips.ongoingSubTitle',
  },

  {
    code: DashboardAclCode.Session,
    title: 'dashboard.session.title',
    description: 'dashboard.session.subTitle',
  },
] as Acl<DashboardAclCode>;
