import classNames from 'classnames';
import React, { PropsWithChildren, useContext } from 'react';
import { CallContext } from '../../context/call.context';
interface Props {
  title: string;
  subtitle?: string;
  imgSrc?: string;
  className?: any;
  childClassName?: any;
  wrapperClassName?: any;
  headerClassName?: any;
  componentName?: string;
  selectedId?: string;
  ecommerceClassName?: any;
}

export function ViewContent({
  title, subtitle, imgSrc, children, childClassName, className, headerClassName, ecommerceClassName, ...props
}: PropsWithChildren<Props>) {
  const callContext = useContext(CallContext);
  return (
    <div className={classNames(className)}>
      <div className={classNames("ecommerce-customer-info", ecommerceClassName)}>
        <div className={classNames('ecommerce-customer-main-info', headerClassName)}>
          {imgSrc && imgSrc !== undefined && <div className="ecc-avatar"><img src={imgSrc} alt={title} /></div>}
          <div style={{ display: "flex", flexDirection: 'row', flex: 1 }}>
            <div className="ecc-name" style={{ flex: 1 }}>{title}</div>
            {props.componentName === "driver" && (
              <li className="active" style={{ listStyleType: "none", display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                <a onClick={() => callContext.makeCall(props.selectedId!)}>
                  {!callContext.callStatus && (
                    <i className={`os-icon os-icon-phone`} style={{ fontSize: 18 }}></i>
                  )}
                </a>
              </li>
            )}
            {props.componentName === "vehicle" && (
              <li className="active" style={{ listStyleType: "none", display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                <a onClick={() => callContext.makeCall(props.selectedId!)}>
                  {!callContext.callStatus && (
                    <i className={`os-icon os-icon-activity`} style={{ fontSize: 18 }}></i>
                  )}
                </a>
              </li>
            )}
          </div>
          {subtitle && <p>{subtitle}</p>}
        </div>
        <div className={classNames(childClassName)}>
          {children}
        </div>
      </div>
    </div>
  );
}

interface IContentWrapper {
  className?: any;
}

export function ContentWrapper({
  className, children
}: PropsWithChildren<IContentWrapper>) {
  return (
    <div className={classNames('m-b', className)}>
      {children}
    </div>
  )
}