import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useWindowScroll } from 'react-use';
import { Loading } from "../../../components/alerts/loading.component";
import { GroupFilter, ListFilter } from "../../../components/list/filter.component";
import { ListItem, ListItems, ListItemStatus } from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { View } from "../../../components/view/wrapper.component";
import { Feature } from "../../../entities/feature.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormStyle } from "../../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../../services/api.service';
import { FeaturePost } from "../post/post.component";
import { SelectedItemContent } from "./content.component";

const groupFilters: GroupFilter[] = [
  { title: 'All', icon: 'phone-21' },
];

export function FeatureList(props: any) {

  const { t } = useTranslation('list');
  const [search, setSearch] = useState<string>('');
  const [allCount, setAllCount] = useState<number>(0);
  const { y: pageYOffset } = useWindowScroll()
  const [limit, setLimit] = useState<number>(20)
  const [count, setCount] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [features, setFeatures] = useState<Feature[] | undefined>(undefined);
  const [selectedFeature, setSelectedFeature] = useState<Feature>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);
  const [size, setSize] = useState<number>()

  const refresh = async (e?:boolean) => {
    const group = activeGroupFilter?.title;
    const { items, totalItems } = await Api.get<IPaginationResponse<Feature>, IPaginationOptions>(Endpoint.FEATURE_LIST, { page: 0, limit, group, search });

    if (items && items.length !== totalItems) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (items) {
      if (props.location.state !== null && !selectedFeature) {
        setSelectedFeature(props.location.state);
      }
      setFeatures(items);

    } 

    setAllCount(totalItems)

    if (items.length > 0 && !selectedFeature) {
      setSelectedFeature(items[0]);
    }

    // on edit updating states without refreshing
    if (e) {
      setTimeout(async () => {
        const item = items.filter((item: any) => item.id === selectedFeature?.id)
        setSelectedFeature(item[0]);
      }, 500)
    }
  }

  useEffect(() => { refresh(); }, [search, limit, activeGroupFilter]);

  const fetchMoreData = () => {
    setLimit(limit + 10);
  };

  const deleteFeature = async () => {
    setConfirmingDelete(false);
    await Api.delete(Endpoint.FEATURE, { id: selectedFeature?.id });
    setSelectedFeature(undefined);
    refresh();
  }

  const toListItems = (feature?: Feature): ListItem<Feature> | undefined => {
    if (!feature) {
      return;
    }

    return {
      id: feature.id as string,
      title: feature.title,
      status: ListItemStatus.Green,
      ref: feature
    }
  }

  const addFeature = () => {
    setFormMode(FormMode.Adding);
  }

  const editFeature = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (features) {
      const currentFeatureIndex = features.findIndex(feature => !!selectedFeature && feature.id === selectedFeature.id);
      if (currentFeatureIndex + 1 < features.length && currentFeatureIndex !== -1) {
        setSelectedFeature(features[currentFeatureIndex + 1]);
      }
    }
  }

  const onPrev = () => {
    if (features) {
      const currentFeatureIndex = features.findIndex(feature => !!selectedFeature && feature.id === selectedFeature.id);
      if (currentFeatureIndex - 1 > -1 && currentFeatureIndex !== -1) {
        setSelectedFeature(features[currentFeatureIndex - 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedFeature(undefined);
    setActiveGroupFilter(target);
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  let history = useHistory();
  function checkAudit(id: string | undefined): void | undefined {
    history.push(`/audit/${id}`);
  }

  const updateSize = () => {
    setSize(window.screen.width)
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [])


  return (
    <div>
      {size && size < 1000 ?
        <List>
          <div className="row">
            <div className="col-xl-12">
              <ListFilter
                groupFilters={[
                  { title: 'All', icon: 'phone-21', count: allCount },
                ]}
                activeGroupFilter={activeGroupFilter}
                onGroupSelect={selectGroup}
              />
            </div>
            <div className="col-xl-12">
              <ListItems
                items={features?.map(toListItems) as ListItem<Feature>[]}
                activeItem={toListItems(selectedFeature)}
                onSearchChange={onSearchChange}
                showSearch={true}
                showRefresh={true}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                onRefresh={() => { setFeatures(undefined); refresh(); }}
                onClick={(item: ListItem<Feature>) => { setSelectedFeature(item.ref); }}
              />
            </div>
            <div className="col-xl-12" style={{ marginTop: "40px" }}>
              {formMode === undefined ? (
                features !== undefined ? (
                  selectedFeature ? (
                    <View
                      componentName={"fare"}
                      showAdd={true}
                      showEdit={true}
                      showDelete={true}
                      showAudit={true}
                      showNext={true}
                      showPrev={true}
                      onAdd={addFeature}
                      onEdit={editFeature}
                      onDelete={deleteFeature}
                      onNext={onNext}
                      onPrev={onPrev}
                      onAudit={() => checkAudit(selectedFeature.id)}
                    >
                      <SelectedItemContent
                        selectedFeature={selectedFeature}
                      />
                    </View>
                  ) : (
                    <div className="mx-auto py-5 text-center">
                      <button onClick={addFeature} className="btn btn-primary text-bold">{t('feature.addFeature')}</button>
                    </div>
                  )
                ) : (
                  <div className="mx-auto py-5 text-center">
                    <p>{t('feature.wait')}</p>
                    <Loading loading={true} />
                  </div>
                )
              ) : (
                selectedFeature ? (
                  <FeaturePost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    entityId={selectedFeature.id}
                    onAfterSave={() => refresh(true)}
                  />
                ) : (
                  <FeaturePost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    onAfterSave={() => refresh(true)}
                  />
                )
              )}
            </div>
          </div>
        </List> :
        <List>
          <ListFilter
            groupFilters={[
              { title: 'All', icon: 'phone-21', count: allCount },
            ]}
            activeGroupFilter={activeGroupFilter}
            onGroupSelect={selectGroup}
          />

          <ListItems
            items={features?.map(toListItems) as ListItem<Feature>[]}
            activeItem={toListItems(selectedFeature)}
            onSearchChange={onSearchChange}
            showSearch={true}
            showRefresh={true}
            hasMore={hasMore}
            fetchMoreData={fetchMoreData}
            onRefresh={() => { setFeatures(undefined); refresh(); }}
            onClick={(item: ListItem<Feature>) => { setSelectedFeature(item.ref); }}
          />

          {formMode === undefined ? (
            features !== undefined ? (
              selectedFeature ? (
                <View
                  componentName={"fare"}
                  showAdd={true}
                  showEdit={true}
                  showDelete={true}
                  showAudit={true}
                  showNext={true}
                  showPrev={true}
                  onAdd={addFeature}
                  onEdit={editFeature}
                  onDelete={deleteFeature}
                  onNext={onNext}
                  onPrev={onPrev}
                  onAudit={() => checkAudit(selectedFeature.id)}
                >
                  <SelectedItemContent
                    selectedFeature={selectedFeature}
                  />
                </View>
              ) : (
                <div className="mx-auto py-5 text-center">
                  <button onClick={addFeature} className="btn btn-primary text-bold">{t('feature.addFeature')}</button>
                </div>
              )
            ) : (
              <div className="mx-auto py-5 text-center">
                <p>{t('feature.wait')}</p>
                <Loading loading={true} />
              </div>
            )
          ) : (
            selectedFeature ? (
              <FeaturePost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                entityId={selectedFeature.id}
                onAfterSave={() => refresh(true)}
              />
            ) : (
              <FeaturePost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh(true)}
              />
            )
          )}
        </List>
      }
    </div>
  );

}
