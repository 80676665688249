import classNames from "classnames";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState
} from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { FormMode } from "../../enums/core.enum";
import { Entities, FormCancelLink } from "../../enums/form.enum";
import { DashboardRoute } from "../../pages/dashboard/routes";
import { Api, Endpoint } from "../../services/api.service";
import { ValidationError } from "../../types/error.interface";

/*  The props that the Form component expects to be passed to it
    formMode (optional): Needs to be passed if default value is needed
    className (optional): Needs to be passed if you want to provide additional styling classes to the form
    returnLink (optional): The route that will be opened after successfully submitting or cancelling the form
    endpoint (optional): The data submission endpoint
    onBeforeSave (optional): Used for custom validation or any other thing that needs to performed before submitting form 
    onAfterSave (optional): Used for post-processing of data, i.e. uploading images and stuff after form submission
    childClassName (optional): Needed for child's wrapper styling
    cancel (optional): Function that runs when cancel button is pressed
    saveForm (optional): Needs to be passed if default save function doesn't meet the requirements
    entityId (optional): Required in case the form is being used for editing
    afterInit (optional): Function that needs to run after the init function's functionality
    customLoad (optional): Needs to be passed if the default load function doesn't meet the requirements
    useParentFormContext (optional): Use if the parent is making use of useForm(), so that the Form component's useForm isn't used
    defaultLoading (optional): Passed if loading state needs to be passed from parent */
export interface FormProps<Entity> {
  formMode?: FormMode;
  className?: any;
  returnLink?: FormCancelLink;
  endpoint?: Endpoint;
  // returnLink?:IncidentRoute;
  onBeforeSave?: (mode: FormMode, form: Entity) => void;
  onAfterSave?: (entity: Entity, form: Entity) => void;
  childClassName?: any;
  cancel?: Function;
  saveForm?: (form: Entity) => void;
  entityId?: string | undefined;
  afterInit?: (
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  customLoad?: (id: string, reset: (form: Entity, props?: any) => void) => void;
  processData?: (form: Entity) => Entity;
  useParentFormContext?: boolean;
  defaultLoading?: boolean;
  formFiles?: FormFile[];
  fileEndpoint?: Endpoint;
}

/* File upload fields interface */
interface FormFile {
  name: string;
  originalName: string;
  count: number;
  required: boolean;
  desc: string;
}

/* Interface for the state of the form that needs to be passed to the context to be provided to the children */
interface StateObject {
  mode: FormMode;
  loading: boolean;
  errorMessage: string | null;
  saving: boolean;
  setErrorMessage: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >;
}

/* The form's props and state context for passing data such as mode, loading state, as well as other data to child components */
/* The <Partial> thingie is required since we don't wanna pass default values */
export const FormPropsContext = createContext<
  Partial<FormProps<any> & StateObject>
>({});

/* The form component */
/* The <Entity extends Entities> is important, form for any new module needs to be registered at "Entities" type */
export function Form<Entity extends Entities>(
  props: PropsWithChildren<FormProps<Entity>>
) {
  /* Destructuring the props */
  const {
    defaultLoading,
    fileEndpoint,
    useParentFormContext,
    customLoad,
    afterInit,
    endpoint,
    entityId,
    formMode,
    formFiles,
    onBeforeSave,
    onAfterSave,
    saveForm,
    processData,
    returnLink,
    childClassName,
    className,
    children,
    cancel,
  } = props;

  /* The states for the form */
  const [mode, setMode] = useState<FormMode>(
    formMode === FormMode.Editing ? FormMode.Editing : FormMode.Adding
  );
  const [loading, setLoading] = useState<boolean>(
    defaultLoading !== undefined ? defaultLoading : false
  );
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [saving, setSaving] = useState<boolean>(false);

  /* Initialize the formContext and form variables
   * formContext will be undefined if the parent doesn't provide any context
   * the useForm provides the same properties to the form variable as that of the useFormContext
   * which is exactly we can assign the value formContext to the form variable */
  let formContext = useFormContext();
  let form = useForm();

  /* In case if the parent provides a context, then use that */
  if (formContext) {
    form = formContext;
  }

  /* Destructuring the required properties from props and form variables */
  const { reset, setError, clearErrors, trigger, control } = form;

  /* The useHistory hook is required to access push and location capabilities provided by react-router-dom */
  const { push: navigateTo, location } = useHistory();

  /* The load function */
  const load = useCallback(async (id: string) => {
    setLoading(true);

    if (customLoad) {
      customLoad(id, reset);
    } else {
      if (endpoint) {
        /* Get the entity data from the endpoint corresponding the id */
        let entity = await Api.get<Entity, { id: string }>(endpoint, { id });
        if (processData) {
          entity = processData(entity);
        }
        
        reset(entity);
        trigger();
      } else {
        console.log("An entity id is required!");
      }
    }

    setLoading(false);
  }, [reset, trigger]);

  /* The init function */
  const init: () => Promise<void> = useCallback(async () => {
    /* Getting the id from the url which is passed only in case if the form is meant to update an entity */
    const query = new URLSearchParams(location.search);
    const id = query.get("id") || entityId;

    /* In case if id is present in the url, the set the formMode to editing and load the corresponding id data */
    if (id && formMode === FormMode.Editing) {
      setMode(FormMode.Editing);
      await load(id);
    }

    if (afterInit) {
      afterInit(setLoading);
    }
  }, [afterInit, entityId, formMode, load, location]);

  /* The useEffect hook required for initializing the form */
  useEffect(() => {
    init();
  }, [init]);

  /* The save function */
  const save = async (form: Entity) => {
    setErrorMessage(null);
    setSaving(true);

    /* Try... catch... block is needed since it involves uploading stuff */
    try {
      if (saveForm) {
        saveForm(form);
      } else {
        /* Run onBeforeSave if provided which handles any extra functionality needed for specific forms */
        if (onBeforeSave && mode !== undefined) {
          onBeforeSave(mode, form);
        }

        /* If formFiles are provided, then loop over them and check in case if any file is required but not present */
        console.log("form inside form", form)
        validateFiles(form);

        /* If a form id provided, then use the patch (i.e. update endpoint) otherwise use the post (i.e. create new entity endpoint) */
        form.id = form.id || undefined;
        const saveFn = form.id ? Api.patch.bind(Api) : Api.post.bind(Api);

        /* If an endpoint is provided, then use it otherwise send an error */
        if (endpoint) {
          const toSave = { ...form };
          console.log('FILE DATA:', formFiles)
          // Remove files from saving, these will be uploaded separately
          formFiles?.forEach((formFile) => {
            delete toSave[formFile.originalName];
          });
          formFiles?.forEach((formFile) => {
            delete toSave[formFile.name];
          });
          // Remove address if no value is entered in it
          for (let [key, value] of Object.entries(toSave)) {
            if (
              value &&
              typeof value === "object" &&
              value.hasOwnProperty("lat") &&
              !value.lat
            ) {
              delete toSave[key];
            }
          }
          
          const entity: Entity = await saveFn<Entity, Entity>(endpoint, toSave);
          console.log("entity in form", entity, endpoint, toSave)
          /* Run on After save if provided */
          if (onAfterSave) {
            onAfterSave(entity, form);
          }
          /* Upload files */
          uploadFiles(entity, form);

          /* Call cancel before navigating back so it can do the cleanup */
          cancel && cancel();
          setSaving(false);

          /* After successful saving, navigate to the return link */
          navigateTo(returnLink ? returnLink : DashboardRoute.Overview);
        } else {
          throw new Error(
            "An endpoint is required if a save function is not provided!"
          );
        }
      }
    } catch (err: any) {
      /* Set errors corresponding to the field */
      if (err.field) {
        setError(err.field, { type: "unique", message: err.message });
        setErrorMessage(`${err.message}`);
      } else {
        setError(err.field, { type: "", message: err.message });
      }

    }
  };

  /* The onChange function */
  const onChange = (ev: React.FormEvent) => {
    /* Clear the respective field's error on the change */
    clearErrors();
    setErrorMessage(null);
    setSaving(false);
  };

  /* Function to validate files */
  const validateFiles = (form: Entity) => {
    if (formFiles && formMode === FormMode.Adding) {
      console.log("form files", formFiles, form)
      if (form.picFile)
        form.picFile = Object.values(form?.picFile).every(x => !!x) === false ? undefined : form?.picFile;

      if (form.identityBackFile)
        form.identityBackFile = Object.values(form?.identityBackFile).every(x => !!x) === false ? undefined : form?.identityBackFile;

      if (form.identityFrontFile)
        form.identityFrontFile = Object.values(form?.identityFrontFile).every(x => !!x) === false ? undefined : form?.identityFrontFile;

      if (form.newPlateFile)
        form.newPlateFile = Object.values(form?.newPlateFile).every(x => !!x) === false ? undefined : form?.newPlateFile;


      for (let i of formFiles) {
        console.log('Requred i', i, "ORIGNAL Data:", form[i.originalName], form[i.name])
        if (i.required && !form[i.originalName] && !form[i.name])
          throw new ValidationError({
            field: i.name,
            message: `${i.desc} required!`,
          });
        if (form[i.name] && (form[i.name] as File[]).length > i.count)
          throw new ValidationError({
            field: i.name,
            message: `Max ${i.count} files allowed!`,
          });
      }
    }
    if (formFiles && formMode === FormMode.Editing) {
      console.log("form files", formFiles, form)
      if (form.picFile)
        form.picFile = Object.values(form?.picFile).every(x => !!x) === false ? undefined : form?.picFile;

      if (form.identityBackFile)
        form.identityBackFile = Object.values(form?.identityBackFile).every(x => !!x) === false ? undefined : form?.identityBackFile;

      if (form.identityFrontFile)
        form.identityFrontFile = Object.values(form?.identityFrontFile).every(x => !!x) === false ? undefined : form?.identityFrontFile;

      if (form.newPlateFile)
        form.newPlateFile = Object.values(form?.newPlateFile).every(x => !!x) === false ? undefined : form?.newPlateFile;


      for (let i of formFiles) {
        console.log('Requred i', i, "ORIGNAL Data:", form[i.originalName], form[i.name])
        if (i.required && !form[i.originalName] && !form[i.name])
          throw new ValidationError({
            field: i.name,
            message: `${i.desc} required!`,
          });
        if (form[i.name] && (form[i.name] as File[]).length > i.count)
          throw new ValidationError({
            field: i.name,
            message: `Max ${i.count} files allowed!`,
          });
      }
    }
  };

  /* Function to upload files */
  const uploadFiles = async (entity: Entity, form: Entity) => {
    /* Upload files in case if both the formFiles and Endpoint is provided */
    if (formFiles && fileEndpoint) {
      /* Create a form data */
      const upload = new FormData();

      /* Append the entity id, as it is needed to access the files for the particular entity */
      console.log("entity id", entity, entityId)
      if (entity.id) {
        upload.append("id", entity.id);
      }

      /* Traverse the list provided to the form */
      for (let i of formFiles) {
        /* If the particular field has an image selected */
        if (form[i.name]) {
          /* Use string manipulation to create keys from the field names */
          let key = i.name.slice(3).charAt(0).toLowerCase() + i.name.slice(4);

          /* If the particular field has multiple files, then append them one by one */
          if ((form[i.name] as File[]).length > 1) {
            for (let j of form[i.name] as File[]) {
              upload.append(key, j);
            }
          } else {
            /* Append the file */
            upload.append(key, form[i.name] as File);
          }
        }
      }
      console.log("uploaded data", upload)
      /* Upload the files */
      await Api.upload(fileEndpoint, upload, (e: ProgressEvent) => {
        console.log(`Uploaded: ${(e.total / e.loaded) * 100}%`);
      });
    }
  };

  /* The childProps passed to the children through context api */
  const childProps = { ...props, mode, loading, errorMessage, saving };

  return (
    /**
     * In case if parent provides context, then just use the form itself
     * Otherwise, also use a form context provider
     */
    useParentFormContext ? (
      <FormInternals<Entity>
        childProps={childProps}
        className={className}
        childClassName={childClassName}
        children={children}
        onChange={onChange}
        save={save}
        formMode={formMode}
      />
    ) : (
      <FormProvider {...form}>
        <FormInternals<Entity>
          childProps={childProps}
          className={className}
          childClassName={childClassName}
          children={children}
          onChange={onChange}
          save={save}
          formMode={formMode}
        />
      </FormProvider>
    )
  );
}

interface InternalProps<Entity> {
  childProps: any;
  className?: any;
  childClassName?: any;
  children?: React.ReactNode;
  onChange: (ev: React.FormEvent) => void;
  save: (form: Entity) => Promise<void>;
  formMode: FormMode | undefined;
}

function FormInternals<Entity extends Entities>({
  childProps,
  className,
  childClassName,
  children,
  onChange,
  save,
  formMode,
}: InternalProps<Entity>) {
  const { handleSubmit, register } = useFormContext();
  return (
    /* The form props context provider which provides props and state to the children through native context api */
    <FormPropsContext.Provider value={{ ...childProps }}>
      {/* The content box wrapper that wraps the form */}
      <div className={classNames("content-box", className)}>
        {/* The form component */}
        <form
          className={classNames("row", childClassName)}
          onSubmit={handleSubmit((form) => save(form as Entity))}
          onChange={onChange}
          autoComplete="off"
        >
          {formMode === FormMode.Editing && (
            <input type="hidden" name="id" ref={register} />
          )}
          {children}
        </form>
      </div>
    </FormPropsContext.Provider>
  );
}