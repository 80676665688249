import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatsFilterPeriod } from '../../interfaces/stats.interface';
import { Loading } from '../alerts/loading.component';

type Stats = {
    label: string; value: string | number; isPositive: boolean; percentage: number;
    onClick?: () => void;
}

interface Props {
    data: Stats[]; currentRange: StatsFilterPeriod; onRangeChange: (e: any) => void;
    onCustomSelect: (startDate: Date, endDate: Date) => void;
}
export function StatsComponent(props: Props) {
    const rows = props.data.length / 2;
    const { t } = useTranslation('list');
    const [END, setEND] = useState<string>();
    const [START, setSTART] = useState<string>();
    return (
        <div >
            <div className='d-flex flex-row mx-1'>
                {props.currentRange === StatsFilterPeriod.Custom && (
                    <div className='d-flex flex-column flex-grow-1 justify-content-around'>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Start</label>
                            <div className="col-sm-10">
                                <input type="date" value={START} className="form-control" id="startDate" onChange={(e) => setSTART(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">End</label>
                            <div className="col-sm-10">
                                <input type="date" value={END} className="form-control" id="endDate" onChange={(e) => setEND(e.target.value)} />
                            </div>
                        </div> 
                    </div>
                )}
                <div className='flex-fill d-flex flex-column justify-content-around element-actions'>
                    <form className="form-group justify-content-sm-end" style={{ alignSelf: props.currentRange === StatsFilterPeriod.Custom ? "center": "flex-end" }}>
                        <select value={props.currentRange} onChange={props.onRangeChange} className="form-control form-control-sm rounded">
                            <option value={StatsFilterPeriod.Today}>{t('dashboard:dropdown.today')}</option>
                            <option value={StatsFilterPeriod.Yesterday}>{t('dashboard:dropdown.yesterday')}</option>
                            <option value={StatsFilterPeriod.ThisWeek}>{t('dashboard:dropdown.thisWeek')}</option>
                            <option value={StatsFilterPeriod.LastWeek}>{t('dashboard:dropdown.lastWeek')}</option>
                            <option value={StatsFilterPeriod.ThisMonth}>{t('dashboard:dropdown.thisMonth')}</option>
                            <option value={StatsFilterPeriod.LastMonth}>{t('dashboard:dropdown.lastMonth')}</option>
                            <option value={StatsFilterPeriod.ThisYear}>{t('dashboard:dropdown.thisYear')}</option>
                            <option value={StatsFilterPeriod.Custom}>{t('dashboard:dropdown.custome')}</option>
                        </select>
                    </form>
                    {props.currentRange === StatsFilterPeriod.Custom && (
                        <div onClick={() => {
                            //@ts-ignore
                            if (!START) { alert('Please select start range!'); return; }
                            else {
                                if (!END) setEND(START);
                                props.onCustomSelect(new Date(START), new Date(!END ? START: END));
                            }
                        }} className='form-group btn btn-outline-primary w-75 align-self-center'>
                            <p className='fs-5 m-0'>GET</p>
                        </div>
                    )}
                </div>
            </div>

            {props.data.length === 0 && <div style={{ display: "flex", flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Loading loading/>    
            </div>}

            {[...Array(rows)].map((_, index) => (
                <div key={index+""} className="row" style={{ justifyContent: 'space-around', marginTop: '0rem', padding: '0.5rem' }}>
                    <a className="element-box el-tablo" onClick={props.data[(0 + index) + index].onClick} style={{ 
                        height: 'inherit', flex: 1, padding: '1rem', borderBottom: props.data[(0 + index) + index].onClick ? '3px solid blue': undefined,
                        cursor: "pointer"
                    }}>
                        <div className="label">{props.data[(0 + index) + index].label}</div>
                        <div className="value">{String(props.data[(0 + index) + index].value || 0)}</div>
                        <div className={`trending trending-${props.data[(0 + index) + index].isPositive ? 'up' : 'down'}-basic`}>
                            <span>{Math.round(props.data[(0 + index) + index].percentage)}%</span>
                            <i className={`os-icon os-icon-arrow-${props.data[(0 + index) + index].isPositive ? 'up2' : 'down'}`}></i>
                        </div>
                    </a>
                    <div style={{ flex: 0.02 }}></div>
                    {!!props.data[(1 + index) + index] && (
                        <a className="element-box el-tablo" onClick={props.data[(1 + index) + index].onClick} style={{ 
                            height: 'inherit', flex: 1, padding: '1rem', borderBottom: props.data[(1 + index) + index].onClick ? '3px solid blue': undefined,
                            cursor: "pointer"
                        }}>
                            <div className="label">{props.data[(1 + index) + index].label}</div>
                            <div className="value">{String(props.data[(1 + index) + index].value || 0)}</div>
                            <div className={`trending trending-${props.data[(1 + index) + index].isPositive ? 'up' : 'down'}-basic`}>
                                <span>{Math.round(props.data[(1 + index) + index].percentage)}%</span>
                                <i className={`os-icon os-icon-arrow-${props.data[(1 + index) + index].isPositive ? 'up2' : 'down'}`}></i>
                            </div>
                        </a>
                    )}
                </div>
            ))}
        </div>
    );
}